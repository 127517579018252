import * as React from "react";
import * as Yup from "yup";
import styles from "./AmountModal.module.css";
import { Field, Form, Formik } from "formik";

import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import CloseModalImg from "assets/images/closeModal.svg";

export interface AmountModalProps {
    modalIsOpen: boolean;
    closeModalFn: () => void;
    submitForm: (values: { amount: string }) => void;
    status?: string;
}

const AmountModal: React.FC<AmountModalProps> = ({ submitForm, closeModalFn, status }) => {
    return (
        <div className={styles.amountModal}>
            <img onClick={closeModalFn} className={styles.amountModal__closeBtn} src={CloseModalImg} alt="close" />
            <Formik
                initialValues={{ amount: "" }}
                onSubmit={(values) => {
                    submitForm(values);
                }}
                validationSchema={AmountSchema}
            >
                {() => (
                    <Form>
                        <Field
                            id="amount"
                            label="Amount"
                            name="amount"
                            placeholder=""
                            type="number"
                            component={TextInput}
                        />
                        <PrimaryButton type="submit" title={status} block />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AmountModal;

const AmountSchema = Yup.object().shape({
    amount: Yup.string().required("Required"),
});
