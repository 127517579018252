import React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import request from "constants/requests";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";
import { PrimaryButton } from "components/Button/Button";

const TextInput = ({ field, form: { touched, errors }, ...props }) => {
    const inputClassName = `form-control ${touched[field.name] && errors[field.name] ? "is-invalid" : ""}`;

    return (
        <div className="form-group">
            <input className={inputClassName} {...field} {...props} />
            {touched[field.name] && errors[field.name] && <div className="invalid-feedback">{errors[field.name]}</div>}
        </div>
    );
};

const SetupCharges = ({ data }) => {
    const handleSubmit = async (values) => {
        try {
            const { data: responseData } = await request({
                method: "patch",
                url: "/merchant/activate",
                data: {
                    merchantId: data?.id,
                    charges: {
                        airtimeCharge: 10,
                        bvnChargeV1: values.bvnChargeV1,
                        transferCharges: values.transferCharges,
                        bvnVerificationCharge: values.bvnChargeV2,
                        walletToWalletTransfer: values.accountToAccountTransfer,
                        walletReservationCharge: values.walletReservationCharge,
                        fundingRate: values.fundingRate,
                        fundingRateMax: values.fundingRateMax,
                        tier_1_daily_limit: values.tier1Limit,
                        tier_2_daily_limit: values.tier2Limit,
                        tier_3_daily_limit: values.tier3Limit,
                        dailyTransactionLimit: values.transactionLimit,
                    },
                },
            });

            toast.success(responseData?.message);

            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error(error?.message);
            }
        }
    };

    return (
        <div>
            <h4 className="mb-3">Account Fees</h4>
            <Formik
                onSubmit={handleSubmit}
                validationSchema={setupMerchantSchema}
                initialValues={{
                    bvnChargeV1: "",
                    bvnChargeV2: "",
                    accountToAccountTransfer: "",
                    walletReservationCharge: "",
                    transferCharges: { max5000: "", max50000: "", min50000: "" },
                    fundingRate: "",
                    fundingRateMax: "",
                    tier1Limit: "",
                    tier2Limit: "",
                    tier3Limit: "",
                    transactionLimit: "",
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field name="bvnChargeV1" placeholder="BVN V1 Fee" type="text" component={TextInput} />
                        <Field name="bvnChargeV2" placeholder="BVN V2 Fee" type="text" component={TextInput} />

                        <Field
                            component={TextInput}
                            name="walletReservationCharge"
                            placeholder="Account Reservation Fee"
                        />

                        <Field
                            component={TextInput}
                            name="accountToAccountTransfer"
                            placeholder="Account to Account Transfer Fee"
                        />

                        <Field
                            component={TextInput}
                            name="transferCharges.max5000"
                            placeholder="Transfer 1 - 5000 Fee"
                        />
                        <Field
                            component={TextInput}
                            name="transferCharges.max50000"
                            placeholder="Transfer 5,000 - 50,000 Fee"
                        />
                        <Field
                            component={TextInput}
                            name="transferCharges.min50000"
                            placeholder="Transfer above 50,000 Fee"
                        />
                        <Field
                            component={TextInput}
                            type="number"
                            name="transactionLimit"
                            placeholder="DAILY TRANSACTION LIMIT"
                        />
                        <Field component={TextInput} name="fundingRate" placeholder="Funding Rate e.g 0.85" />
                        <Field component={TextInput} name="fundingRateMax" placeholder="Funding fee Cap Amount" />
                        <Field component={TextInput} name="tier1Limit" placeholder="TIER 1 LIMIT" />
                        <Field component={TextInput} name="tier2Limit" placeholder="TIER 2 LIMIT" />
                        <Field component={TextInput} name="tier3Limit" placeholder="TIER 3 LIMIT" />

                        {isSubmitting ? (
                            <Loading />
                        ) : (
                            <PrimaryButton type="submit" block size="lg" title="Approve Account" />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SetupCharges;

const setupMerchantSchema = Yup.object().shape({
    bvnChargeV1: Yup.number()
        .typeError("BVN v1 charge must be a number")
        .required()
        .label("BVN v1 charge")
        .positive()
        .integer()
        .min(0),
    bvnChargeV2: Yup.number()
        .typeError("BVN v2 charge must be a number")
        .required()
        .label("BVN v2 charge")
        .positive()
        .integer()
        .min(0),
    accountToAccountTransfer: Yup.number()
        .typeError("Account to Account transfer must be a number")
        .required()
        .label("Account to Account transfer")
        .positive()
        .integer()
        .min(0),
    walletReservationCharge: Yup.number()
        .typeError("Account reservation must be a number")
        .required()
        .label("Account reservation")
        .positive()
        .integer()
        .min(0),
    transferCharges: Yup.object({
        max5000: Yup.number()
            .typeError("Max 5000 transfer charges must be a number")
            .required()
            .integer()
            .positive()
            .min(1),
        max50000: Yup.number()
            .typeError("Max 50000 transfer charges must be a number")
            .required()
            .integer()
            .positive()
            .min(1),
        min50000: Yup.number()
            .typeError("Min 50000 transfer charges must be a number")
            .required()
            .integer()
            .positive()
            .min(1),
    }),
    fundingRate: Yup.number()
        .typeError("Funding rate fee must be a number")
        .required()
        .label("Funding rate fee")
        .positive()
        .min(0),
    fundingRateMax: Yup.number()
        .typeError("Funding Fee Cap must be a number")
        .required()
        .label("Funding Fee Cap")
        .positive()
        .integer()
        .min(1),
    tier1Limit: Yup.number()
        .typeError("Tier limit must be a number")
        .required()
        .label("Tier limit")
        .positive()
        .integer()
        .min(1),
    tier2Limit: Yup.number()
        .typeError("Tier limit must be a number")
        .required()
        .label("Tier limit")
        .positive()
        .integer()
        .min(1),
    tier3Limit: Yup.number()
        .typeError("Tier limit must be a number")
        .required()
        .label("Tier limit")
        .positive()
        .integer()
        .min(1),
    transactionLimit: Yup.number()
        .typeError("Transaction limit must be a number")
        .required()
        .label("Transaction limit")
        .positive()
        .min(0),
});
