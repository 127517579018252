import React from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getWalletsFn } from "slices/walletSlice";
import {
  getSingleCustomerFn,
  upgradeCustomerTierFn,
  selectUpgradeCustomerTier,
} from "slices/customersSlice";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email?: string;
  address?: string;
  tier?: string;
  customerId?: string;
}

export interface CreateWalletSectionProps {
  editWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const UpdateBVNModal: React.FC<{
  customerData: Record<string, any>;
  closeModal: () => void;
}> = ({ customerData, closeModal }) => {
  const dispatch = useDispatch();
  const upgradeTierState = useSelector(selectUpgradeCustomerTier);

  const validationSchema = Yup.object().shape(
    {
      bvn: Yup.string().when("nin", {
        is: (val) => val,
        then: (schema) => schema,
        otherwise: (schema) =>
          schema
            .required("BVN is required")
            .length(11, "BVN length should be 11 characters!"),
      }),
      nin: Yup.string().when("bvn", {
        is: (val) => val,
        then: (schema) => schema,
        otherwise: (schema) =>
          schema
            .required("NIN is required")
            .length(11, "NIN length should be 11 characters!"),
      }),
    },
    [["bvn", "nin"]]
  );

  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <h2 className="mb-5">
        {customerData?.isBVNVerificationRequired && !customerData?.isNINVerified
          ? "Update BVN/NIN"
          : customerData?.isBVNVerificationRequired
          ? "Update BVN"
          : "Update NIN"}
      </h2>
      <Formik
        enableReinitialize
        initialValues={{
          bvn: "",
          nin: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const payload: any = { customerId: customerData?.customerId };

          if (values?.bvn) {
            payload.bvn = values?.bvn;
          }
          if (values?.nin) {
            payload.nin = values?.nin;
          }

          dispatch(
            upgradeCustomerTierFn({
              payload,
              cb: () => {
                dispatch(getWalletsFn({ page: 1 }));
                dispatch(getSingleCustomerFn(customerData?.customerId));
                closeModal();
                toast.success(upgradeTierState?.data);
                actions.resetForm();
              },
            })
          );
        }}
      >
        {() => (
          <Form>
            {!!customerData?.isBVNVerificationRequired && (
              <>
                <Field
                  name="bvn"
                  placeholder="BVN"
                  type="text"
                  component={TextInput}
                  maxLength={11}
                />
                <ErrorMessage
                  name="bvn"
                  className="invalid-feedback"
                  component="div"
                />
              </>
            )}
            {!customerData?.isNINVerified && (
              <div style={{ marginTop: "20px" }}>
                <Field
                  name="nin"
                  placeholder="NIN"
                  type="text"
                  component={TextInput}
                  maxLength={11}
                />
                <ErrorMessage
                  name="nin"
                  className="invalid-feedback"
                  component="div"
                />
              </div>
            )}

            {upgradeTierState?.loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title={
                  customerData?.isBVNVerificationRequired &&
                  !customerData?.isNINVerified
                    ? "Update BVN/NIN"
                    : customerData?.isBVNVerificationRequired
                    ? "Update BVN"
                    : "Update NIN"
                }
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateBVNModal;
