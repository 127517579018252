import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import MerchantSection from "./components/Merchant/Merchant";
import SetupCharge from "./components/SetupCharges/SetupCharges";
import MerchantList from "./components/MerchantList/MerchantList";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";

import { selectBankState, getMerchantFn } from "slices/bankSlice";

const MerchantScreen: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectBankState);

  const { loading, info, data, meta } = bankState.merchant;

  const [record, setRecord] = useState(null);
  const [sideContent, setSideContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(meta ? meta.page : 1);

  useEffect(() => {
    dispatch(getMerchantFn({ page: currentPage }));
  }, [bankState.accessMode, dispatch, currentPage]);

  const renderSideContent = () => {
    if (sideContent === "showSingleRecord") {
      return (
        <CustomerDetail
          data={record}
          onProceed={() => setSideContent("setupCharges")}
          onCancel={closeModal}
        />
      );
    } else if (sideContent === "setupCharges") {
      return <SetupCharge data={record} />;
    } else {
      return null;
    }
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;

    const currentPage = selected + 1;

    setCurrentPage(currentPage);
  };

  const openModal = (content, values) => {
    setRecord(values);
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <BankLayout>
        {loading ? (
          <Loading />
        ) : (
          <>
            <MerchantSection data={info} totalRecords={meta?.totalRecords} />
            <MerchantList
              data={data}
              openModal={openModal}
              getMerchantFn={getMerchantFn}
            />

            {meta?.totalPages > 1 && (
              <nav aria-label="navigation">
                <ReactPaginate
                  nextLabel={"next"}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={2}
                  activeClassName={"active"}
                  previousLabel={"previous"}
                  nextClassName={"page-item"}
                  pageClassName={"page-item"}
                  pageCount={meta?.totalPages}
                  onPageChange={handlePageClick}
                  nextLinkClassName={"page-link"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  containerClassName={"pagination"}
                  previousLinkClassName={"page-link"}
                />
              </nav>
            )}
          </>
        )}
      </BankLayout>
    </>
  );
};

export default MerchantScreen;
