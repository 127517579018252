import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";

import Loading from "components/Loading/Loading";
import styles from "./CardListDetails.module.css";
import {
  OutlineButton,
  PrimaryButton,
  RedButton,
} from "components/Button/Button";
import request from "constants/requests";
import TextInput from "components/TextInput/TextInput";

interface ICardDetails {
  id: string;
  batchNumber: string;
  maskedPan: string;
  cardholder: string;
  status: string;
  expiry: string;
  accountNumber: string;
  isCardBlocked: boolean;
  pinSet: boolean;
}

interface ICardDetailsModal {
  card: ICardDetails;
  closeModalFn?: () => void | undefined;
  refetch: () => void;
  showModal: boolean;
}

const CardListDetails: React.FC<ICardDetailsModal> = ({
  card,
  closeModalFn,
  refetch,
  showModal,
}) => {
  const [showChangePin, setShowChangePin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [cardDetails, setCardDetails] = useState<ICardDetails>(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (showModal) {
      getCardDetailsWithAccountNumber();
    } else {
      setTimeout(() => {
        setLoading(true);
        setError("");
        setShowChangePin(false);
      }, 300);
    }
  }, [showModal]);

  const getCardDetailsWithAccountNumber = async () => {
    setError("");
    setLoading(true);

    try {
      const { data } = await request({
        method: "get",
        url: `/fip/card/${card?.accountNumber}`,
      });
      setCardDetails(data?.data);
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePin = async (values, { resetForm }) => {
    setSubmitting(true);
    try {
      const { data } = await request({
        method: "patch",
        url: "/fip/card/pin",
        data: { cardId: cardDetails?.id, pin: values?.pin },
      });
      toast.success(data?.message);
      setShowChangePin(false);
      resetForm();
      refetch();
      getCardDetailsWithAccountNumber();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleBlockCard = async () => {
    setSubmitting(true);
    try {
      const { data } = await request({
        method: "post",
        url: "/fip/card/block",
        data: { cardId: cardDetails?.id },
      });
      toast.success(data?.message);
      setShowChangePin(false);
      getCardDetailsWithAccountNumber();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleUnblockCard = async () => {
    setSubmitting(true);
    try {
      const { data } = await request({
        method: "post",
        url: "/fip/card/unblock",
        data: { cardId: cardDetails?.id },
      });
      toast.success(data?.message);
      setShowChangePin(false);
      getCardDetailsWithAccountNumber();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return showChangePin ? (
    <div className={styles.reason__container}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <h2>Change Pin</h2>
        <p
          style={{
            fontSize: "14px",
            color: "black",
            opacity: 0.8,
            cursor: "pointer",
          }}
          onClick={() => setShowChangePin(false)}
        >
          Close
        </p>
      </div>
      <Formik
        initialValues={{ pin: "" }}
        validationSchema={object().shape({
          pin: string()
            .required("Pin is required")
            .length(4, "Pin must be 4 characters long"),
        })}
        onSubmit={handleChangePin}
      >
        {() => (
          <Form className={styles.form__container}>
            <Field
              name="pin"
              placeholder="Pin"
              type="text"
              component={TextInput}
              maxLength={4}
            />
            <div className={styles.btn__container}>
              <PrimaryButton
                title={submitting ? "Processing..." : "Change Pin"}
                type="submit"
                className={styles.submit}
                disabled={submitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : error ? (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          padding: "50px 20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "5px",
          width: "100%",
        }}
      >
        <p
          style={{
            color: "black",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          Unable to get card details
        </p>
        <button
          style={{
            padding: "10px 30px",
            borderRadius: "5px",
            outline: "none",
            border: 0,
            backgroundColor: "var(--primary-color)",
            color: "white",
            fontSize: "14px",
          }}
          onClick={() => {
            setLoading(true);
            getCardDetailsWithAccountNumber();
          }}
        >
          Try Again
        </button>
      </div>
    </div>
  ) : (
    <div>
      <h2 className="mb-5">Card Details</h2>

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Masked Pan</td>
            <td
              align="right"
              style={{ maxWidth: "60%", wordWrap: "break-word" }}
            >
              {cardDetails?.maskedPan || "-"}
            </td>
          </tr>
          <tr>
            <td>Card Holder</td>
            <td
              align="right"
              style={{ maxWidth: "60%", wordWrap: "break-word" }}
            >
              {cardDetails?.cardholder || "-"}
            </td>
          </tr>
          <tr>
            <td>Batch Number</td>
            <td
              align="right"
              style={{ maxWidth: "60%", wordWrap: "break-word" }}
            >
              {cardDetails?.batchNumber || "-"}
            </td>
          </tr>
          <tr>
            <td>Account Number</td>
            <td
              align="right"
              style={{ maxWidth: "60%", wordWrap: "break-word" }}
            >
              {cardDetails?.accountNumber || "-"}
            </td>
          </tr>
          <tr>
            <td>Expiry</td>
            <td align="right">
              {cardDetails?.expiry
                ? `${cardDetails?.expiry?.slice(
                    2
                  )}/${cardDetails?.expiry?.slice(0, 2)}`
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td align="right">
              {cardDetails?.status === "active" ? "Active" : "Inactive"}
            </td>
          </tr>
          <tr>
            <td>Is Pin Set</td>
            <td align="right">{cardDetails?.pinSet ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <td>Is Card Blocked</td>
            <td align="right">{cardDetails?.isCardBlocked ? "Yes" : "No"}</td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          width: "100%",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <PrimaryButton
          title="Change Pin"
          style={{ width: "100%" }}
          size="lg"
          onClick={() => setShowChangePin(true)}
        />
        {cardDetails?.isCardBlocked ? (
          <OutlineButton
            title={submitting ? "Processing..." : "Unblock Card"}
            style={{ width: "100%" }}
            size="lg"
            onClick={handleUnblockCard}
            disabled={submitting}
          />
        ) : (
          <RedButton
            title={submitting ? "Processing..." : "Block Card"}
            style={{ width: "100%", backgroundColor: "red", color: "red" }}
            size="lg"
            onClick={handleBlockCard}
            disabled={submitting}
          />
        )}
      </div>
    </div>
  );
};

export default CardListDetails;
