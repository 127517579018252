import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateRole from "./components/CreateRole/CreateRole";
import RoleDetails from "./components/RoleDetails/RoleDetails";
import UpdateRole from "./components/UpdateRole/UpdateRole";
import request from "constants/requests";
import BankLayout from "layouts/Bank/Bank";

import AddWalletImg from "assets/images/addWallet.svg";

const RolesScreen: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [selectedRole, setSelectedRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [rolesData, permissionsData] = await Promise.all([
        request({
          method: "get",
          url: "/team/admin/roles",
        }),
        request({
          method: "get",
          url: "/team/admin/permissions",
        }),
      ]);
      setRoles(rolesData?.data?.data);
      setPermissions(permissionsData?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    fetchData();
  }, []);

  const createRole = async (payload) => {
    setCreateLoading(true);
    try {
      await request({
        method: "post",
        url: "/team/admin/roles",
        data: payload,
      });
      fetchData();
      closeModal();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setCreateLoading(false);
    }
  };

  const updateRole = async (payload) => {
    setUpdateLoading(true);
    try {
      await request({
        method: "patch",
        url: `/team/admin/roles/${payload?.id}`,
        data: { name: payload?.name, permissions: payload?.permissions },
      });
      fetchData();
      closeModal();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setUpdateLoading(false);
    }
  };

  const renderSideContent = () => {
    if (sideContent === "roleDetails") {
      return <RoleDetails content={selectedRole} permissions={permissions} />;
    }
    if (sideContent === "createRole") {
      return (
        <CreateRole
          loading={createLoading}
          onSubmit={createRole}
          permissions={permissions}
        />
      );
    }
    if (sideContent === "updateRole") {
      return (
        <UpdateRole
          loading={updateLoading}
          content={selectedRole}
          onSubmit={updateRole}
          permissions={permissions}
        />
      );
    }
    return null;
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderRows = () => {
    const rows = roles?.map((each: any, index) => {
      return (
        <tr key={each?.id}>
          <td>{each?.name}</td>
          <td>
            <PrimaryButton
              title="View"
              className="mr-2"
              size="sm"
              onClick={() => {
                openModal("roleDetails");
                setSelectedRole(each);
              }}
            />
            <OutlineButton
              title="Update"
              className="mr-2"
              size="sm"
              onClick={() => {
                openModal("updateRole");
                setSelectedRole(each);
              }}
            />
            {/* <button className='btn btn-sm btn-danger'>Delete</button> */}
          </td>
        </tr>
      );
    });

    return rows;
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <BankLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className="container-fluid">
            <div className="container-fluid d-flex justify-content-between align-items-start">
              <Header>
                Roles
                <>
                  <img
                    alt="Customer"
                    className="ml-2"
                    data-tip="Add New Role"
                    data-for="addRole"
                    src={AddWalletImg}
                    style={{ cursor: "pointer" }}
                    onClick={() => openModal("createRole")}
                  />
                  <ReactTooltip id="addRole" place="right" />
                </>
              </Header>
              <div>
                <p className="fs-3">Total Numbers of Roles</p>
                <h4 className="fs-2 fw-bold text-right">{roles?.length}</h4>
              </div>
            </div>
            <div className="container-fluid bg-white rounded px-5 py-3 mt-4">
              <Table
                noData={roles?.length === 0 ? true : false}
                headers={["ROLE", "ACTION"]}
              >
                {renderRows()}
              </Table>
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default RolesScreen;
