import React from "react";
import Header from "components/Header/Header";

import styles from "./Transaction.module.css";

const Transaction = () => {
    return (
        <div className={styles.wallet}>
            <div className="mb-4 d-flex justify-content-between">
                <Header>Transaction</Header>
            </div>
        </div>
    );
};

export default Transaction;
