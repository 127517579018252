import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import route from "routing/routes";
import styles from "./SideNav.module.css";
import { logoutFn } from "slices/authSlice";

import HomeImg from "assets/images/house.svg";
import LogOutImg from "assets/images/logout.svg";
import CustomersImg from "assets/images/person.svg";
import TransactionsImg from "assets/images/clipboard.svg";

const SideNav: React.FC = () => {
    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logoutFn());
    };

    return (
        <div className={styles.sidenav}>
            <NavItem route={route.PARTNER_HOME} routeName="Home" image={HomeImg} />
            <NavItem route={route.PARTNERMERCHANT} routeName="Primary Merchants" image={CustomersImg} />
            <NavItem route={route.PARTNER_SUBSIDIARY_MERCHANT} routeName="Subsidiary Merchants" image={CustomersImg} />
            <NavItem route={route.PARTNERTRANSACTION} routeName="Transactions" image={TransactionsImg} />

            <div onClick={logoutUser} className={styles.sidenav__logoutContainer}>
                <img src={LogOutImg} alt="Logout" />
                <span>Log Out</span>
            </div>
        </div>
    );
};

export default SideNav;

const NavItem = ({ route, routeName, image }: { route: string; routeName: string; image: string }) => (
    <NavLink className={styles.sidenav__navItem} activeClassName={styles.sidenav__navItemActive} to={route}>
        <img src={image} alt="Menu" />
        <span>{routeName}</span>
    </NavLink>
);
