import React, { Fragment, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { toast } from "react-toastify";

import styles from "./Customers.module.css";
import AddWalletImg from "assets/images/addWallet.svg";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import { selectWalletState, createWalletFn } from "slices/walletSlice";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
  getCustomersFn,
  selectCustomerState,
  getSingleCustomerFn,
  exportCustomersFn,
  selectExportCustomers,
  selectCustomerAccessModeState,
} from "slices/customersSlice";
import CreateWalletSection, {
  CreateWalletValuesTypes,
} from "screens/Merchant/Home/components/CreateWallet/CreateWallet";
import { selectProfile } from "slices/merchantSlice";
import { PrimaryButton } from "components/Button/Button";
import request from "constants/requests";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const customersState = useSelector(selectCustomerState);
  // All customers
  const {
    data: customersData,
    meta: customersMeta,
    loading: customerIsLoading,
  } = customersState.customers;
  // Single customer
  const { customerData: customer } = customersState;
  const walletState = useSelector(selectWalletState);
  const createWalletLoading = walletState.createWallet.loading;
  const { permissions: profilePermissions } = useSelector(selectProfile);
  const exportCustomerData = useSelector(selectExportCustomers);
  const mode = useSelector(selectCustomerAccessModeState);

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(customersMeta?.page || 1);
  const [searchCategory, setSearchCategory] = useState("");
  const [fetching, setFetching] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);

  const fetchCustomers = () => {
    !!profilePermissions?.includes("BROWSE_CUSTOMERS") &&
      dispatch(getCustomersFn({ page: currentPage }));
  };

  useEffect(fetchCustomers, [currentPage, profilePermissions]);

  useEffect(() => {
    if (!searchValue && searchCategory) {
      setSearchCategory("");
    }
  }, [searchValue, searchCategory]);

  const renderRows = () => {
    const rows = customersData.map((each: any) => {
      return (
        <tr
          style={{ cursor: "pointer" }}
          key={each?.id}
          onClick={() => getCustomer(each?.id)}
        >
          <td>
            {each?.lastName} {each?.firstName}
          </td>
          <td>{each?.dateOfBirth}</td>
          <td>{each?.phoneNumber}</td>
          <td>{each?.email}</td>
          <td>{format(parseISO(each?.createdAt), "dd MMMM, yyyy")}</td>
        </tr>
      );
    });
    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const createWallet = (values: CreateWalletValuesTypes) => {
    dispatch(
      createWalletFn(values, () => {
        fetchCustomers();
        closeModal();
      })
    );
  };

  const renderSideContent = () => {
    if (sideContent === "customerDetail") {
      return (
        <CustomerDetail
          closeModalFn={closeModal}
          customer={customer}
          permissions={profilePermissions}
        />
      );
    }
    if (sideContent === "createWallet") {
      return (
        <CreateWalletSection
          loading={createWalletLoading}
          error={walletState.createWallet.error}
          createWalletFn={createWallet}
        />
      );
    } else {
      return null;
    }
  };

  const createCustomerCard = async (e) => {
    const { files } = e.target;

    if (files?.length > 0) {
      setCardLoading(true);
      const formData = new FormData();
      formData.set("cards", files[0]);

      try {
        const { data } = await request({
          method: "post",
          url: "/directcard/create-cards",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success(data?.message);
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message);
      } finally {
        setCardLoading(false);
      }
    }
  };

  const getCustomer = (id: string) => {
    dispatch(getSingleCustomerFn(id));
    openModal("customerDetail");
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <DashboardLayout>
        {customerIsLoading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>
                Customers
                {!!profilePermissions?.includes("CREATE_CUSTOMER_WALLET") && (
                  <>
                    <img
                      alt="Customer"
                      className="ml-2"
                      src={AddWalletImg}
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal("createWallet")}
                      data-tip="Create Customer"
                      data-for="createCustomer"
                    />
                    <ReactTooltip id="createCustomer" place="right" />
                  </>
                )}
              </Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of customers
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {customersMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (
                    (searchValue && !searchCategory) ||
                    (!searchValue && searchCategory)
                  ) {
                    toast.error(
                      "Search value and search category are required"
                    );
                    return;
                  }

                  setFetching(true);

                  dispatch(
                    getCustomersFn({
                      search: searchValue,
                      searchBy: searchCategory,
                      cb: () => {
                        setFetching(false);
                      },
                    })
                  );
                }}
                className={styles.form__container}
              >
                <div className={styles.search__category}>
                  <div className={classNames(styles.search__div)}>
                    <label className="sr-only" htmlFor="searchCustomer">
                      Search customer
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        id="searchCustomer"
                        value={searchValue}
                        className="form-control"
                        style={{ borderLeftColor: "transparent" }}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search Customer"
                      />
                    </div>
                  </div>
                  <select
                    value={searchCategory}
                    className={styles.category}
                    onChange={(e) => setSearchCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    <option value="PHONE_NUMBER">Phone Number</option>
                    <option value="ACCOUNT_NUMBER">Account Number</option>
                  </select>
                </div>
                <label htmlFor="card" className={styles.create__card}>
                  {cardLoading ? "Creating Cards..." : "Create Customers Card"}
                  <input
                    type="file"
                    id="card"
                    onChange={createCustomerCard}
                    style={{ display: "none" }}
                  />
                </label>
                {!!customersData?.length && (
                  <PrimaryButton
                    title={
                      exportCustomerData?.loading
                        ? "Expoting..."
                        : "Export Customer List"
                    }
                    type="button"
                    onClick={() => dispatch(exportCustomersFn({ mode }))}
                    disabled={exportCustomerData?.loading}
                    className={styles.export}
                  />
                )}
              </form>
              <Table
                noData={customersData.length === 0 ? true : false}
                headers={[
                  "name",
                  "date of birth",
                  "phone number",
                  "email",
                  "date",
                ]}
              >
                {!!profilePermissions?.includes("BROWSE_CUSTOMERS") && (
                  <Fragment>
                    {fetching ? (
                      <tr>
                        <td colSpan={6}>
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      renderRows()
                    )}
                  </Fragment>
                )}
              </Table>

              {customersMeta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={customersMeta?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default CustomersScreen;
