import * as Yup from "yup";
import * as React from "react";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import routes from "routing/routes";

import AuthLayout from "layouts/Auth/Auth";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import { verifyUserFn, selectAuthState, VerificationValuesTypes, resendVerificationFn } from "slices/authSlice";

const VerificationScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { email } = useParams() as Record<string, string | undefined>;

    const authState = useSelector(selectAuthState);
    const { loading: verificationLoading } = authState?.verification;
    const { loading: resendVerificationLoading } = authState?.resendVerification;

    const submitForm = (values: VerificationValuesTypes) => {
        dispatch(verifyUserFn(values, () => history.push(routes.LOGIN)));
    };

    const resendVerificationCode = () => {
        if (email) {
            dispatch(resendVerificationFn(email));
        }
    };

    return (
        <AuthLayout title="Verification">
            <Formik
                initialValues={{
                    activationCode: "",
                }}
                validationSchema={VerificationSchema}
                onSubmit={(values) => {
                    submitForm(values);
                }}
            >
                {() => (
                    <Form>
                        <Field
                            label="Enter verification code"
                            id="code"
                            name="activationCode"
                            placeholder=""
                            type="text"
                            component={TextInput}
                        />
                        {verificationLoading || resendVerificationLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <PrimaryButton type="submit" title="Verify" block size="lg" />
                                <OutlineButton onClick={resendVerificationCode} title="Resend Code" block size="lg" />
                            </React.Fragment>
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default VerificationScreen;

const VerificationSchema = Yup.object().shape({
    activationCode: Yup.string().min(2, "Too Short!").max(70, "Too Long!").required("Required"),
});
