import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./Transactions.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactionsFn,
  selectTransactionsState,
  getSingleTransactionFn,
  selectExportApprovedTransactionsState,
  exportApprovedTransactionsFn,
  selectTransactionAccessModeState,
} from "slices/transactionsSlice";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import TransactionDetail from "./components/TransactionDetail";
import { OutlineButton, PrimaryButton } from "components/Button/Button";
import { CATEGORY_LIST, CATEGORY_DESCRIPTION } from "constants/transaction";
import routes from "routing/routes";
import { selectProfile } from "slices/merchantSlice";

import DownArrow from "assets/images/down-arrow.svg";

const TransactionsScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const transactionsState = useSelector(selectTransactionsState);
  const { permissions: profilePermissions } = useSelector(selectProfile);
  const {
    data: transactionsData,
    meta: transactionsMeta,
    loading,
  } = transactionsState.transactions;
  const transaction = transactionsState.singleTransaction;
  const exportApprovedTransactionData = useSelector(
    selectExportApprovedTransactionsState
  );
  const mode = useSelector(selectTransactionAccessModeState);

  const [currentPage, setCurrentPage] = useState(transactionsMeta?.page || 1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState("ALL");
  const [category, setCategory] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [toggleFilter, setToggleFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // const user = JSON.parse(localStorage.getItem('user'));

  const fetchTransactions = () => {
    !!profilePermissions?.includes("BROWSE_TRANSACTIONS") &&
      dispatch(
        getTransactionsFn({
          page: currentPage,
          search: searchValue,
          type,
          status: filterStatus,
          category,
          startDate,
          endDate,
        })
      );
  };

  useEffect(fetchTransactions, [currentPage, profilePermissions]);

  const handlefilter = () => {
    !!profilePermissions?.includes("BROWSE_TRANSACTIONS") &&
      dispatch(
        getTransactionsFn({
          page: currentPage,
          search: searchValue,
          type,
          status: filterStatus,
          category,
          startDate,
          endDate,
        })
      );
  };

  const renderRows = () => {
    return transactionsData.map((each: any, index: number) => {
      return transactionsData?.length ? (
        <tr
          onClick={() => getTransaction(each?.reference)}
          style={{ cursor: "pointer" }}
          key={index}
        >
          <td style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                display: "inline-block",
                backgroundColor:
                  each?.status?.toLowerCase() === "success"
                    ? each?.status?.toLowerCase() === "pending"
                      ? "#FBB900"
                      : "green"
                    : "red",
              }}
            />
            &nbsp;&nbsp;&#8358;{numberWithCommas(each?.amount)}
          </td>
          <td>{each?.type}</td>
          <td className={styles.descriptionRow}>{each?.description}</td>
          <td>{format(parseISO(each?.createdAt), "dd-MM-yyyy p")}</td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            No Transaction Available
          </td>
        </tr>
      );
    });
  };

  const getTransaction = (reference: string) => {
    dispatch(
      getSingleTransactionFn(reference, () => {
        openModal("transactionDetail");
      })
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;

    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "transactionDetail") {
      return <TransactionDetail transaction={transaction} status="approved" />;
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {loading && !toggleFilter && !transactionsData?.length ? (
          <Loading />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Transactions</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Approved Transactions
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {transactionsMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              {!!profilePermissions?.includes("BROWSE_TRANSACTIONS") && (
                <div className="mb-4 d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      className={`btn btn-lg rounded-pill px-4 btn-warning-sec mr-3`}
                      onClick={() => history.push(routes.TRANSACTIONS)}
                    >
                      Approved
                    </button>
                    <button
                      className={`btn btn-lg rounded-pill px-4 btn-light mr-3`}
                      onClick={() =>
                        history.push(`${routes.TRANSACTIONS}/pending`)
                      }
                    >
                      Pending
                    </button>
                    <button
                      className={`btn btn-lg rounded-pill px-4 btn-light`}
                      onClick={() =>
                        history.push(`${routes.TRANSACTIONS}/batch`)
                      }
                    >
                      Batch
                    </button>
                  </div>
                  <div className={styles.btn__container}>
                    {!!transactionsData?.length && (
                      <PrimaryButton
                        title={
                          exportApprovedTransactionData?.loading
                            ? "Exporting..."
                            : "Export Approved Transactions"
                        }
                        type="button"
                        disabled={exportApprovedTransactionData?.loading}
                        onClick={() =>
                          dispatch(
                            exportApprovedTransactionsFn({
                              type: type,
                              status: filterStatus,
                              mode,
                            })
                          )
                        }
                      />
                    )}
                    <OutlineButton
                      title="Print Transaction History"
                      link={routes.TRANSACTION_HISTORY}
                      target="_blank"
                    />
                  </div>
                </div>
              )}
              {!!transactionsData?.length && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getTransaction(searchValue);
                  }}
                >
                  <div className="col-auto my-3">
                    <label className="sr-only" htmlFor="searchTransaction">
                      Search transaction
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              borderRightColor: "transparent",
                              backgroundColor: "transparent",
                            }}
                          >
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                        <input
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                          style={{ borderLeftColor: "transparent" }}
                          type="text"
                          className="form-control"
                          id="searchTransaction"
                          placeholder="Search with transaction Reference"
                        />
                      </div>
                      <div className={styles.filter__container}>
                        <div
                          className={styles.filter__placeholder}
                          onClick={() => setToggleFilter(!toggleFilter)}
                        >
                          All{" "}
                          <img
                            src={DownArrow}
                            alt=""
                            className={styles.filter__icon}
                          />
                        </div>
                        <div
                          className={styles.filter__content}
                          style={{ display: toggleFilter ? "block" : "none" }}
                        >
                          <div className={styles.filter__content__top}>
                            <p className={styles.filter__top__text}>FILTER</p>
                            <button
                              className={styles.filter__button}
                              onClick={handlefilter}
                              type="button"
                            >
                              Apply
                            </button>
                          </div>
                          <div className={styles.filter__content__bottom}>
                            <select
                              onChange={(e) => setType(e.target.value)}
                              className={styles.filter__select}
                            >
                              <option value="ALL">-- Select Type --</option>
                              <option value="CREDIT">Credit</option>
                              <option value="DEBIT">Debit</option>
                            </select>
                            <select
                              onChange={(e) => setFilterStatus(e.target.value)}
                              className={styles.filter__select}
                            >
                              <option value="">-- Select Status --</option>
                              <option value="success">Success</option>
                              <option value="failed">Failed</option>
                            </select>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                              className={styles.filter__select}
                            >
                              <option value="">-- Select Category --</option>
                              {CATEGORY_LIST.map((each) => (
                                <option value={each} key={each}>
                                  {CATEGORY_DESCRIPTION[each]}
                                </option>
                              ))}
                            </select>
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={80}
                              scrollableYearDropdown
                              className={styles.date__container}
                              placeholderText="Start Date"
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={80}
                              scrollableYearDropdown
                              className={styles.date__container}
                              placeholderText="End Date"
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              <Table
                headers={["amount", "type", "description", "date"]}
                noData={
                  transactionsData?.length === 0 && !loading ? true : false
                }
              >
                {loading ? (
                  <tr className="bg-white">
                    <td colSpan={5} className="text-center">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {transactionsMeta.totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={transactionsMeta.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
      <div
        className={styles.filter__overlay}
        style={{ display: toggleFilter ? "block" : "none" }}
        onClick={() => setToggleFilter(false)}
      />
    </>
  );
};

export default TransactionsScreen;
