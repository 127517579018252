import { format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import Header from "components/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import EndOfDayHeaderSection from "./components/Transaction/Transaction";
import SideContent from "layouts/Bank/components/SideContent/SideContent";
import TransactionDetail from "./components/Transaction/TransactionDetail";
import EndOfDayTransactionList from "./components/MerchantList/MerchantList";
import ModalBackground from "layouts/Bank/components/ModalBackground/ModalBackground";

import { selectBankState, getReportFn } from "slices/bankSlice";
import styles from "./components/MerchantList/MerchantList.module.css";

const ReportScreen: React.FC = () => {
  const dispatch = useDispatch();
  const bankState = useSelector(selectBankState);
  const { loading, info, data, meta } = bankState.reports;

  const [currentPage, setCurrentPage] = useState(meta ? meta.page : 1);
  const [sideContent, setSideContent] = useState("");
  const [transaction, setTransaction] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [date, setDate] = useState<string>(
    format(subDays(new Date(), 1), "yyyy-MM-dd")
  );

  const fetchData = () => {
    dispatch(getReportFn({ page: currentPage }));
  };

  useEffect(fetchData, [bankState.accessMode, currentPage]);

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "transactionDetail") {
      return (
        <TransactionDetail
          transaction={{ data: transaction, loading }}
          closeModalFn={closeModal}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <BankLayout>
        <div className={styles.wallet}>
          <div className="mb-4 d-flex justify-content-between">
            <Header>End of day report</Header>
            <div>
              <p className={styles.totalText}>Date</p>
              <input
                type="date"
                value={date}
                max={format(subDays(new Date(), 1), "yyyy-MM-dd")}
                onChange={(e) => {
                  setDate(format(new Date(e.target.value), "yyyy-MM-dd"));
                  dispatch(getReportFn({ date: e.target.value }));
                }}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
            <EndOfDayHeaderSection data={info} />
            <div className={styles.partner__content}>
              <EndOfDayTransactionList
                data={data}
                openModal={openModal}
                setTransaction={setTransaction}
              />

              <nav aria-label="navigation">
                {meta && meta.totalPages > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={meta && meta.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </>
        )}
      </BankLayout>
    </>
  );
};

export default ReportScreen;
