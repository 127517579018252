import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import Customer from "./components/Customer/Customer";
import MerchantSection from "./components/Merchant/Merchant";
import Transaction from "./components/Transaction/Transaction";
import MerchantList from "./components/MerchantList/MerchantList";

import { selectBankHomeState, selectBankAccessModeState, getHomeRecordFn } from "slices/bankSlice";

const HomeScreen: React.FC = () => {
    const dispatch = useDispatch();

    const accessMode = useSelector(selectBankAccessModeState);

    const { loading, merchant, customer, transaction, merchantList } = useSelector(selectBankHomeState);

    const fetchData = () => {
        dispatch(getHomeRecordFn(accessMode));
    };

    useEffect(fetchData, [accessMode]);

    return (
        <BankLayout>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Transaction data={transaction} />

                    <MerchantSection data={merchant} />

                    <Customer data={customer} />

                    <MerchantList data={merchantList} />
                </>
            )}
        </BankLayout>
    );
};

export default HomeScreen;
