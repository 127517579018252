import ReactPaginate from "react-paginate";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Loading from "components/Loading/Loading";
import PartnerLayout from "layouts/Partner/Partner";
import MerchantSection from "./components/Merchant/Merchant";
import MerchantList from "./components/MerchantList/MerchantList";
import SideContent from "layouts/Partner/components/SideContent/SideContent";
import CreateMerchantSection from "./components/CreateMerchant/CreateMerchant";
import ModalBackground from "layouts/Partner/components/ModalBackground/ModalBackground";

import styles from "./Merchant.module.css";

import {
    selectPartnerModeState,
    selectCreateMerchantState,
    createMerchantAccountFn,
    fetchPartnerMerchantsFn,
    selectPartnerMerchantsState,
} from "slices/partnerSlice";

const MerchantScreen: React.FC = () => {
    const timer: any = useRef();
    const startTrackingSearch = useRef(false);

    const dispatch = useDispatch();

    const [sideContent, setSideContent] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const partnerMode = useSelector(selectPartnerModeState);
    const merchantState = useSelector(selectPartnerMerchantsState);
    const createMerchantState = useSelector(selectCreateMerchantState);

    const [currentPage, setCurrentPage] = useState(merchantState?.meta?.page || 1);

    useEffect(() => {
        dispatch(fetchPartnerMerchantsFn({ page: currentPage, mode: partnerMode }));
    }, [dispatch, currentPage, partnerMode]);

    useEffect(() => {
        if (!startTrackingSearch.current) {
            startTrackingSearch.current = true;
        } else {
            if (timer.current) {
                clearTimeout(timer.current);

                timer.current = setTimeout(() => {
                    dispatch(fetchPartnerMerchantsFn({ page: currentPage, mode: partnerMode, name: searchValue }));
                }, 500);
            } else {
                timer.current = setTimeout(() => {
                    dispatch(fetchPartnerMerchantsFn({ page: currentPage, mode: partnerMode, name: searchValue }));
                }, 500);
            }

            return () => {
                clearTimeout(timer.current);
            };
        }

        // eslint-disable-next-line
    }, [searchValue, dispatch]);

    const handlePageClick = (data) => {
        let selected = data.selected;

        setCurrentPage(selected + 1);
    };

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const handleMerchantCreation = (values) => {
        dispatch(
            createMerchantAccountFn(values, () => {
                dispatch(fetchPartnerMerchantsFn({ page: currentPage, mode: partnerMode }));
                closeModal();
            })
        );
    };

    const renderSideContent = () => {
        switch (sideContent) {
            case "createNewMerchant":
                return (
                    <CreateMerchantSection
                        loading={createMerchantState.loading}
                        CreateMerchantFn={handleMerchantCreation}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <PartnerLayout>
                {merchantState.loading ? (
                    <Loading />
                ) : (
                    <>
                        <MerchantSection
                            openModal={openModal}
                            data={merchantState?.info}
                            total={merchantState?.data?.length}
                        />

                        <div className={styles.tableContainer}>
                            <div className="col-auto my-3">
                                <label className="sr-only" htmlFor="searchCustomer">
                                    Search merchants
                                </label>
                                <div className="input-group mb-2" style={{ backgroundColor: "#87BD40", border: 0 }}>
                                    <div className="input-group-prepend">
                                        <div
                                            style={{
                                                border: 0,
                                                backgroundColor: "transparent",
                                                borderRightColor: "transparent",
                                            }}
                                            className="input-group-text"
                                        >
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        value={searchValue}
                                        id="searchCustomer"
                                        className="form-control"
                                        placeholder="Search by merchant business name"
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        style={{
                                            border: 0,
                                            color: "#000",
                                            backgroundColor: "#87BD40",
                                            borderLeftColor: "transparent",
                                        }}
                                    />
                                </div>
                            </div>
                            <MerchantList
                                data={merchantState?.data}
                            />
                        </div>

                        {merchantState?.meta?.totalPages > 1 ? (
                            <nav aria-label="Page navigation example">
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    activeClassName={"active"}
                                    pageClassName={"page-item"}
                                    nextClassName={"page-item"}
                                    onPageChange={handlePageClick}
                                    nextLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"page-link"}
                                    pageCount={merchantState?.meta?.totalPages || 0}
                                />
                            </nav>
                        ) : null}
                    </>
                )}
            </PartnerLayout>
        </>
    );
};

export default MerchantScreen;
