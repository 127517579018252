import React, { PropsWithChildren } from "react";

import Card from "./components/Card/Card";
import Navbar from "./components/Navbar/Navbar";

interface Props {
    title?: string;
}

const AuthLayout: React.FC<PropsWithChildren<Props>> = ({ children, title }) => {
    return (
        <div>
            <Navbar />
            <Card title={title}>{children}</Card>
        </div>
    );
};

export default AuthLayout;
