import React from "react";
import {useHistory} from 'react-router-dom';

import Table from "components/Table/Table";

import styles from "./PartnerList.module.css";

const MerchantList = ({ data }) => {
    const history = useHistory();

    const renderTable = () =>
        data.map((record) => (
            <tr key={record?.id} className={styles.row__style} onClick={() => history.push(`/bank/partners/${record?.id}`)}>
                <td>
                    {record?.lastName} {record?.firstName}
                </td>
                <td>{record?.email}</td>
                <td>{record?.merchants.length}</td>
            </tr>
        ));

    return (
        <div className={styles.tableContainer}>
            <Table noData={data.length === 0} headers={["PARTNER NAME", "Email", "TOTAL MERCHANTS"]}>
                {renderTable()}
            </Table>
        </div>
    );
};

export default MerchantList;
