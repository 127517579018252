import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Settings from "../Settings";
import config from "constants/api";
import Loading from "components/Loading/Loading";
import { PrimaryButton } from "components/Button/Button";
import {
    getAccessKeysFn,
    selectSettingsState,
    updateProfileFn,
    getProfileFn,
    generateNewKeyFn,
} from "slices/settingsSlice";
import { selectProfile } from "slices/merchantSlice";

const DeveloperKeysScreen: React.FC = () => {
    const dispatch = useDispatch();

    const [url, setUrl] = useState<string>("");
    const profileState = useSelector(selectSettingsState);
    const { permissions: profilePermissions } = useSelector(selectProfile);

    const { data } = profileState.getProfile;

    const isProduction = localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION";

    const callBackURL = isProduction ? data?.callbackURL : data?.sandboxCallbackURL;

    const fetchData = () => {
        dispatch(getProfileFn());
        !!profilePermissions?.includes("BROWSE_KEYS") && dispatch(getAccessKeysFn());
    };

    useEffect(fetchData, [profilePermissions]);

    useEffect(() => {
        setUrl(callBackURL || "");
    }, [callBackURL]);

    const handleCallbackChange = () => {
        dispatch(
            updateProfileFn(
                isProduction ? { callbackURL: url || callBackURL } : { sandboxCallbackURL: url || callBackURL }
            )
        );
    };

    const handleKeyGeneration = () => {
        dispatch(generateNewKeyFn());
    };

    const { privateKey, publicKey } = profileState?.getAccessKeys?.data;

    return (
        <Settings>
            {!publicKey && profileState?.getAccessKeys?.loading ? (
                <Loading />
            ) : (
                <Fragment>
                    {!!profilePermissions?.includes("BROWSE_KEYS") && (
                        <>
                            <div>
                                <p className="settings__lightText">Public Key</p>
                                <p>{publicKey}</p>
                            </div>
                            <div>
                                <p className="settings__lightText">Secret Key</p>
                                <p>{privateKey}</p>
                            </div>

                            {!!profilePermissions?.includes("GENERATE_KEYS") && (
                                <PrimaryButton
                                    className="mb-4"
                                    title="Generate new keys"
                                    onClick={handleKeyGeneration}
                                />
                            )}
                        </>
                    )}

                    <p className="settings__lightText">Callback URL</p>
                    <div className="input-group mb-3 w-50">
                        <input
                            type="text"
                            className="form-control"
                            value={url}
                            aria-describedby="callbackURL"
                            aria-label="Enter your callback URL"
                            placeholder="Enter your callback URL"
                            onChange={(e) => setUrl(e.target.value)}
                        />
                        {!!profilePermissions?.includes("UPDATE_MERCHANT_DETAIL") && (
                            <div className="input-group-append">
                                <PrimaryButton
                                    type="button"
                                    title="Update"
                                    className="form-control"
                                    onClick={handleCallbackChange}
                                    disabled={!url ? true : false}
                                />
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
        </Settings>
    );
};

export default DeveloperKeysScreen;
