import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";

import styles from "./Unload.module.css";

import BankLayout from "layouts/Bank/Bank";
import { PrimaryButton } from "components/Button/Button";
import TextInput from "components/TextInput/TextInput";
import { selectBankAccessModeState, selectMerchant, getMerchantFn } from "slices/bankSlice";
import request from "constants/requests";

const CashLoanScreen: React.FC = () => {
    const dispatch = useDispatch();

    const modeState = useSelector(selectBankAccessModeState);
    const merchantState = useSelector(selectMerchant);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getMerchantFn({ perPage: 1000 }));
    }, [dispatch]);

    const handleSubmit = async ({
        amount,
        description,
        merchant,
        cb,
    }: {
        amount: number;
        description?: string;
        merchant?: string;
        cb?: () => void;
    }) => {
        setLoading(true);
        try {
            const { data } = await request({
                method: "post",
                url: "/admin/cash-unload",
                data: { amount, mode: modeState, description, merchantId: merchant },
            });
            if (cb) {
                cb();
            }
            toast.success(data?.message);
        } catch (err) {
            toast.error(err?.response?.data?.message || err?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <BankLayout>
            <Formik
                initialValues={{
                    merchant: "",
                    amount: "",
                    description: "",
                }}
                validationSchema={WalletSchema}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit({
                        amount: Number(values.amount),
                        description: values.description,
                        merchant: values.merchant,
                        cb: () => {
                            resetForm();
                        },
                    });
                }}
            >
                {({ errors, touched }) => (
                    <div className={styles.viaWallet}>
                        <h4 className={styles.viaWallet__title}>Cash Unload</h4>
                        <Form className={styles.cash_load_form_container}>
                            {/* <div>Baba oo</div> */}
                            <div className={styles.cash_load_container}>
                                <div className="form-group">
                                    <Field
                                        name="merchant"
                                        type="string"
                                        component="select"
                                        className={`form-control form-control-lg ${
                                            errors.merchant && touched.merchant ? "is-invalid" : ""
                                        }`}
                                    >
                                        <option value="">-- Select Merchant --</option>
                                        {merchantState?.data?.map((merchant) => (
                                            <option value={merchant?.id} key={merchant?.id}>
                                                {merchant?.businessName}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="merchant" component="div" className="invalid-feedback" />
                                </div>

                                <Field name="amount" placeholder="Amount" type="text" component={TextInput} />
                                <Field name="description" placeholder="Description" type="text" component={TextInput} />

                                {loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <PrimaryButton type="submit" title="Debit Merchant" size="lg" block />
                                )}
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </BankLayout>
    );
};

export default CashLoanScreen;

const WalletSchema = object().shape({
    merchant: string().required("Merchant is required"),
    amount: string().required("Amount is required"),
    description: string(),
});
