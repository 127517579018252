import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import InviteMember from "./components/InviteMember/InviteMember";
import UpdateMemberRole from "./components/UpdateMemberRole/UpdateMemberRole";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
    selectTeamMembers,
    selectInvitations,
    selectUpdateMemberRole,
    selectInviteTeamMembers,
    getTeamMembersFn,
    selectProfile,
    getInvitationsFn,
    resendInvitationFn,
    updateMemberRoleFn,
    inviteTeamMembersFn,
} from "slices/merchantSlice";

import AddWalletImg from "assets/images/addWallet.svg";

const postsPerPage = 20;

const TeamScreen: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sideContent, setSideContent] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [teamStatus, setTeamStatus] = useState("member");
    const [selectedMember, setSelectedMember] = useState(null);

    const dispatch = useDispatch();

    const teamMembersState = useSelector(selectTeamMembers);
    const invitationsState = useSelector(selectInvitations);
    const inviteTeamMembersState = useSelector(selectInviteTeamMembers);
    const updateMemberRoleState = useSelector(selectUpdateMemberRole);
    const { permissions: profilePermissions } = useSelector(selectProfile);

    const fetchData = () => {
        if (profilePermissions?.includes("BROWSE_TEAM_MEMBER") && teamStatus === "member") {
            dispatch(getTeamMembersFn());
        }
        if (profilePermissions?.includes("BROWSE_TEAM_MEMBER") && teamStatus === "invites") {
            dispatch(getInvitationsFn({ metadata: { currentPage, postsPerPage } }));
        }
    };

    useEffect(fetchData, [teamStatus, profilePermissions]);
    useEffect(() => {
        if (inviteTeamMembersState?.data) {
            setTeamStatus("invites");
            dispatch(getInvitationsFn({ metadata: { currentPage, postsPerPage } }));
            openModal("");
            closeModal();
        }
        // eslint-disable-next-line
    }, [inviteTeamMembersState?.data]);

    useEffect(() => {
        if (updateMemberRoleState?.data) {
            setTeamStatus("member");
            dispatch(getTeamMembersFn());
            openModal("");
            closeModal();
        }
        // eslint-disable-next-line
    }, [updateMemberRoleState?.data]);

    const handleInviteMember = (payload) => {
        dispatch(inviteTeamMembersFn(payload));
    };

    const handleUpdateMemberRole = (payload) => {
        dispatch(updateMemberRoleFn(payload));
    };

    const renderSideContent = () => {
        if (sideContent === "inviteMember") {
            return <InviteMember loading={inviteTeamMembersState.loading} onSubmit={handleInviteMember} />;
        }
        if (sideContent === "editMember") {
            return (
                <UpdateMemberRole
                    loading={updateMemberRoleState.loading}
                    content={selectedMember}
                    onSubmit={handleUpdateMemberRole}
                />
            );
        }
        return null;
    };

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const renderRows = () => {
        let rows;

        if (teamStatus === "member") {
            rows = teamMembersState?.data?.length ? (
                teamMembersState?.data?.map((each: any) => {
                    return (
                        <tr key={each?.id}>
                            <td>{each?.firstName + " " + each?.lastName}</td>
                            <td>{each?.email}</td>
                            <td>{each.owner ? "Owner" : each?.role}</td>
                            <td>&#8358;{numberWithCommas(each?.approvalLimit)}</td>
                            <td>
                                {!!profilePermissions?.includes("MANAGE_TEAM_MEMBER") && (
                                    <PrimaryButton
                                        title="Edit"
                                        className="mr-2"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedMember(each);
                                            openModal("editMember");
                                        }}
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan={5} className="text-center">
                        No Member Available
                    </td>
                </tr>
            );
        }

        if (teamStatus === "invites") {
            rows = invitationsState?.data?.length ? (
                invitationsState?.data?.map((each: any) => {
                    return (
                        <tr key={each?.id}>
                            <td>{each?.email}</td>
                            <td>{each?.role}</td>
                            <td>{each?.accepted ? "Accepted" : "Pending"}</td>
                            <td>
                                {!each?.accepted && (
                                    <OutlineButton
                                        title="Resend Invitation"
                                        className="mr-2"
                                        size="sm"
                                        onClick={() => dispatch(resendInvitationFn({ email: each?.email }))}
                                    />
                                )}
                            </td>
                        </tr>
                    );
                })
            ) : (
                <tr>
                    <td colSpan={5} className="text-center">
                        No Invitation Available
                    </td>
                </tr>
            );
        }

        return rows;
    };

    const handlePageClick = (data: { selected: number }) => {
        let selected = data.selected;
        const currentPage = selected + 1;
        setCurrentPage(currentPage);
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <DashboardLayout>
                {teamMembersState.loading && !teamMembersState?.data?.length && !invitationsState?.data?.length ? (
                    <Loading />
                ) : (
                    <div className="container-fluid">
                        <div className="container-fluid d-flex justify-content-between align-items-center">
                            <Header>
                                Team
                                {!!profilePermissions?.includes("INVITE_TEAM_MEMBER") && (
                                    <>
                                        <img
                                            alt="Customer"
                                            className="ml-2"
                                            src={AddWalletImg}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => openModal("inviteMember")}
                                            data-tip="Create Team"
                                            data-for="createTeam"
                                        />
                                        <ReactTooltip id="createTeam" place="right" />
                                    </>
                                )}
                            </Header>
                            <div>
                                <p className="fs-3">
                                    {teamStatus === "member"
                                        ? "Total Numbers of Team Members"
                                        : "Total Numbers of Invited Members"}
                                </p>
                                <h4 className="fs-2 fw-bold text-right">
                                    {teamStatus === "member"
                                        ? teamMembersState?.data?.length || 0
                                        : invitationsState?.data?.length}
                                </h4>
                            </div>
                        </div>
                        <div className="container-fluid bg-white rounded px-5 py-3 mt-4">
                            <div className="mb-4">
                                <button
                                    className={`btn btn-lg rounded-pill px-4 ${
                                        teamStatus === "member" ? "btn-warning-sec" : "btn-light"
                                    } mr-3`}
                                    onClick={() => setTeamStatus("member")}
                                >
                                    Member
                                </button>
                                <button
                                    className={`btn btn-lg rounded-pill px-4 ${
                                        teamStatus === "invites" ? "btn-warning-sec" : "btn-light"
                                    }`}
                                    onClick={() => setTeamStatus("invites")}
                                >
                                    Invites
                                </button>
                            </div>

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="col-auto my-3">
                                    <label className="sr-only" htmlFor="searchTeam">
                                        Search Team Member
                                    </label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            id="searchTeam"
                                            value={searchValue}
                                            className="form-control"
                                            style={{ borderLeftColor: "transparent" }}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            placeholder="Search with member name"
                                        />
                                    </div>
                                </div>
                            </form>
                            <Table
                                noData={
                                    teamStatus === "member"
                                        ? teamMembersState?.data?.length === 0 && !teamMembersState?.loading
                                            ? true
                                            : false
                                        : invitationsState?.data?.length === 0 && !invitationsState?.loading
                                        ? true
                                        : false
                                }
                                headers={
                                    teamStatus === "member"
                                        ? ["NAME", "EMAIL", "ROLE", "APPROVAL LIMIT", "ACTION"]
                                        : ["EMAIL", "ROLE", "STATUS", "ACTION"]
                                }
                            >
                                {teamMembersState.loading || invitationsState.loading ? (
                                    <tr className="bg-white">
                                        <td colSpan={5} className="text-center">
                                            <Loading />
                                        </td>
                                    </tr>
                                ) : (
                                    renderRows()
                                )}
                            </Table>

                            {teamStatus === "member"
                                ? teamMembersState?.meta?.totalPages > 1 && (
                                      <nav aria-label="pagination">
                                          <ReactPaginate
                                              previousLabel={"previous"}
                                              nextLabel={"next"}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={5}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              pageClassName={"page-item"}
                                              pageLinkClassName={"page-link"}
                                              previousLinkClassName={"page-link"}
                                              previousClassName={"page-item"}
                                              nextLinkClassName={"page-link"}
                                              nextClassName={"page-item"}
                                              pageCount={teamMembersState?.meta?.totalPages}
                                          />
                                      </nav>
                                  )
                                : invitationsState?.meta?.totalPages > 1 && (
                                      <nav aria-label="pagination">
                                          <ReactPaginate
                                              previousLabel={"previous"}
                                              nextLabel={"next"}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={5}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              pageClassName={"page-item"}
                                              pageLinkClassName={"page-link"}
                                              previousLinkClassName={"page-link"}
                                              previousClassName={"page-item"}
                                              nextLinkClassName={"page-link"}
                                              nextClassName={"page-item"}
                                              pageCount={invitationsState?.meta?.totalPages}
                                          />
                                      </nav>
                                  )}
                        </div>
                    </div>
                )}
            </DashboardLayout>
        </>
    );
};

export default TeamScreen;
