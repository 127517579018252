import { createSlice, Dispatch } from "@reduxjs/toolkit";
import config from "constants/api";
import request from "constants/requests";
import { toast } from "react-toastify";

const initialState = {
    loading: true,
    activeMerchant: null,
    accessMode: localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION" ? "PRODUCTION" : "SANDBOX",
    home: {
        loading: true,
        merchant: null,
        customer: null,
        transaction: null,
        merchantList: [],
        error: null,
    },
    merchants: {
        data: [],
        info: {},
        error: null,
        loading: true,
        meta: { page: 1 },
    },
    subMerchant: {
        data: [],
        info: {},
        error: null,
        loading: true,
        meta: { page: 1 },
    },
    singleMerchant: {
        loading: false,
        data: null,
        error: null,
    },
    parentMerchants: {
        loading: false,
        data: [],
        meta: {},
        error: null,
    },
    transaction: {
        loading: false,
        data: [],
        info: {},
        error: null,
        meta: {},
    },
    createMerchant: {
        loading: false,
        error: null,
        data: null,
    },
    cardholders: {
        data: [],
        meta: {},
        error: false,
        loading: true,
    },
    activatedCard: {
        data: [],
        meta: {},
        error: false,
        loading: true,
    },
    activateMerchant: {
        loading: false,
        data: null,
        error: null,
    },
    deactivateMerchant: {
        loading: false,
        data: null,
        error: null,
    },
    updateMerchantInfo: {
        loading: false,
        data: null,
        error: null,
    },
    updateMerchantCharges: {
        loading: false,
        data: null,
        error: null,
    },
    updateMerchantLimit: {
        loading: false,
        data: null,
        error: null,
    },
};

const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setAccessMode: (state, { payload }) => {
            state.accessMode = payload;
        },
        resetHome: (state) => {
            state.home.loading = true;
            state.home.error = null;
            state.home.merchant = null;
            state.home.customer = null;
            state.home.merchantList = [];
            state.home.transaction = null;
        },
        setHomeSuccess: (state, { payload }) => {
            state.home.loading = false;
            state.home.merchant = payload.merchant;
            state.home.customer = payload.customer;
            state.home.merchantList = payload.merchantList;
            state.home.transaction = payload.transaction;
        },
        setHomeFailure: (state, { payload }) => {
            state.home.loading = false;
            state.home.error = payload;
            state.home.merchant = null;
            state.home.customer = null;
            state.home.merchantList = [];
            state.home.transaction = null;
        },
        getTransaction: (state) => {
            let { transaction } = state;
            transaction.data = [];
            transaction.info = {};
            transaction.meta = {};
            transaction.error = null;
            transaction.loading = true;
        },
        getTransactionSuccess: (state, { payload }) => {
            let { transaction } = state;
            transaction.data = payload.data;
            transaction.info = payload.info;
            transaction.meta = payload.meta;
            transaction.error = null;
            transaction.loading = false;
        },
        getTransactionFailure: (state, { payload }) => {
            let { transaction } = state;
            transaction.data = [];
            transaction.info = {};
            transaction.error = payload;
            transaction.meta = {};
            transaction.loading = false;
        },

        getActiveMerchant: (state, { payload }) => {
            state.activeMerchant = payload;
        },
        setCardHolderLoading: (state) => {
            state.cardholders.loading = true;
            state.cardholders.error = null;
            state.cardholders.data = [];
            state.cardholders.meta = {};
        },
        setCardHolderSuccess: (state, { payload }) => {
            state.cardholders.error = null;
            state.cardholders.data = payload.data;
            state.cardholders.meta = payload.meta;
            state.cardholders.loading = false;
        },
        setCardHolderFailure: (state, { payload }) => {
            state.cardholders.data = [];
            state.cardholders.meta = {};
            state.cardholders.error = payload;
            state.cardholders.loading = false;
        },
        setActivatedCardLoading: (state) => {
            state.activatedCard.loading = true;
        },
        setActivatedCardSuccess: (state, { payload }) => {
            state.activatedCard.error = null;
            state.activatedCard.data = payload.data;
            state.activatedCard.meta = payload.meta;
            state.activatedCard.loading = false;
        },
        setActivatedCardFailure: (state, { payload }) => {
            state.activatedCard.data = [];
            state.activatedCard.meta = {};
            state.activatedCard.error = payload;
            state.activatedCard.loading = false;
        },
        fetchMerchantStarted: (state) => {
            const { merchants } = state;

            merchants.loading = true;
            merchants.data = [];
            merchants.info = {};
            merchants.error = null;
            merchants.meta = { page: 1 };
        },
        fetchMerchantSuccess: (state, { payload }) => {
            const { merchants } = state;

            merchants.error = null;
            merchants.loading = false;
            merchants.data = payload.data;
            merchants.info = payload.info;
            merchants.meta = payload.metadata;
        },
        fetchMerchantFailed: (state, { payload }) => {
            const { merchants } = state;

            merchants.loading = false;
            merchants.data = [];
            merchants.info = {};
            merchants.error = payload;
        },
        fetchSubMerchantStarted: (state) => {
            const { subMerchant } = state;

            subMerchant.loading = true;
            subMerchant.data = [];
            subMerchant.info = {};
            subMerchant.error = null;
            subMerchant.meta = { page: 1 };
        },
        fetchSubMerchantSuccess: (state, { payload }) => {
            const { subMerchant } = state;

            subMerchant.error = null;
            subMerchant.loading = false;
            subMerchant.data = payload.data;
            subMerchant.info = payload.info;
            subMerchant.meta = payload.metadata;
        },
        fetchSubMerchantFailed: (state, { payload }) => {
            const { subMerchant } = state;

            subMerchant.loading = false;
            subMerchant.info = {};
            subMerchant.data = [];
            subMerchant.error = payload;
        },
        startCreatingMerchant: (state) => {
            const { createMerchant } = state;

            createMerchant.loading = true;
            createMerchant.error = null;
            createMerchant.data = null;
        },
        merchantCreatedSuccess: (state, { payload }) => {
            const { createMerchant } = state;

            createMerchant.loading = false;
            createMerchant.error = null;
            createMerchant.data = payload;
        },
        merchantCreatedFailed: (state, { payload }) => {
            const { createMerchant } = state;

            createMerchant.loading = false;
            createMerchant.error = payload;
            createMerchant.data = null;
        },
        resetSingleMerchant: (state) => {
            const { singleMerchant } = state;

            singleMerchant.loading = true;
            singleMerchant.data = null;
            singleMerchant.error = null;
        },
        singleMerchantSuccess: (state, { payload }) => {
            const { singleMerchant } = state;

            singleMerchant.loading = false;
            singleMerchant.data = payload.data;
            singleMerchant.error = null;
        },
        singleMerchantFailed: (state, { payload }) => {
            const { singleMerchant } = state;

            singleMerchant.loading = false;
            singleMerchant.data = null;
            singleMerchant.error = payload;
        },
        resetParentMerchants: (state) => {
            state.parentMerchants.loading = true;
            state.parentMerchants.data = [];
            state.parentMerchants.meta = {};
            state.parentMerchants.error = null;
        },
        parentMerchantsSuccess: (state, { payload }) => {
            state.parentMerchants.loading = false;
            state.parentMerchants.data = payload.data;
            state.parentMerchants.meta = payload.meta;
            state.parentMerchants.error = null;
        },
        parentMerchantsFailure: (state, { payload }) => {
            state.parentMerchants.loading = false;
            state.parentMerchants.data = [];
            state.parentMerchants.meta = {};
            state.parentMerchants.error = payload;
        },
        resetActivateMerchant: (state) => {
            const { activateMerchant } = state;

            activateMerchant.loading = true;
            activateMerchant.data = null;
            activateMerchant.error = null;
        },
        activateMerchantSuccess: (state, { payload }) => {
            const { activateMerchant } = state;

            activateMerchant.loading = false;
            activateMerchant.data = payload.data;
            activateMerchant.error = null;
        },
        activateMerchantFailed: (state, { payload }) => {
            const { activateMerchant } = state;

            activateMerchant.loading = false;
            activateMerchant.data = null;
            activateMerchant.error = payload;
        },
        resetDeactivateMerchant: (state) => {
            const { deactivateMerchant } = state;

            deactivateMerchant.loading = true;
            deactivateMerchant.data = null;
            deactivateMerchant.error = null;
        },
        deactivateMerchantSuccess: (state, { payload }) => {
            const { deactivateMerchant } = state;

            deactivateMerchant.loading = false;
            deactivateMerchant.data = payload.data;
            deactivateMerchant.error = null;
        },
        deactivateMerchantFailed: (state, { payload }) => {
            const { deactivateMerchant } = state;

            deactivateMerchant.loading = false;
            deactivateMerchant.data = null;
            deactivateMerchant.error = payload;
        },
        resetUpdateMerchantInfo: (state) => {
            const { updateMerchantInfo } = state;

            updateMerchantInfo.loading = true;
            updateMerchantInfo.data = null;
            updateMerchantInfo.error = null;
        },
        updateMerchantInfoSuccess: (state, { payload }) => {
            const { updateMerchantInfo } = state;

            updateMerchantInfo.loading = false;
            updateMerchantInfo.data = payload.data;
            updateMerchantInfo.error = null;
        },
        updateMerchantInfoFailed: (state, { payload }) => {
            const { updateMerchantInfo } = state;

            updateMerchantInfo.loading = false;
            updateMerchantInfo.data = null;
            updateMerchantInfo.error = payload;
        },
        resetUpdateMerchantCharges: (state) => {
            const { updateMerchantCharges } = state;

            updateMerchantCharges.loading = true;
            updateMerchantCharges.data = null;
            updateMerchantCharges.error = null;
        },
        updateMerchantChargesSuccess: (state, { payload }) => {
            const { updateMerchantCharges } = state;

            updateMerchantCharges.loading = false;
            updateMerchantCharges.data = payload.data;
            updateMerchantCharges.error = null;
        },
        updateMerchantChargesFailed: (state, { payload }) => {
            const { updateMerchantCharges } = state;

            updateMerchantCharges.loading = false;
            updateMerchantCharges.data = null;
            updateMerchantCharges.error = payload;
        },
        resetUpdateMerchantLimit: (state) => {
            const { updateMerchantLimit } = state;

            updateMerchantLimit.loading = true;
            updateMerchantLimit.data = null;
            updateMerchantLimit.error = null;
        },
        updateMerchantLimitSuccess: (state, { payload }) => {
            const { updateMerchantLimit } = state;

            updateMerchantLimit.loading = false;
            updateMerchantLimit.data = payload.data;
            updateMerchantLimit.error = null;
        },
        updateMerchantLimitFailed: (state, { payload }) => {
            const { updateMerchantLimit } = state;

            updateMerchantLimit.loading = false;
            updateMerchantLimit.data = null;
            updateMerchantLimit.error = payload;
        },
    },
});

export const {
    setLoading,
    setAccessMode,
    resetHome,
    setHomeSuccess,
    setHomeFailure,
    getTransaction,
    getTransactionSuccess,
    getTransactionFailure,
    getActiveMerchant,
    setCardHolderLoading,
    setCardHolderSuccess,
    setCardHolderFailure,
    setActivatedCardLoading,
    setActivatedCardSuccess,
    setActivatedCardFailure,
    fetchMerchantStarted,
    fetchMerchantSuccess,
    fetchMerchantFailed,
    fetchSubMerchantStarted,
    fetchSubMerchantSuccess,
    fetchSubMerchantFailed,
    startCreatingMerchant,
    merchantCreatedSuccess,
    merchantCreatedFailed,
    resetParentMerchants,
    parentMerchantsSuccess,
    parentMerchantsFailure,
    resetSingleMerchant,
    singleMerchantSuccess,
    singleMerchantFailed,
    resetActivateMerchant,
    activateMerchantSuccess,
    activateMerchantFailed,
    resetDeactivateMerchant,
    deactivateMerchantSuccess,
    deactivateMerchantFailed,
    resetUpdateMerchantInfo,
    updateMerchantInfoSuccess,
    updateMerchantInfoFailed,
    resetUpdateMerchantCharges,
    updateMerchantChargesSuccess,
    updateMerchantChargesFailed,
    resetUpdateMerchantLimit,
    updateMerchantLimitSuccess,
    updateMerchantLimitFailed,
} = partnerSlice.actions;

export const getHomeRecordFn =
    ({ mode }) =>
    async (dispatch) => {
        dispatch(resetHome());

        try {
            const [transactionResponse, customerResponse, merchantResponse, merchantListResponse] = await Promise.all([
                request({ method: "get", url: "/partners/analytics/transaction", params: { mode } }),
                request({ method: "get", url: "/partners/analytics/customer", params: { mode } }),
                request({ method: "get", url: "/partners/analytics/merchant", params: { mode } }),
                request({ method: "get", url: "/partners/merchant", params: { mode } }),
            ]);

            dispatch(
                setHomeSuccess({
                    merchant: merchantResponse?.data?.data,
                    customer: customerResponse?.data?.data,
                    transaction: transactionResponse?.data?.data,
                    merchantList: merchantListResponse?.data?.data,
                })
            );
        } catch (error) {
            if (error?.response) {
                dispatch(setHomeFailure(error?.response?.data || error?.response));
            } else {
                dispatch(setHomeFailure(error?.message));
            }
        }
    };

export const fetchPartnerMerchantsFn =
    ({ page = 1, mode, name }: { page?: number; mode: string; name?: string }) =>
    async (dispatch: Dispatch) => {
        if (page === 1) {
            dispatch(fetchMerchantStarted());
        }

        try {
            const [infoResponse, merchantRecord] = await Promise.all([
                request({ method: "get", url: "/partners/analytics/merchant/main", params: { mode } }),
                request({
                    method: "get",
                    url: "/partners/merchant/main",
                    params: { page, mode, name },
                }),
            ]);

            if (merchantRecord?.data && infoResponse?.data && merchantRecord?.data?.data) {
                dispatch(fetchMerchantSuccess({ ...merchantRecord?.data, info: infoResponse?.data?.data }));
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchMerchantFailed(error?.response?.data || error?.response));
            } else {
                dispatch(fetchMerchantFailed(error?.message));
            }
        }
    };

export const fetchPartnerSubMerchantsFn =
    ({ page = 1, mode, name, activeMerchant }: { page?: number; mode: string; name: string; activeMerchant: any }) =>
    async (dispatch: Dispatch) => {
        if (page === 1) {
            dispatch(fetchSubMerchantStarted());
        }

        try {
            const [infoResponse, merchantRecord] = await Promise.all([
                request({
                    method: "get",
                    url: "/partners/analytics/merchant/subsidiary",
                    params: { merchantId: activeMerchant ? activeMerchant.id : undefined },
                }),
                request({
                    method: "get",
                    url: "/partners/merchant/subsidiary",
                    params: { mode, page, name, merchantId: activeMerchant ? activeMerchant.id : undefined },
                }),
            ]);

            if (merchantRecord?.data && infoResponse?.data && merchantRecord?.data?.data) {
                dispatch(fetchSubMerchantSuccess({ ...merchantRecord?.data, info: infoResponse?.data?.data }));
            }
        } catch (error) {
            if (error?.response) {
                dispatch(fetchSubMerchantFailed(error?.response?.data || error?.response));
            } else {
                dispatch(fetchSubMerchantFailed(error?.message));
            }
        }
    };

export const fetchTransactionFn =
    ({ page = 1, mode, activeMerchant }) =>
    async (dispatch) => {
        if (page === 1) {
            dispatch(getTransaction());
        }

        try {
            const [infoResponse, merchantRecord] = await Promise.all([
                request({ method: "get", url: "/partners/analytics/transaction", params: { mode } }),
                request({
                    method: "get",
                    url: "/partners/transactions",
                    params: {
                        page,
                        mode,
                        type: "ALL",
                        merchantId: activeMerchant ? activeMerchant?.id : undefined,
                    },
                }),
            ]);

            if (merchantRecord?.data && infoResponse?.data && merchantRecord?.data?.data) {
                dispatch(
                    getTransactionSuccess({
                        info: infoResponse?.data?.data,
                        data: merchantRecord?.data?.data,
                        meta: merchantRecord?.data?.metadata,
                    })
                );
            }
        } catch (error) {
            if (error?.response) {
                dispatch(getTransactionFailure(error?.response?.data || error?.response));
            } else {
                dispatch(getTransactionFailure(error?.message));
            }
        }
    };

export const getTransactionFn =
    ({ merchantId }) =>
    async (dispatch, getState) => {
        const { accessMode } = getState().partner;

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/transactions",
                params: {
                    type: "ALL",
                    mode: accessMode,
                    merchantId,
                },
            });

            dispatch(getTransactionSuccess({ data: data?.data, meta: data?.metadata }));
        } catch (error) {
            if (error?.response) {
                dispatch(getTransactionFailure(error?.response?.data || error?.response));
            } else {
                dispatch(getTransactionFailure(error?.message));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export const toggleAccessModeFn =
    ({ accessMode, merchantId }) =>
    async (dispatch) => {
        dispatch(setAccessMode(accessMode));

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/transactions",
                params: {
                    type: "ALL",
                    mode: accessMode,
                    merchantId,
                },
            });

            dispatch(getTransactionSuccess({ data: data?.data, meta: data?.metadata }));
        } catch (error) {
            if (error?.response) {
                dispatch(getTransactionFailure(error?.response?.data || error?.response));
            } else {
                dispatch(getTransactionFailure(error?.message));
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export const getTransactionByFilterFn =
    ({ merchantId, type, mode, status, category, reference }) =>
    async (dispatch) => {
        try {
            const [infoResponse, merchantRecord] = await Promise.all([
                request({ method: "get", url: "/partners/analytics/transaction", params: { mode } }),
                request({
                    method: "get",
                    url: "/partners/transactions",
                    params: {
                        type,
                        mode,
                        status,
                        category,
                        reference,
                        merchantId,
                    },
                }),
            ]);

            if (merchantRecord?.data && infoResponse?.data && merchantRecord?.data?.data) {
                dispatch(
                    getTransactionSuccess({
                        info: infoResponse?.data?.data,
                        data: merchantRecord?.data?.data,
                        meta: merchantRecord?.data?.metadata,
                    })
                );
            }
        } catch (error) {
            if (error?.response) {
                dispatch(getTransactionFailure(error?.response?.data || error?.response));
            } else {
                dispatch(getTransactionFailure(error?.message));
            }
        }
    };

export const createMerchantAccountFn = (merchantInfo, cb) => async (dispatch) => {
    dispatch(startCreatingMerchant());
    try {
        const { data } = await request({
            method: "post",
            url: "/partners/merchant",
            data: merchantInfo,
        });

        if (data && data?.status) {
            dispatch(merchantCreatedSuccess(data?.data));
            toast.success("Merchant successfully created.");

            cb();
        } else {
            throw new Error();
        }
    } catch (error) {
        if (error?.response) {
            dispatch(merchantCreatedFailed(error?.response?.data || error?.response));
            toast.error(error?.response?.data || error?.response);
        } else {
            dispatch(merchantCreatedFailed(error?.message));
            toast.error(error?.message);
        }
    }
};

export const switchUserFn = (merchant) => async (dispatch, getState) => {
    if (!merchant) {
        dispatch(getActiveMerchant(merchant));
    } else {
        const { activeMerchant } = getState().partner;

        if (!activeMerchant || activeMerchant?.id !== merchant?.id) {
            dispatch(getActiveMerchant(merchant));
        }
    }
};

export const getMerchantCardHolderFn =
    ({ page, name }) =>
    async (dispatch, getState) => {
        const { accessMode, activeMerchant } = getState().partner;

        if (page === 1) {
            dispatch(setCardHolderLoading());
        }

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/card-holders",
                params: {
                    page,
                    name,
                    mode: accessMode,
                    merchantId: activeMerchant?.id,
                },
            });

            dispatch(setCardHolderSuccess({ data: data?.data, meta: data?.metadata }));
        } catch (error) {
            if (error?.response) {
                dispatch(setCardHolderFailure(error?.response?.data || error?.response));
            } else {
                dispatch(setCardHolderFailure(error?.message));
            }
        }
    };

export const getMerchantActivatedCardFn =
    ({ page }) =>
    async (dispatch, getState) => {
        const { accessMode, activeMerchant } = getState().partner;

        if (page === 1) {
            dispatch(setActivatedCardLoading());
        }

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/activated-cards",
                params: {
                    page,
                    mode: accessMode,
                    merchantId: activeMerchant?.id,
                },
            });

            dispatch(setActivatedCardSuccess({ data: data?.data, meta: data?.metadata }));
        } catch (error) {
            if (error?.response) {
                dispatch(setActivatedCardFailure(error?.response?.data || error?.response));
            } else {
                dispatch(setActivatedCardFailure(error?.message));
            }
        }
    };

export const getSingleMerchantFn =
    ({ merchantId, mode }) =>
    async (dispatch) => {
        dispatch(resetSingleMerchant());

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/merchant/" + merchantId,
                params: { mode },
            });

            dispatch(singleMerchantSuccess({ data: data?.data }));
        } catch (error) {
            if (error?.response) {
                dispatch(singleMerchantFailed(error?.response?.data || error?.response));
            } else {
                dispatch(singleMerchantFailed(error?.message));
            }
        }
    };

export const activateMerchantFn =
    ({ merchantId }, cb) =>
    async (dispatch) => {
        dispatch(resetActivateMerchant());

        try {
            const { data } = await request({
                method: "patch",
                url: "/merchant/activate",
                data: { merchantId },
            });

            dispatch(activateMerchantSuccess({ data: data?.message }));
            toast.success(data?.message);
            cb();
        } catch (error) {
            if (error?.response) {
                dispatch(activateMerchantFailed(error?.response?.data.message));
                toast.error(error?.response?.data.message);
            } else {
                dispatch(activateMerchantFailed(error?.message));
                toast.error(error?.message);
            }
        }
    };

export const deactivateMerchantFn =
    ({ merchantId }, cb) =>
    async (dispatch) => {
        dispatch(resetDeactivateMerchant());

        try {
            const { data } = await request({
                method: "patch",
                url: "/merchant/deactivate",
                data: { merchantId },
            });

            dispatch(deactivateMerchantSuccess({ data: data?.message }));
            toast.success(data?.message);
            cb();
        } catch (error) {
            if (error?.response) {
                dispatch(deactivateMerchantFailed(error?.response?.data.message));
                toast.error(error?.response?.data.message);
            } else {
                dispatch(deactivateMerchantFailed(error?.message));
                toast.error(error?.message);
            }
        }
    };

export const updateMerchantInfoFn = (merchantId: string, basicInformations: any, capabilities: any) => async (dispatch) => {
    dispatch(resetUpdateMerchantInfo());

    try {
        await request({
            method: "put",
            url: "/partners/merchant/" + merchantId,
            data: { ...basicInformations, ...capabilities },
        });

        dispatch(updateMerchantInfoSuccess({ data: "Merchant informations was updated successfully" }));
        toast.success("Merchant informations was updated successfully");
    } catch (error) {
        if (error?.response) {
            dispatch(updateMerchantInfoFailed(error?.response?.data || error?.response));
            toast.error(error?.response?.data || error?.response);
        } else {
            dispatch(updateMerchantInfoFailed(error?.message));
            toast.error(error?.message);
        }
    }
};

export const updateMerchantChargesFn =
    ({ merchantId, chargesInformations }) =>
    async (dispatch) => {
        dispatch(resetUpdateMerchantCharges());

        try {
            await request({
                method: "patch",
                url: "/merchant/charges",
                data: { merchantId, ...chargesInformations },
            });

            dispatch(updateMerchantChargesSuccess({ data: "Merchant charges was updated successfully" }));
            toast.success("Merchant charges was updated successfully");
        } catch (error) {
            if (error?.response) {
                dispatch(updateMerchantChargesFailed(error?.response?.data || error?.response));
                toast.error(error?.response?.data || error?.response);
            } else {
                dispatch(updateMerchantChargesFailed(error?.message));
                toast.error(error?.message);
            }
        }
    };

export const updateMerchantLimitFn =
    ({ merchantId, limitInformations }) =>
    async (dispatch) => {
        dispatch(resetUpdateMerchantLimit());

        try {
            await request({
                method: "patch",
                url: "/merchant/limits",
                data: { merchantId, ...limitInformations },
            });

            dispatch(updateMerchantLimitSuccess({ data: "Merchant limit was updated successfully" }));
            toast.success("Merchant limit was updated successfully");
        } catch (error) {
            if (error?.response) {
                dispatch(updateMerchantLimitFailed(error?.response?.data || error.response));
                toast.error(error?.response?.data || error?.response);
            } else {
                dispatch(updateMerchantLimitFailed(error?.message));
                toast.error(error?.message);
            }
        }
    };

export const getParentMerchantsFn =
    ({ metadata }) =>
    async (dispatch) => {
        dispatch(resetParentMerchants());

        try {
            const { data } = await request({
                method: "get",
                url: "/partners/merchant/main",
                params: { page: metadata?.currentPage, perPage: metadata?.postsPerPage },
            });

            dispatch(
                parentMerchantsSuccess({
                    data: data.data?.map((each) => {
                        return {
                            id: each?.id,
                            businessName: each?.businessName,
                        };
                    }),
                    meta: data?.metadata,
                })
            );
        } catch (error) {
            if (error?.response) {
                dispatch(parentMerchantsFailure(error?.response?.data || error?.response));
            } else {
                dispatch(parentMerchantsFailure(error?.message));
            }
        }
    };

export const selectPartnerState = (state) => state.partner;
export const selectPartnerHomeState = (state) => state.partner.home;
export const selectPartnerMerchantsState = (state) => state.partner.merchants;
export const selectPartnerTransactionState = (state) => state.partner.transaction;
export const selectCreateMerchantState = (state) => state.partner.createMerchant;
export const selectActiveMerchantState = (state) => state.partner.activeMerchant;
export const selectPartnerSubMerchantsState = (state) => state.partner.subMerchant;
export const selectPartnerModeState = (state) => state.partner.accessMode || "PRODUCTION";
export const selectSingleMerchantState = (state) => state.partner.singleMerchant;
export const selectParentMerchantsState = (state) => state.partner.parentMerchants;

export default partnerSlice.reducer;
