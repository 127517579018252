import React, { Fragment, useEffect } from "react";
import { object, string} from "yup";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import {selectMerchantRoles, getRolesFn, selectProfile} from 'slices/merchantSlice';

const validationSchema = object().shape({
    email: string().email('Invalid email').required('Email is required'),
    role: string().required('Role is required'),
    approvalLimit: string().required('Approval limit is required')
});

const InviteMember: React.FC<{ loading: boolean; onSubmit: any }> = ({ loading, onSubmit }) => {
    const dispatch = useDispatch();

    const rolesState = useSelector(selectMerchantRoles);
    const {permissions: profilePermissions} = useSelector(selectProfile);

    const fetchRoles = () => {
        !!profilePermissions?.includes('BROWSE_ROLES') && dispatch(getRolesFn());
    }

    useEffect(fetchRoles, []);

    return (
        <div>
            <h2 className="mb-5">Invite Member</h2>
            <Formik
                onSubmit={(values) => onSubmit({email: values.email, roleId: values.role, approvalLimit: values.approvalLimit})}
                validationSchema={validationSchema}
                initialValues={{
                    email: '',
                    role: '',
                    approvalLimit: ''
                }}
            >
                {({errors, touched}) => {
                    return (
                        <Form>
                            <Field name="email" placeholder="Email*" type="text" component={TextInput} />
                            <Field name="role" component='select' className={`form-control form-control-lg mb-3 ${
                                errors.role && touched.role ? "is-invalid" : ""}`} placeholder="Role*" type="text">
                                <option value=''>-- Select Role --</option>
                                {rolesState?.data?.map((each) => <option value={each?.id}>{each?.name}</option>)}
                            </Field>
                            <Field name="approvalLimit" placeholder="Approval Limit*" type="text" component={TextInput} />
                            

                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <PrimaryButton type="submit" block size="lg" title="Invite" />
                                </Fragment>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default InviteMember;


