import * as React from "react";
import styles from "./SectionSelector.module.css";
import { Link, useLocation } from "react-router-dom";

import routes from "routing/routes";

const SectionSelector: React.FC = () => {
  const { pathname } = useLocation();
  const bank = pathname.includes("via-bank");

  return (
    <section className="d-flex">
      {bank ? (
        <div>
          <Link to={`${routes.VIA_BANK}`} style={{ textDecoration: "none" }}>
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Bank Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.VIA_BANK}/cutomer`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Customer Bank Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.VIA_BANK}/batch`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Bank Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          {/* <Link to={`${routes.VIA_BANK}/batch-cutomer`} style={{textDecoration: 'none'}}>
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Customers Bank Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link> */}
        </div>
      ) : (
        <div>
          <Link to={routes.SEND_MONEY} style={{ textDecoration: "none" }}>
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Credit Customer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/debit`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Debit Customer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/customer-to-customer-transfer`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Customer To Customer Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/batch-credit`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Credit Customers</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/batch-credit-csv`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Credit Customers Via CSV</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/batch-debit`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Debit Customers</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>

          <Link
            to={`${routes.SEND_MONEY}/batch-customer-to-customer-transfer`}
            style={{ textDecoration: "none" }}
          >
            <div
              className={`${styles.section__itemActive} ${styles.section__item}`}
            >
              <span>Batch Customer To Customers Transfer</span>
              <i className="fas fa-caret-right"></i>
            </div>
          </Link>
        </div>
      )}

      <div className={styles.vl} />
    </section>
  );
};

export default SectionSelector;
