import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import request from "constants/requests";
import { handlingErrors } from "utils/helpers";
import config from "../constants/api";

const initialState = {
  accessMode:
    localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION"
      ? "PRODUCTION"
      : "SANDBOX",
  transactions: {
    loading: false,
    data: [],
    error: {},
    meta: {},
  },
  pendingTransactions: {
    loading: false,
    data: [],
    error: {},
    meta: {},
  },
  singleTransaction: {
    loading: false,
    data: {},
    error: {},
  },
  approveTransaction: {
    loading: false,
    data: null,
    error: null,
  },
  transactionsHistory: {
    loading: false,
    data: [],
    error: null,
  },
  customerTransactionsHistory: {
    loading: false,
    data: [],
    error: null,
  },
  customerTransactions: {
    loading: false,
    data: [],
    meta: null,
    error: null,
  },
  deletePendingTransaction: {
    loading: false,
    data: null,
    error: null,
  },
  batchTransactions: {
    loading: false,
    data: null,
    error: null,
    meta: null,
  },
  singleBatchTransaction: {
    loading: false,
    data: null,
    error: null,
  },
  exportApprovedTransactions: {
    loading: false,
    data: null,
    error: null,
  },
  exportPendingTransactions: {
    loading: false,
    data: null,
    error: null,
  },
  exportBatchTransactions: {
    loading: false,
    data: null,
    error: null,
  },
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialState,
  reducers: {
    getTransactions: (state) => {
      let { transactions } = state;
      transactions.loading = true;
      transactions.data = [];
      transactions.error = {};
      transactions.meta = {};
    },
    getTransactionsSuccess: (state, { payload }) => {
      let { transactions } = state;
      transactions.loading = false;
      transactions.data = payload.data;
      transactions.error = {};
      transactions.meta = payload.meta;
    },
    getTransactionsFailed: (state, { payload }) => {
      let { transactions } = state;
      transactions.loading = false;
      transactions.data = [];
      transactions.error = payload;
      transactions.meta = {};
    },
    resetPendingTransactions: (state) => {
      let { pendingTransactions } = state;

      pendingTransactions.loading = true;
      pendingTransactions.data = [];
      pendingTransactions.error = {};
      pendingTransactions.meta = {};
    },
    getPendingTransactionsSuccess: (state, { payload }) => {
      let { pendingTransactions } = state;

      pendingTransactions.loading = false;
      pendingTransactions.data = payload.data;
      pendingTransactions.error = {};
      pendingTransactions.meta = payload.meta;
    },
    getPendingTransactionsFailed: (state, { payload }) => {
      let { pendingTransactions } = state;

      pendingTransactions.loading = false;
      pendingTransactions.data = [];
      pendingTransactions.error = payload;
      pendingTransactions.meta = {};
    },
    getSingleTransaction: (state) => {
      let { singleTransaction } = state;
      singleTransaction.loading = true;
      singleTransaction.data = {};
      singleTransaction.error = {};
    },
    getSingleTransactionSuccess: (state, { payload }) => {
      let { singleTransaction } = state;
      singleTransaction.loading = false;
      singleTransaction.data = payload;
      singleTransaction.error = {};
    },
    getSingleTransactionFailed: (state, { payload }) => {
      let { singleTransaction } = state;
      singleTransaction.loading = false;
      singleTransaction.data = {};
      singleTransaction.error = payload;
    },
    resetApproveTransaction: (state) => {
      const { approveTransaction } = state;

      approveTransaction.loading = true;
      approveTransaction.data = null;
      approveTransaction.error = null;
    },
    approveTransactionSuccess: (state, { payload }) => {
      const { approveTransaction } = state;

      approveTransaction.loading = false;
      approveTransaction.data = payload.data;
      approveTransaction.error = null;
    },
    approveTransactionFailed: (state, { payload }) => {
      const { approveTransaction } = state;

      approveTransaction.loading = false;
      approveTransaction.data = null;
      approveTransaction.error = payload;
    },
    resetTransactionsHistory: (state) => {
      const { transactionsHistory } = state;

      transactionsHistory.loading = true;
      transactionsHistory.data = [];
      transactionsHistory.error = null;
    },
    transactionsHistorySuccess: (state, { payload }) => {
      const { transactionsHistory } = state;

      transactionsHistory.loading = false;
      transactionsHistory.data = payload.data;
      transactionsHistory.error = null;
    },
    transactionsHistoryFailed: (state, { payload }) => {
      const { transactionsHistory } = state;

      transactionsHistory.loading = true;
      transactionsHistory.data = [];
      transactionsHistory.error = payload;
    },
    resetCustomerTransactionsHistory: (state) => {
      const { customerTransactionsHistory } = state;

      customerTransactionsHistory.loading = true;
      customerTransactionsHistory.data = [];
      customerTransactionsHistory.error = null;
    },
    customerTransactionsHistorySuccess: (state, { payload }) => {
      const { customerTransactionsHistory } = state;

      customerTransactionsHistory.loading = false;
      customerTransactionsHistory.data = payload.data;
      customerTransactionsHistory.error = null;
    },
    customerTransactionsHistoryFailed: (state, { payload }) => {
      const { customerTransactionsHistory } = state;

      customerTransactionsHistory.loading = true;
      customerTransactionsHistory.data = [];
      customerTransactionsHistory.error = payload;
    },
    resetCustomerTransactions: (state) => {
      const { customerTransactions } = state;

      customerTransactions.loading = true;
      customerTransactions.data = [];
      customerTransactions.meta = null;
      customerTransactions.error = null;
    },
    customerTransactionsSuccess: (state, { payload }) => {
      const { customerTransactions } = state;

      customerTransactions.loading = false;
      customerTransactions.data = payload.data;
      customerTransactions.meta = payload.meta;
      customerTransactions.error = null;
    },
    customerTransactionsFailure: (state, { payload }) => {
      const { customerTransactions } = state;

      customerTransactions.loading = false;
      customerTransactions.data = [];
      customerTransactions.meta = null;
      customerTransactions.error = payload;
    },
    resetDeletePendingTransaction: (state) => {
      const { deletePendingTransaction } = state;

      deletePendingTransaction.loading = true;
      deletePendingTransaction.data = null;
      deletePendingTransaction.error = null;
    },
    deletePendingTransactionSuccess: (state, { payload }) => {
      const { deletePendingTransaction } = state;

      deletePendingTransaction.loading = false;
      deletePendingTransaction.data = payload;
      deletePendingTransaction.error = null;
    },
    deletePendingTransactionFailure: (state, { payload }) => {
      const { deletePendingTransaction } = state;

      deletePendingTransaction.loading = true;
      deletePendingTransaction.data = null;
      deletePendingTransaction.error = payload;
    },
    resetBatchTransactions: (state) => {
      const { batchTransactions } = state;

      batchTransactions.loading = true;
      batchTransactions.data = null;
      batchTransactions.meta = null;
      batchTransactions.error = null;
    },
    batchTransactionsSuccess: (state, { payload }) => {
      const { batchTransactions } = state;

      batchTransactions.loading = false;
      batchTransactions.data = payload.data;
      batchTransactions.meta = payload.meta;
      batchTransactions.error = null;
    },
    batchTransactionsFailure: (state, { payload }) => {
      const { batchTransactions } = state;

      batchTransactions.loading = false;
      batchTransactions.data = null;
      batchTransactions.meta = null;
      batchTransactions.error = payload;
    },
    resetSingleBatchTransaction: (state) => {
      const { singleBatchTransaction } = state;

      singleBatchTransaction.loading = true;
      singleBatchTransaction.data = null;
      singleBatchTransaction.error = null;
    },
    singleBatchTransactionSuccess: (state, { payload }) => {
      const { singleBatchTransaction } = state;

      singleBatchTransaction.loading = false;
      singleBatchTransaction.data = payload;
      singleBatchTransaction.error = null;
    },
    singleBatchTransactionFailure: (state, { payload }) => {
      const { singleBatchTransaction } = state;

      singleBatchTransaction.loading = false;
      singleBatchTransaction.data = null;
      singleBatchTransaction.error = payload;
    },
    resetExportApprovedTransactions: (state) => {
      const { exportApprovedTransactions } = state;

      exportApprovedTransactions.loading = true;
      exportApprovedTransactions.data = null;
      exportApprovedTransactions.error = null;
    },
    exportApprovedTransactionsSuccess: (state, { payload }) => {
      const { exportApprovedTransactions } = state;

      exportApprovedTransactions.loading = false;
      exportApprovedTransactions.data = payload;
      exportApprovedTransactions.error = null;
    },
    exportApprovedTransactionsFailure: (state, { payload }) => {
      const { exportApprovedTransactions } = state;

      exportApprovedTransactions.loading = false;
      exportApprovedTransactions.data = null;
      exportApprovedTransactions.error = payload;
    },
    resetExportPendingTransactions: (state) => {
      const { exportPendingTransactions } = state;

      exportPendingTransactions.loading = true;
      exportPendingTransactions.data = null;
      exportPendingTransactions.error = null;
    },
    exportPendingTransactionsSuccess: (state, { payload }) => {
      const { exportPendingTransactions } = state;

      exportPendingTransactions.loading = false;
      exportPendingTransactions.data = payload;
      exportPendingTransactions.error = null;
    },
    exportPendingTransactionsFailure: (state, { payload }) => {
      const { exportPendingTransactions } = state;

      exportPendingTransactions.loading = false;
      exportPendingTransactions.data = null;
      exportPendingTransactions.error = payload;
    },
    resetExportBatchTransactions: (state) => {
      const { exportBatchTransactions } = state;

      exportBatchTransactions.loading = true;
      exportBatchTransactions.data = null;
      exportBatchTransactions.error = null;
    },
    exportBatchTransactionsSuccess: (state, { payload }) => {
      const { exportBatchTransactions } = state;

      exportBatchTransactions.loading = false;
      exportBatchTransactions.data = payload;
      exportBatchTransactions.error = null;
    },
    exportBatchTransactionsFailure: (state, { payload }) => {
      const { exportBatchTransactions } = state;

      exportBatchTransactions.loading = false;
      exportBatchTransactions.data = null;
      exportBatchTransactions.error = payload;
    },
  },
});

export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  resetPendingTransactions,
  getPendingTransactionsSuccess,
  getPendingTransactionsFailed,
  getSingleTransaction,
  getSingleTransactionSuccess,
  getSingleTransactionFailed,
  resetApproveTransaction,
  approveTransactionSuccess,
  approveTransactionFailed,
  resetTransactionsHistory,
  transactionsHistorySuccess,
  transactionsHistoryFailed,
  resetCustomerTransactionsHistory,
  customerTransactionsHistorySuccess,
  customerTransactionsHistoryFailed,
  resetCustomerTransactions,
  customerTransactionsSuccess,
  customerTransactionsFailure,
  resetDeletePendingTransaction,
  deletePendingTransactionSuccess,
  deletePendingTransactionFailure,
  resetBatchTransactions,
  batchTransactionsSuccess,
  batchTransactionsFailure,
  resetSingleBatchTransaction,
  singleBatchTransactionSuccess,
  singleBatchTransactionFailure,
  resetExportApprovedTransactions,
  exportApprovedTransactionsSuccess,
  exportApprovedTransactionsFailure,
  resetExportPendingTransactions,
  exportPendingTransactionsSuccess,
  exportPendingTransactionsFailure,
  resetExportBatchTransactions,
  exportBatchTransactionsSuccess,
  exportBatchTransactionsFailure,
} = transactionSlice.actions;

export const getTransactionsFn =
  ({ page, search, type, status, category, startDate, endDate }) =>
  async (dispatch) => {
    try {
      if (page === 1) {
        dispatch(getTransactions());
      }

      const params: any = {};

      if (page) {
        params.page = page;
      }
      if (search) {
        params.search = search;
      }
      if (type) {
        params.type = type;
      }
      if (status) {
        params.status = status;
      }
      if (category) {
        params.category = category;
      }
      if (startDate) {
        params.startDate = startDate;
      }
      if (endDate) {
        params.endDate = endDate;
      }

      const response = await request({
        method: "get",
        url: "/merchant/transactions",
        params,
      });

      dispatch(
        getTransactionsSuccess({
          data: response?.data?.transactions,
          meta: response?.data?.metadata,
        })
      );
    } catch (error) {
      handlingErrors(error, dispatch, getTransactionsFailed);
    }
  };
export const getSingleTransactionFn =
  (reference: string, cb?: () => void) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(getSingleTransaction());

      const response = await request({
        method: "get",
        url: `/merchant/transaction/${reference}`,
      });

      dispatch(getSingleTransactionSuccess(response?.data?.transaction));
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        getSingleTransactionFailed(error?.response?.data || error?.response)
      );
    }
  };

export const getPendingTransactionsFn =
  ({ page, type, category, startDate, endDate }) =>
  async (dispatch) => {
    if (page === 1) {
      dispatch(resetPendingTransactions());
    }

    try {
      const transactionParams: any = {};

      transactionParams.page = page;

      if (type) {
        transactionParams.type = type;
      }

      if (category) {
        transactionParams.category = category;
      }
      if (startDate) {
        transactionParams.startDate = startDate;
      }
      if (endDate) {
        transactionParams.endDate = endDate;
      }

      const { data } = await request({
        method: "get",
        url: "/transaction/pending",
        params: transactionParams,
      });

      dispatch(
        getPendingTransactionsSuccess({
          data: data?.data,
          meta: data?.metadata,
        })
      );
    } catch (error) {
      if (error?.response) {
        dispatch(
          getPendingTransactionsFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(getPendingTransactionsFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const approveTransactionFn =
  ({ transactionId }, cb) =>
  async (dispatch) => {
    dispatch(resetApproveTransaction());

    try {
      await request({
        method: "post",
        url: "/transaction/approve",
        data: { transactionId },
      });

      dispatch(
        approveTransactionSuccess({ data: "Transaction approved successfully" })
      );
      toast.success("Transaction has been approved successfully");
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(
          approveTransactionFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(approveTransactionFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getTransactionsHistoryFn = (cb) => async (dispatch) => {
  dispatch(resetTransactionsHistory());

  try {
    const { data } = await request({
      method: "get",
      url: "/transaction/download/merchant",
    });

    dispatch(transactionsHistorySuccess({ data: data?.data }));
    cb();
  } catch (error) {
    if (error?.response) {
      dispatch(
        transactionsHistoryFailed(error?.response?.data || error?.response)
      );
    } else {
      dispatch(transactionsHistoryFailed(error?.message));
    }
  }
};

export const getCustomerTransactionsHistoryFn =
  (customer, cb) => async (dispatch) => {
    dispatch(resetCustomerTransactionsHistory());

    try {
      const { data } = await request({
        method: "get",
        url: "/transaction/download/customer",
        params: customer,
      });

      dispatch(customerTransactionsHistorySuccess({ data: data?.data }));
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(
          customerTransactionsHistoryFailed(
            error?.response?.data || error?.response
          )
        );
      } else {
        dispatch(customerTransactionsHistoryFailed(error?.message));
      }
    }
  };

export const getCustomerTransactionsFn =
  ({ customerId, page, type, search, status, category, startDate, endDate }) =>
  async (dispatch) => {
    try {
      if (page === 1) {
        dispatch(resetCustomerTransactions());
      }

      const params: any = {};

      if (customerId) {
        params.customerId = customerId;
      }
      if (page) {
        params.page = page;
      }
      if (search) {
        params.search = search;
      }
      if (type) {
        params.type = type;
      }
      if (status) {
        params.status = status;
      }
      if (category) {
        params.category = category;
      }
      if (startDate) {
        params.startDate = startDate;
      }
      if (endDate) {
        params.endDate = endDate;
      }

      const { data } = await request({
        method: "get",
        url: "/transaction/customer",
        params,
      });

      dispatch(
        customerTransactionsSuccess({
          data: data?.transactions,
          meta: data?.metadata,
        })
      );
      console.log(data);
    } catch (error) {
      handlingErrors(error, dispatch, customerTransactionsFailure);
    }
  };

export const deletePendingTransactionFn =
  (transactionId: string, cb: () => void) => async (dispatch) => {
    try {
      dispatch(resetDeletePendingTransaction());

      const { data } = await request({
        method: "delete",
        url: `/transaction/${transactionId}`,
      });

      dispatch(deletePendingTransactionSuccess(data?.message));
      toast.success(data?.message);
      cb();
    } catch (error) {
      handlingErrors(error, dispatch, deletePendingTransactionFailure);
    }
  };

export const batchTransactionsFn =
  ({ page, category, type, startDate, endDate }) =>
  async (dispatch) => {
    if (page === 1) {
      dispatch(resetBatchTransactions());
    }

    const params: any = {};

    if (page) {
      params.page = page;
    }
    if (category) {
      params.category = category;
    }
    if (type) {
      params.type = type;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    try {
      const { data } = await request({
        method: "get",
        url: "/transaction/batch",
        params,
      });

      dispatch(
        batchTransactionsSuccess({
          data: data?.data,
          meta: data?.metadata,
        })
      );
    } catch (error) {
      if (error?.response) {
        dispatch(
          batchTransactionsFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(batchTransactionsFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getSingleBatchTransactionFn =
  (reference: string, cb?: () => void) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(resetSingleBatchTransaction());

      const { data } = await request({
        method: "get",
        url: `/transaction/batch/${reference}`,
      });

      dispatch(singleBatchTransactionSuccess(data?.data));
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        singleBatchTransactionFailure(error?.response?.data || error?.response)
      );
    }
  };

export const exportApprovedTransactionsFn =
  ({
    page,
    type,
    status,
    mode,
  }: {
    page?: number;
    type?: string;
    status?: string;
    mode?: string;
  }) =>
  async (dispatch) => {
    dispatch(resetApproveTransaction());

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/merchant/transactions/export`;
      let params = "";

      if (page) {
        params = params + `page=${page}`;
      }
      if (params?.length && type) {
        params = params + `&type=${type}`;
      }
      if (!params?.length && type) {
        params = params + `type=${type}`;
      }
      if (params?.length && status) {
        params = params + `&status=${status}`;
      }
      if (!params?.length && status) {
        params = params + `status=${status}`;
      }
      if (params?.length && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params?.length && mode) {
        params = params + `mode=${mode}`;
      }

      if (params?.length) {
        URLToPDF = URLToPDF + `?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "transactions.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        dispatch(exportApprovedTransactionsSuccess("Done"));
      };
      httpClient.send();
    } catch (error) {
      dispatch(
        exportApprovedTransactionsFailure(
          error?.response?.data || error?.response
        )
      );
    }
  };

export const exportPendingTransactionsFn =
  ({
    page,
    perPage,
    search,
    category,
    type,
    mode,
  }: {
    page?: number;
    perPage?: number;
    type?: string;
    search?: string;
    category?: string;
    mode?: string;
  }) =>
  async (dispatch) => {
    dispatch(resetExportPendingTransactions());

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/transaction/pending/export`;
      let params = "";

      if (page) {
        params = params + `page=${page}`;
      }
      if (params && perPage) {
        params = params + `&perPage=${perPage}`;
      }
      if (!params && perPage) {
        params = params + `perPage=${perPage}`;
      }
      if (params?.length && type) {
        params = params + `&type=${type}`;
      }
      if (!params?.length && type) {
        params = params + `type=${type}`;
      }
      if (params?.length && search) {
        params = params + `&search=${search}`;
      }
      if (!params?.length && search) {
        params = params + `search=${search}`;
      }
      if (params && category) {
        params = params + `&category=${category}`;
      }
      if (!params && category) {
        params = params + `category=${category}`;
      }
      if (params && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params && mode) {
        params = params + `mode=${mode}`;
      }

      if (params?.length) {
        URLToPDF = URLToPDF + `?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "transactions.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        dispatch(exportPendingTransactionsSuccess("Done"));
      };
      httpClient.send();
    } catch (error) {
      dispatch(
        exportPendingTransactionsFailure(
          error?.response?.data || error?.response
        )
      );
    }
  };

export const exportBatchTransactionsFn =
  ({
    page,
    perPage,
    search,
    category,
    type,
    mode,
  }: {
    page?: number;
    perPage?: number;
    type?: string;
    search?: string;
    category?: string;
    mode?: string;
  }) =>
  async (dispatch) => {
    dispatch(resetBatchTransactions());

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/transaction/batch/export`;
      let params = "";

      if (page) {
        params = params + `page=${page}`;
      }
      if (params && perPage) {
        params = params + `&perPage=${perPage}`;
      }
      if (!params && perPage) {
        params = params + `perPage=${perPage}`;
      }
      if (params?.length && type) {
        params = params + `&type=${type}`;
      }
      if (!params?.length && type) {
        params = params + `type=${type}`;
      }
      if (params?.length && search) {
        params = params + `&search=${search}`;
      }
      if (!params?.length && search) {
        params = params + `search=${search}`;
      }
      if (params && category) {
        params = params + `&category=${category}`;
      }
      if (!params && category) {
        params = params + `category=${category}`;
      }
      if (params && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params && mode) {
        params = params + `mode=${mode}`;
      }

      if (params?.length) {
        URLToPDF = URLToPDF + `?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "transactions.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        dispatch(exportBatchTransactionsSuccess("Done"));
      };
      httpClient.send();
    } catch (error) {
      dispatch(
        exportBatchTransactionsFailure(error?.response?.data || error?.response)
      );
    }
  };

export const selectTransactionAccessModeState = (state) =>
  state.transactions.accessMode || "PRODUCTION";
export const selectTransactionsState = (state: any) => state.transactions;
export const selectSingleTransactionState = (state) =>
  state.transactions.singleTransaction;
export const selectPendingTransactionsState = (state) =>
  state.transactions.pendingTransactions;
export const selectTransactionHistoryState = (state) =>
  state.transactions.transactionsHistory;
export const selectCustomerTransactionsState = (state) =>
  state.transactions.customerTransactions;
export const selectBatchTransactionsState = (state) =>
  state.transactions.batchTransactions;
export const selectSingleBatchTransactionState = (state) =>
  state.transactions.singleBatchTransaction;
export const selectCustomerTransactionHistoryState = (state) =>
  state.transactions.customerTransactionsHistory;
export const selectExportApprovedTransactionsState = (state) =>
  state.transactions.exportApprovedTransactions;
export const selectExportPendingTransactionsState = (state) =>
  state.transactions.exportPendingTransactions;
export const selectExportBatchTransactionsState = (state) =>
  state.transactions.exportBatchTransactions;

export default transactionSlice.reducer;
