import React from "react";
import { object, string, number } from "yup";
import { Field, Form, Formik } from "formik";
import { PrimaryButton } from "components/Button/Button";
const { BUSINESS_CATEGORY } = require("constants/business");

export interface CreateMerchantValuesTypes {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phoneNumber: string;
    bvn: string;
    businessName: string;
    businessType: string;
    charges: Record<string, any>;
}

export interface CreateMerchantSectionProps {
    loading: boolean;
    CreateMerchantFn: (values: CreateMerchantValuesTypes) => void;
}

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}: {
    [x: string]: any;
    field: any;
    form: any;
}) => {
    const inputClassName = `form-control form-control-lg ${
        touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;

    return (
        <div className="form-group">
            <input className={inputClassName} {...field} {...props} />
            {touched[field.name] && errors[field.name] && <div className="invalid-feedback">{errors[field.name]}</div>}
        </div>
    );
};

const CreateMerchantSection: React.FC<CreateMerchantSectionProps> = ({ loading, CreateMerchantFn }) => {
    return (
        <div>
            <h2 className="mb-5">Create Primary Merchant</h2>
            <Formik
                validationSchema={CreateMerchantSchema}
                onSubmit={(values, actions) => {
                    CreateMerchantFn(values);
                    actions.resetForm();
                }}
                initialValues={{
                    bvn: "",
                    email: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    businessName: "",
                    businessType: "",
                    charges: {},
                }}
            >
                {() => (
                    <Form>
                        <Field name="firstName" placeholder="First Name" type="text" component={TextInput} />
                        <Field name="lastName" placeholder="Last Name" type="text" component={TextInput} />

                        <Field name="email" placeholder="Email" type="email" component={TextInput} />
                        <Field name="password" placeholder="Password" type="password" component={TextInput} />
                        <Field name="phoneNumber" placeholder="Phone" type="tel" component={TextInput} />

                        <Field name="bvn" placeholder="BVN" type="text" component={TextInput} />
                        <Field name="businessName" placeholder="Business Name*" type="text" component={TextInput} />

                        <div className="form-group">
                            <Field
                                component="select"
                                name="businessType"
                                placeholder="Business type"
                                className="form-control form-control-lg"
                            >
                                <option value="" disabled hidden>
                                    Select Business Type
                                </option>
                                {BUSINESS_CATEGORY.map((type) => (
                                    <option key={type}>{type}</option>
                                ))}
                            </Field>
                        </div>

                        {loading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <PrimaryButton type="submit" block title="Create Merchant" />
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateMerchantSection;

const CreateMerchantSchema = object().shape({
    bvn: string().required("Bvn is required.").length(11),
    email: string().email().lowercase().required("Email is required"),
    businessName: string().trim().required("Business name is required."),
    businessType: string().trim().required("Business type is required."),
    password: string().label("Password").required("Password is required").min(6),
    lastName: string().min(3, "Too Short!").max(70, "Too Long!").required("Required"),
    firstName: string().min(3, "Too Short!").max(70, "Too Long!").required("Required"),
    phoneNumber: string().min(3, "Too Short!").max(70, "Too Long!").required("Required"),
    charges: object().shape({
        bvnChargeV1: number(),
        fundingRate: number(),
        fundingRateMax: number(),
        tier_1_daily_limit: number(),
        tier_2_daily_limit: number(),
        tier_3_daily_limit: number(),
        bvnVerificationCharge: number(),
        walletToWalletTransfer: number(),
        walletReservationCharge: number(),
        transferCharges: object()
            .shape({
                max5000: number(),
                max50000: number(),
                min50000: number(),
            })
            .notRequired(),
    }),
});
