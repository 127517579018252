import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";

import styles from "./Load.module.css";

import BankLayout from "layouts/Bank/Bank";
import { PrimaryButton } from "components/Button/Button";
import TextInput from "components/TextInput/TextInput";
import { selectBankAccessModeState, selectMerchant, selectCashLoad, getMerchantFn, cashLoadFn } from "slices/bankSlice";

const CashLoanScreen: React.FC = () => {
    const dispatch = useDispatch();

    const modeState = useSelector(selectBankAccessModeState);
    const merchantState = useSelector(selectMerchant);
    const cashLoadState = useSelector(selectCashLoad);

    useEffect(() => {
        dispatch(getMerchantFn({ perPage: 1000 }));
    }, [dispatch]);
    return (
        <BankLayout>
            <Formik
                initialValues={{
                    merchant: "",
                    amount: "",
                    description: "",
                }}
                validationSchema={WalletSchema}
                onSubmit={(values, actions) => {
                    dispatch(
                        cashLoadFn({
                            merchantId: values.merchant,
                            mode: modeState,
                            amount: Number(values.amount),
                            description: values.description,
                            cb: () => actions.resetForm(),
                        })
                    );
                }}
            >
                {({ errors, touched }) => (
                    <div className={styles.viaWallet}>
                        <h4 className={styles.viaWallet__title}>Cash Load</h4>
                        <Form className={styles.cash_load_form_container}>
                            <div className={styles.cash_load_container}>
                                <div className="form-group">
                                    <Field
                                        name="merchant"
                                        type="string"
                                        component="select"
                                        className={`form-control form-control-lg ${
                                            errors.merchant && touched.merchant ? "is-invalid" : ""
                                        }`}
                                    >
                                        <option value="">-- Select Merchant --</option>
                                        {merchantState?.data?.map((merchant) => (
                                            <option value={merchant?.id} key={merchant?.id}>
                                                {merchant?.businessName}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="merchant" component="div" className="invalid-feedback" />
                                </div>

                                <Field name="amount" placeholder="Amount" type="text" component={TextInput} />
                                <Field name="description" placeholder="Description" type="text" component={TextInput} />

                                {cashLoadState.loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <PrimaryButton type="submit" title="Credit Merchant" size="lg" block />
                                )}
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </BankLayout>
    );
};

export default CashLoanScreen;

const WalletSchema = object().shape({
    merchant: string().required("Required"),
    amount: string().required("Required"),
    description: string(),
});
