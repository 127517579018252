import React from 'react';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import { useDispatch, useSelector } from "react-redux";

import { PrimaryButton } from "components/Button/Button";
import {selectCreatePartner, createPartnerFn} from 'slices/bankSlice';

import styles from './CreatePartner.module.css';

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
};

const validationSchema = object().shape({
    firstName: string().required('First name required'),
    lastName: string().required('Last name'),
    email: string().email('Email is invalid').required('Email is required'),
    password: string().required('Password is required')
});

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}: {
    [x: string]: any;
    field: any;
    form: any;
}) => {
    const inputClassName = `form-control form-control-lg ${
        touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;

    return (
        <div className="form-group">
            <input className={inputClassName} {...field} {...props} />
            {touched[field.name] && errors[field.name] && <div className="invalid-feedback">{errors[field.name]}</div>}
        </div>
    );
};

const CreatePartner = ({closeModal}) => {
    const createPartnerState = useSelector(selectCreatePartner);
    const dispatch = useDispatch();


    return <div className={styles.container}>
        <h2 className="mb-5">Create Partner</h2>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {resetForm}) => {
                dispatch(createPartnerFn(values, () => {
                    resetForm();
                    closeModal();
                }));
                
            }}
        >
            {() => (
                <Form className={styles.formContainer}>
                    <Field name="firstName" placeholder="First Name" type="text" component={TextInput} />
                    <Field name="lastName" placeholder="Last Name" type="text" component={TextInput} />

                    <Field name="email" placeholder="Email" type="email" component={TextInput} />
                    <Field name="password" placeholder="Password" type="password" component={TextInput} />
                    {createPartnerState.loading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <PrimaryButton type="submit" block title="Create Partner" />
                        )}
                </Form>
            )}
        </Formik>
    </div>
}

export default CreatePartner;