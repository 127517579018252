import { createSlice, Dispatch } from "@reduxjs/toolkit";
import request from "constants/requests";
import { toast } from "react-toastify";
import config from "../constants/api";

interface CustomerLookup {
  page?: number;
  search?: string;
  searchBy?: string;
  cb?: () => void;
}

const initialState = {
  accessMode:
    localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION"
      ? "PRODUCTION"
      : "SANDBOX",
  customers: {
    loading: true,
    data: [],
    error: {},
    meta: {},
  },
  subMerchant: {
    data: [],
    info: {},
    error: null,
    loading: true,
    meta: { page: 1 },
  },
  createSubMerchant: {
    loading: false,
    error: null,
    data: null,
  },
  customerData: {
    loading: false,
    data: {},
    error: {},
  },
  creditWallet: {
    loading: false,
    data: {},
    error: {},
  },
  debitWallet: {
    loading: false,
    data: {},
    error: {},
  },
  freezeWallet: {
    loading: false,
    data: {},
    error: {},
  },
  unfreezeWallet: {
    loading: false,
    data: {},
    error: {},
  },
  allCustomers: {
    loading: false,
    data: null,
    error: null,
  },
  exportCustomers: {
    loading: false,
    data: null,
    error: null,
  },
  updateCustomerBVN: {
    loading: false,
    data: null,
    error: null,
  },
  updateCustomerNIN: {
    loading: false,
    data: null,
    error: null,
  },
  upgradeCustomerTier: {
    loading: false,
    data: null,
    error: null,
  },
};

const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    getCustomers: (state) => {
      let { customers } = state;
      customers.loading = true;
      // customers.data = [];
      customers.error = {};
    },
    getCustomersSuccess: (state, { payload }) => {
      let { customers } = state;
      customers.loading = false;
      customers.data = payload.data;
      customers.meta = payload.meta;
      customers.error = {};
    },
    getCustomersFailed: (state, { payload }) => {
      let { customers } = state;
      customers.loading = false;
      customers.data = [];
      customers.error = payload;
    },
    getSingleCustomer: (state) => {
      let { customerData } = state;
      customerData.loading = true;
      customerData.data = {};
      customerData.error = {};
    },
    getSingleCustomerSuccess: (state, { payload }) => {
      let { customerData } = state;
      customerData.loading = false;
      customerData.data = payload;
      customerData.error = {};
    },
    getSingleCustomerFailed: (state, { payload }) => {
      let { customerData } = state;
      customerData.loading = false;
      customerData.data = {};
      customerData.error = payload;
    },
    fetchSubMerchantStarted: (state) => {
      const { subMerchant } = state;

      subMerchant.loading = true;
      subMerchant.data = [];
      subMerchant.info = {};
      subMerchant.error = null;
      subMerchant.meta = { page: 1 };
    },
    fetchSubMerchantSuccess: (state, { payload }) => {
      const { subMerchant } = state;

      subMerchant.error = null;
      subMerchant.loading = false;
      subMerchant.data = payload.data;
      subMerchant.info = payload.info;
      subMerchant.meta = payload.metadata;
    },
    fetchSubMerchantFailed: (state, { payload }) => {
      const { subMerchant } = state;

      subMerchant.loading = false;
      subMerchant.info = {};
      subMerchant.data = [];
      subMerchant.error = payload;
    },
    creditWallet: (state) => {
      let { creditWallet } = state;
      creditWallet.loading = true;
      creditWallet.data = {};
      creditWallet.error = {};
    },
    creditWalletSuccess: (state, { payload }) => {
      let { creditWallet } = state;
      creditWallet.loading = false;
      creditWallet.data = payload;
      creditWallet.error = {};
    },
    creditWalletFailed: (state, { payload }) => {
      let { creditWallet } = state;
      creditWallet.loading = false;
      creditWallet.data = {};
      creditWallet.error = payload;
    },
    debitWallet: (state) => {
      let { debitWallet } = state;
      debitWallet.loading = true;
      debitWallet.data = {};
      debitWallet.error = {};
    },
    debitWalletSuccess: (state, { payload }) => {
      let { debitWallet } = state;
      debitWallet.loading = false;
      debitWallet.data = payload;
      debitWallet.error = {};
    },
    debitWalletFailed: (state, { payload }) => {
      let { debitWallet } = state;
      debitWallet.loading = false;
      debitWallet.data = {};
      debitWallet.error = payload;
    },
    freezeWallet: (state) => {
      let { freezeWallet } = state;
      freezeWallet.loading = true;
      freezeWallet.data = {};
      freezeWallet.error = {};
    },
    freezeWalletSuccess: (state, { payload }) => {
      let { freezeWallet } = state;
      freezeWallet.loading = false;
      freezeWallet.data = payload;
      freezeWallet.error = {};
    },
    freezeWalletFailed: (state, { payload }) => {
      let { freezeWallet } = state;
      freezeWallet.loading = false;
      freezeWallet.data = {};
      freezeWallet.error = payload;
    },
    unfreezeWallet: (state) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = true;
      unfreezeWallet.data = {};
      unfreezeWallet.error = {};
    },
    unfreezeWalletSuccess: (state, { payload }) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = false;
      unfreezeWallet.data = payload;
      unfreezeWallet.error = {};
    },
    unfreezeWalletFailed: (state, { payload }) => {
      let { unfreezeWallet } = state;
      unfreezeWallet.loading = false;
      unfreezeWallet.data = {};
      unfreezeWallet.error = payload;
    },
    startCreatingMerchant: (state) => {
      const { createSubMerchant } = state;

      createSubMerchant.loading = true;
      createSubMerchant.error = null;
      createSubMerchant.data = null;
    },
    merchantCreatedSuccess: (state, { payload }) => {
      const { createSubMerchant } = state;

      createSubMerchant.loading = false;
      createSubMerchant.error = null;
      createSubMerchant.data = payload;
    },
    merchantCreatedFailed: (state, { payload }) => {
      const { createSubMerchant } = state;

      createSubMerchant.loading = false;
      createSubMerchant.error = payload;
      createSubMerchant.data = null;
    },
    resetAllCustomers: (state) => {
      const { allCustomers } = state;

      allCustomers.loading = true;
      allCustomers.data = null;
      allCustomers.error = null;
    },
    allCustomersSuccess: (state, { payload }) => {
      const { allCustomers } = state;

      allCustomers.loading = false;
      allCustomers.data = payload;
      allCustomers.error = null;
    },
    allCustomersFailure: (state, { payload }) => {
      const { allCustomers } = state;

      allCustomers.loading = false;
      allCustomers.data = null;
      allCustomers.error = payload;
    },
    resetExportCustomers: (state) => {
      const { exportCustomers } = state;

      exportCustomers.loading = true;
      exportCustomers.data = null;
      exportCustomers.error = null;
    },
    exportCustomersSuccess: (state, { payload }) => {
      const { exportCustomers } = state;

      exportCustomers.loading = false;
      exportCustomers.data = payload;
      exportCustomers.error = null;
    },
    exportCustomersFailure: (state, { payload }) => {
      const { exportCustomers } = state;

      exportCustomers.loading = false;
      exportCustomers.data = null;
      exportCustomers.error = payload;
    },
    resetUpdateCustomerBVN: (state) => {
      const { updateCustomerBVN } = state;

      updateCustomerBVN.loading = true;
      updateCustomerBVN.data = null;
      updateCustomerBVN.error = null;
    },
    updateCustomerBVNSuccess: (state, { payload }) => {
      const { updateCustomerBVN } = state;

      updateCustomerBVN.loading = false;
      updateCustomerBVN.data = payload;
      updateCustomerBVN.error = null;
    },
    updateCustomerBVNFailure: (state, { payload }) => {
      const { updateCustomerBVN } = state;

      updateCustomerBVN.loading = false;
      updateCustomerBVN.data = null;
      updateCustomerBVN.error = payload;
    },
    resetUpdateCustomerNIN: (state) => {
      const { updateCustomerNIN } = state;

      updateCustomerNIN.loading = true;
      updateCustomerNIN.data = null;
      updateCustomerNIN.error = null;
    },
    updateCustomerNINSuccess: (state, { payload }) => {
      const { updateCustomerNIN } = state;

      updateCustomerNIN.loading = false;
      updateCustomerNIN.data = payload;
      updateCustomerNIN.error = null;
    },
    updateCustomerNINFailure: (state, { payload }) => {
      const { updateCustomerNIN } = state;

      updateCustomerNIN.loading = false;
      updateCustomerNIN.data = null;
      updateCustomerNIN.error = payload;
    },
    resetUpgradeCustomerTier: (state) => {
      const { upgradeCustomerTier } = state;

      upgradeCustomerTier.loading = true;
      upgradeCustomerTier.data = null;
      upgradeCustomerTier.error = null;
    },
    upgradeCustomerTierSuccess: (state, { payload }) => {
      const { upgradeCustomerTier } = state;

      upgradeCustomerTier.loading = false;
      upgradeCustomerTier.data = payload;
      upgradeCustomerTier.error = null;
    },
    upgradeCustomerTierFailure: (state, { payload }) => {
      const { upgradeCustomerTier } = state;

      upgradeCustomerTier.loading = false;
      upgradeCustomerTier.data = null;
      upgradeCustomerTier.error = payload;
    },
  },
});

export const {
  getCustomers,
  getCustomersSuccess,
  getCustomersFailed,
  getSingleCustomer,
  getSingleCustomerSuccess,
  getSingleCustomerFailed,
  creditWallet,
  creditWalletSuccess,
  creditWalletFailed,
  debitWallet,
  debitWalletSuccess,
  debitWalletFailed,
  freezeWallet,
  freezeWalletSuccess,
  freezeWalletFailed,
  unfreezeWallet,
  unfreezeWalletSuccess,
  unfreezeWalletFailed,
  fetchSubMerchantStarted,
  fetchSubMerchantSuccess,
  fetchSubMerchantFailed,
  startCreatingMerchant,
  merchantCreatedSuccess,
  merchantCreatedFailed,
  resetAllCustomers,
  allCustomersSuccess,
  allCustomersFailure,
  resetExportCustomers,
  exportCustomersSuccess,
  exportCustomersFailure,
  resetUpdateCustomerBVN,
  updateCustomerBVNSuccess,
  updateCustomerBVNFailure,
  resetUpdateCustomerNIN,
  updateCustomerNINSuccess,
  updateCustomerNINFailure,
  resetUpgradeCustomerTier,
  upgradeCustomerTierSuccess,
  upgradeCustomerTierFailure,
} = customerSlice.actions;

export const getCustomersFn =
  ({ page, search, searchBy, cb }: CustomerLookup) =>
  async (dispatch: Dispatch) => {
    if (page === 1) {
      dispatch(getCustomers());
    }

    const params: any = {};

    if (page) {
      params.page = page;
    }
    if (search) {
      params.search = search;
    }
    if (searchBy) {
      params.searchBy = searchBy;
    }

    try {
      const response = await request({
        method: "get",
        url: "/customer",
        params,
      });

      dispatch(
        getCustomersSuccess({
          data: response?.data?.customers,
          meta: response?.data?.metadata,
        })
      );
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(getCustomersFailed(error?.response?.data || error?.response));
    }
  };

export const getSingleCustomerFn =
  (id: string) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(getSingleCustomer());
      const response = await request({
        method: "get",
        url: "/wallet/customer",
        params: {
          customerId: id,
        },
      });
      dispatch(getSingleCustomerSuccess(response?.data?.wallet));
    } catch (error) {
      dispatch(
        getSingleCustomerFailed(error?.response?.data || error?.response)
      );
    }
  };

export const fetchSubMerchantsFn =
  ({ page = 1, name }: { page?: number; name: string }) =>
  async (dispatch: Dispatch) => {
    if (page === 1) {
      dispatch(fetchSubMerchantStarted());
    }

    try {
      const { data } = await request({
        method: "get",
        url: "/merchant/subsidiary",
        params: { page, name },
      });

      if (data && data?.data) {
        dispatch(fetchSubMerchantSuccess(data));
      }
    } catch (error) {
      if (error?.response) {
        dispatch(
          fetchSubMerchantFailed(error?.response?.data || error?.response)
        );
      } else {
        dispatch(fetchSubMerchantFailed(error?.message));
      }
    }
  };

interface WalletUpdateType {
  reference?: string | number;
  amount: string;
  customerId: string;
}

export const creditWalletFn =
  (values: WalletUpdateType, cb?: (id: string) => void) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(creditWallet());
      const response = await request({
        method: "post",
        url: "/wallet/credit",
        data: values,
      });
      dispatch(creditWalletSuccess(response?.data));
      toast.success(response?.data?.message || "Success");
      if (cb) {
        cb(values?.customerId);
      }
    } catch (error) {
      dispatch(creditWalletFailed(error?.response?.data || error?.response));
    }
  };

export const debitWalletFn =
  (values: WalletUpdateType, cb?: (id: string) => void) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(debitWallet());
      const response = await request({
        method: "post",
        url: "/wallet/debit",
        data: values,
      });
      dispatch(debitWalletSuccess(response?.data));
      toast.success(response?.data?.message || "Success");
      if (cb) {
        cb(values?.customerId);
      }
    } catch (error) {
      dispatch(debitWalletFailed(error?.response?.data || error?.response));
    }
  };

export const freezeWalletFn =
  (customerId: string, reason: string, cb: () => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(freezeWallet());

      const response = await request({
        method: "post",
        url: "/wallet/close",
        data: { customerId, reason },
      });

      dispatch(freezeWalletSuccess(response?.data));

      toast.success(response?.data?.message || "Success");

      cb();
    } catch (error) {
      dispatch(freezeWalletFailed(error?.response?.data || error?.response));
    }
  };

export const unfreezeWalletFn =
  (customerId: string, cb: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch(unfreezeWallet());
      const response = await request({
        method: "post",
        url: "/wallet/enable",
        data: {
          customerId,
        },
      });
      dispatch(unfreezeWalletSuccess(response?.data));
      toast.success(response?.data?.message || "Success");
      cb();
    } catch (error) {
      dispatch(unfreezeWalletFailed(error?.response?.data || error?.response));
    }
  };

export const createSubMerchantAccountFn =
  (merchantInfo, cb) => async (dispatch) => {
    dispatch(startCreatingMerchant());
    try {
      const { data } = await request({
        method: "post",
        url: "/merchant/subsidiary",
        data: merchantInfo,
      });

      if (data && data?.status) {
        dispatch(merchantCreatedSuccess(data?.data));
        toast.success("Merchant successfully created.");

        cb();
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error?.response) {
        dispatch(
          merchantCreatedFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(merchantCreatedFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getAllCustomerFn = () => async (dispatch) => {
  dispatch(resetAllCustomers());

  try {
    const { data } = await request({
      method: "get",
      url: "/customer",
    });

    dispatch(allCustomersSuccess(data?.customers));
  } catch (error) {
    dispatch(allCustomersFailure(error?.response?.data || error?.response));
  }
};

export const exportCustomersFn =
  ({ currentPage, mode }: { currentPage?: number; mode?: string }) =>
  async (dispatch) => {
    dispatch(resetExportCustomers());

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/customer/export`;
      let params = "";

      if (currentPage) {
        params = params + `currentPage=${currentPage}`;
      }
      if (params && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params && mode) {
        params = params + `mode=${mode}`;
      }

      if (params?.length) {
        URLToPDF = `${URLToPDF}?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "customers.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        dispatch(exportCustomersSuccess("Done"));
      };
      httpClient.send();
    } catch (error) {
      dispatch(
        exportCustomersFailure(error?.response?.data || error?.response)
      );
    }
  };

export const updateCustomerBVNFn =
  ({
    payload,
    cb,
  }: {
    payload: {
      bvn?: string;
      customerId: string;
    };
    cb?: () => void;
  }) =>
  async (dispatch) => {
    dispatch(resetUpdateCustomerBVN());

    try {
      const { data } = await request({
        method: "patch",
        url: "/customer/bvn",
        data: payload,
      });

      dispatch(updateCustomerBVNSuccess(data?.message));
      if (cb) {
        cb();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      dispatch(
        updateCustomerBVNFailure(
          error?.response?.data?.message || error?.message
        )
      );
    }
  };

export const updateCustomerNINFn =
  ({
    payload,
    cb,
  }: {
    payload: {
      nin?: string;
      customerId: string;
    };
    cb?: () => void;
  }) =>
  async (dispatch) => {
    dispatch(resetUpdateCustomerNIN());

    try {
      const { data } = await request({
        method: "patch",
        url: "/customer/nin",
        data: payload,
      });

      dispatch(updateCustomerNINSuccess(data?.message));
      if (cb) {
        cb();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      dispatch(
        updateCustomerNINFailure(
          error?.response?.data?.message || error?.message
        )
      );
    }
  };

export const upgradeCustomerTierFn =
  ({
    payload,
    cb,
  }: {
    payload: {
      customerId: string;
      bvn?: string;
      nin?: string;
    };
    cb?: () => void;
  }) =>
  async (dispatch) => {
    dispatch(resetUpgradeCustomerTier());

    try {
      const { data } = await request({
        method: "patch",
        url: "/customer/tier",
        data: payload,
      });

      dispatch(upgradeCustomerTierSuccess(data?.message));
      if (cb) {
        cb();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      dispatch(
        upgradeCustomerTierFailure(
          error?.response?.data?.message || error?.message
        )
      );
    }
  };

export const selectCustomerAccessModeState = (state) =>
  state.customers.accessMode || "PRODUCTION";
export const selectCustomerState = (state: any) => state.customers;
export const selectSubMerchantState = (state: any) =>
  state.customers.subMerchant;
export const selectCreateMerchantState = (state: any) =>
  state.customers.createSubMerchant;
export const selectAllCustomersState = (state) => state.customers.allCustomers;
export const selectFreezeCustomer = (state) => state.customers.freezeWallet;
export const selectUnfreezeCustomer = (state) => state.customers.unfreezeWallet;
export const selectExportCustomers = (state) => state.customers.exportCustomers;
export const selectUpdateCustomerBVN = (state) =>
  state.customers.updateCustomerBVN;
export const selectUpdateCustomerNIN = (state) =>
  state.customers.updateCustomerNIN;
export const selectUpgradeCustomerTier = (state) =>
  state.customers.upgradeCustomerTier;

export default customerSlice.reducer;
