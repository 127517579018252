import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AuthLayout from "layouts/Auth/Auth";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { selectAuthState, changePasswordFn } from "slices/authSlice";

const ChangePasswordScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const authState = useSelector(selectAuthState);

    const { loading: changePasswordLoading } = authState.changePassword;

    return (
        <AuthLayout title="Change Password">
            <Formik
                validationSchema={ChangePasswordSchema}
                initialValues={{ resetCode: "", password: "" }}
                onSubmit={(values) => {
                    dispatch(changePasswordFn(values, () => history.push("/")));
                }}
            >
                {() => (
                    <Form>
                        <Field name="resetCode" placeholder="Reset Code" type="text" component={TextInput} />
                        <Field
                            name="password"
                            type="password"
                            component={TextInput}
                            placeholder="New Password"
                            autoComplete="new-password"
                        />
                        {changePasswordLoading ? (
                            <Loading />
                        ) : (
                            <PrimaryButton type="submit" block size="lg" title="Reset Password" />
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default ChangePasswordScreen;

const ChangePasswordSchema = Yup.object().shape({
    resetCode: Yup.string().required("Required"),
    password: Yup.string().min(6, "Too Short!").max(70, "Too Long!").required("Required"),
});
