import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "slices/authSlice";
import walletReducer from "slices/walletSlice";
import customersReducer from "slices/customersSlice";
import TransactionsReducer from "slices/transactionsSlice";
import SettingsReducer from "slices/settingsSlice";
import SendMoneyReducer from "slices/sendMoneySlice";
import configReducer from "slices/configSlice";
import partnerReducer from "slices/partnerSlice";
import bankReducer from "slices/bankSlice";
import merchantReducer from "slices/merchantSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    customers: customersReducer,
    settings: SettingsReducer,
    transactions: TransactionsReducer,
    wallet: walletReducer,
    sendMoney: SendMoneyReducer,
    config: configReducer,
    partner: partnerReducer,
    bank: bankReducer,
    merchant: merchantReducer,
});

export default rootReducer;
