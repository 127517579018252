import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import request from "constants/requests";
import { useSelector } from "react-redux";

import styles from "./CashLoad.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import BankLayout from "layouts/Bank/Bank";
import { PrimaryButton } from "components/Button/Button";
import routes from "routing/routes";
import { selectBankAccessModeState } from "slices/bankSlice";

const CashLoadScreen: React.FC = () => {
    const mode = useSelector(selectBankAccessModeState);

    const [cashLoad, setCashLoad] = useState([]);
    const [loading, setLoading] = useState(true);
    const [meta, setMeta] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(meta?.page || 1);
    const [balance, setBalance] = useState("");

    const fetchCashLoad = async () => {
        setLoading(true);

        try {
            const { data } = await request({
                method: "get",
                url: "/admin/cash-loading",
                params: { page: currentPage, mode, loadingType: "LOAD" },
            });
            setCashLoad(data?.data);
            setMeta(data?.metadata);
            setBalance(data?.balance);
        } catch (err) {
            console.log(err?.response?.data?.message || err?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCashLoad();
    }, [currentPage]);

    const renderRows = () => {
        const rows = cashLoad?.map((each: any) => {
            return (
                <tr key={each?.id}>
                    <td>&#8358;{numberWithCommas(each?.amount)}</td>
                    <td>{each?.description}</td>
                    <td>{format(parseISO(each?.createdAt), "MMM dd, yyyy")}</td>
                    <td>{each?.status}</td>
                    <td>{each?.status == "success" ? "" : each?.reason}</td>
                    <td>{each?.reference}</td>
                </tr>
            );
        });

        return rows;
    };

    const handlePageClick = (data: { selected: number }) => {
        let selected = data.selected;
        const currentPage = selected + 1;
        setCurrentPage(currentPage);
    };

    return (
        <BankLayout>
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.customers}>
                    <div className="mb-4 d-flex justify-content-between">
                        <Header>Cash Load</Header>
                        <div>
                            <p className={styles.customers__totalText}>Cash Balance</p>
                            <h4 className={styles.customers__totalAmount}>{balance || 0}</h4>
                        </div>
                    </div>

                    <div className={styles.customers__content}>
                        <div className={styles.load__cash}>
                            <PrimaryButton title="Load Cash" link={`${routes.BANK_CASH_LOAD}/load`} type="button" />
                        </div>
                        <Table
                            noData={cashLoad?.length === 0 ? true : false}
                            headers={["AMOUNT", "DESCRIPTION", "DATE", "STATUS", "COMMENT", "REFERENCE"]}
                        >
                            {renderRows()}
                        </Table>

                        {meta?.totalPages > 1 && (
                            <nav aria-label="pagination">
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    pageCount={meta?.totalPages}
                                />
                            </nav>
                        )}
                    </div>
                </div>
            )}
        </BankLayout>
    );
};

export default CashLoadScreen;
