import React from "react";
import { Field, Form, Formik } from "formik";

import { PrimaryButton } from "components/Button/Button";
import styles from "./FilterCards.module.css";

export interface UploadType {
  closeModal: () => void;
  handleFilter: (x: any) => void;
}

export const FilterBatchCards: React.FC<UploadType> = ({
  closeModal,
  handleFilter,
}) => {
  const handleSubmit = async (values) => {
    handleFilter(values);
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <h2 className="mb-5">Filter Batch Card</h2>
      <Formik
        initialValues={{
          order: "",
          status: "",
        }}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form className={styles.content__container}>
              <div className="form-group">
                <Field
                  name="order"
                  component="select"
                  placeholder="Order"
                  className={`form-control form-control-lg`}
                >
                  <option value="">Select Order</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </Field>
              </div>
              <div className="form-group">
                <Field
                  name="status"
                  component="select"
                  placeholder="Status"
                  className={`form-control form-control-lg`}
                >
                  <option value="">Select Status</option>
                  <option value="PROCESSED">Processed</option>
                  <option value="REQUESTED">Requested</option>
                  <option value="DECLINED">Declined</option>
                </Field>
              </div>

              <div style={{ marginTop: "20px" }}>
                <PrimaryButton
                  type="submit"
                  block
                  size="lg"
                  title="Apply Filter"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
