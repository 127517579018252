import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import request from "constants/requests";
import { toast } from "react-toastify";

import styles from "./Subsidiaries.module.css";
import AddWalletImg from "assets/images/addWallet.svg";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import BankLayout from "layouts/Bank/Bank";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateSubMerchantSection from "./components/CreateSubsidiary/CreateSubsidiary";

const SubsidiaryScreen: React.FC = () => {
    const history = useHistory();

    const [subMerchants, setSubMerchants] = useState([]);
    const [subsidiaryLoading, setSubsidiaryLoading] = useState(true);
    const [subsidiaryMeta, setSubsidiaryMeta] = useState<any>({});
    const [sideContent, setSideContent] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(subsidiaryMeta?.page || 1);
    const [submitting, setSubmitting] = useState(false);

    const fetchSubMerchants = async () => {
        setSubsidiaryLoading(true);

        try {
            const { data } = await request({
                method: "get",
                url: "/merchant/subsidiary",
            });
            setSubMerchants(data?.data);
            setSubsidiaryMeta(data?.metadata);
        } catch (err) {
            console.log(err?.response?.data?.message || err?.message);
        } finally {
            setSubsidiaryLoading(false);
        }
    };

    useEffect(() => {
        fetchSubMerchants();
    }, [currentPage]);

    const createSubsidiaryMerchant = async (content, cb) => {
        setSubmitting(true);
        try {
            await request({
                method: "post",
                url: "/admin/subsidiary-merchant",
                data: content,
            });
            toast.success("Agent created successfully");
            cb();
        } catch (err) {
            console.log(err?.response?.data?.message || err?.message);
        } finally {
            setSubmitting(false);
        }
    };

    const statusFn = (status) => {
        if (status === "IN-REVIEW") {
            return "In Review";
        } else if (status === "PENDING") {
            return "Pending";
        } else if (status === "DISABLED") {
            return "Disabled";
        } else {
            return "Active";
        }
    };

    const renderRows = () => {
        const rows = subMerchants?.map((each: any) => {
            return (
                <tr key={each?.id} style={{ cursor: "pointer" }} onClick={() => history.push(`/bank/merchants/${each?.id}`)}>
                    <td>{each?.businessName}</td>
                    <td>{each?.phoneNumber}</td>
                    <td>&#8358;{numberWithCommas(each?.availableBalance)}</td>
                    <td>{statusFn(each?.review)}</td>
                    <td>{format(parseISO(each?.createdAt), "MMM dd, yyyy")}</td>
                </tr>
            );
        });

        return rows;
    };

    const handlePageClick = (data: { selected: number }) => {
        let selected = data.selected;
        const currentPage = selected + 1;
        setCurrentPage(currentPage);
    };

    const renderSideContent = () => {
        if (sideContent === "createSubMerchant") {
            return (
                <CreateSubMerchantSection
                    loading={submitting}
                    onSubmit={(values, { resetForm }) => {
                        createSubsidiaryMerchant(values, () => {
                            fetchSubMerchants();
                            closeModal();
                            resetForm();
                        });
                    }}
                />
            );
        } else {
            return null;
        }
    };

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>

            <BankLayout>
                {subsidiaryLoading ? (
                    <Loading />
                ) : (
                    <div className={styles.customers}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>
                                Agents
                                <>
                                    <img
                                        alt="Customer"
                                        className="ml-2"
                                        src={AddWalletImg}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => openModal("createSubMerchant")}
                                        data-tip="Create Agent"
                                        data-for="createSubsidiary"
                                    />
                                    <ReactTooltip id="createSubsidiary" place="right" />
                                </>
                            </Header>
                            <div>
                                <p className={styles.customers__totalText}>Total numbers of agents</p>
                                <h4 className={styles.customers__totalAmount}>{subsidiaryMeta?.totalRecords || 0}</h4>
                            </div>
                        </div>

                        <div className={styles.customers__content}>
                            {/* <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchMerchants();
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
                <PrimaryButton title="Export Agent List" type="button" />
              </form> */}
                            <Table
                                noData={subMerchants?.length === 0 ? true : false}
                                headers={["MERCHANT NAME", "PHONE NO", "BALANCE", "STATUS", "DATE"]}
                            >
                                {renderRows()}
                            </Table>

                            {subsidiaryMeta?.totalPages > 1 && (
                                <nav aria-label="pagination">
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        pageCount={subsidiaryMeta?.totalPages}
                                    />
                                </nav>
                            )}
                        </div>
                    </div>
                )}
            </BankLayout>
        </>
    );
};

export default SubsidiaryScreen;
