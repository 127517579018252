import React from "react";
import BankDetails from "components/BankDetails/BankDetails";
import { PrimaryButton } from "components/Button/Button";
import ProvidusAvatar from "assets/images/tagpay-avatar.png";

import styles from "./CustomerDetail.module.css";

import { numberWithCommas } from "utils/helpers";

const CustomerDetail = ({ data, onProceed, onCancel }) => {
    const statusFn = (status) => {
        if (status === "IN-REVIEW") {
            return "In Review";
        } else if (status === "PENDING") {
            return "Pending";
        } else if (status === "DISABLED") {
            return "Disabled";
        } else {
            return "Active";
        }
    };

    return (
        <div>
            <div className="d-flex flex-row align-items-center mb-3">
                <img src={ProvidusAvatar} alt="Tagpay Avatar" className="rounded-circle" />
                <div className="ml-2">
                    <p className={styles.customerDetail__fullName}>{data?.businessName}</p>
                    <p className={styles.customerDetail__email}>{data?.email}</p>
                </div>
            </div>
            <BankDetails accountName={data?.businessName} accountNumber={data?.accountNumber} bankName="Providus" />

            <table className="table table-striped table-borderless mt-4">
                <tbody>
                    <tr>
                        <td>Email</td>
                        <td align="right" style={{ wordBreak: "break-word" }}>
                            {data?.email}
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td align="right">{statusFn(data?.review)}</td>
                    </tr>
                    <tr>
                        <td>Book Balance</td>
                        <td align="right">&#8358;{numberWithCommas(data?.bookedBalance)}</td>
                    </tr>
                    <tr>
                        <td>Available Balance</td>
                        <td align="right">&#8358;{numberWithCommas(data?.availableBalance)}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td align="right">{data?.phoneNumber}</td>
                    </tr>
                </tbody>
            </table>
            {pending && <PrimaryButton onClick={onProceed} block title="Proceed" />}
        </div>
    );
};

export default CustomerDetail;
