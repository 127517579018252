import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";

import Loading from "components/Loading/Loading";
import PartnerLayout from "layouts/Partner/Partner";
import MerchantSection from "./components/Merchant/Merchant";
import MerchantList from "./components/MerchantList/MerchantList";
// import SideContent from "layouts/Partner/components/SideContent/SideContent";
// import ModalBackground from "layouts/Partner/components/ModalBackground/ModalBackground";

import styles from "./SubMerchants.module.css";

import {
    selectPartnerModeState,
    selectActiveMerchantState,
    fetchPartnerSubMerchantsFn,
    selectPartnerSubMerchantsState,
} from "slices/partnerSlice";

const SubMerchantScreen: React.FC = () => {
    const timer: any = useRef();
    const startTrackingSearch = useRef(false);

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    // const [sideContent, setSideContent] = useState("");
    // const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [merchantInfo, setMerchantInfo] = useState(null);

    const partnerMode = useSelector(selectPartnerModeState);
    const activeMerchant = useSelector(selectActiveMerchantState);
    const merchantState = useSelector(selectPartnerSubMerchantsState);

    const [currentPage, setCurrentPage] = useState(merchantState?.meta?.page || 1);

    useEffect(() => {
        dispatch(fetchPartnerSubMerchantsFn({ page: currentPage, mode: partnerMode, activeMerchant, name: "" }));
    }, [dispatch, currentPage, partnerMode, activeMerchant]);

    useEffect(() => {
        if (!startTrackingSearch.current) {
            startTrackingSearch.current = true;
        } else {
            if (timer.current) {
                clearTimeout(timer.current);

                timer.current = setTimeout(() => {
                    dispatch(
                        fetchPartnerSubMerchantsFn({
                            page: currentPage,
                            mode: partnerMode,
                            activeMerchant,
                            name: searchValue,
                        })
                    );
                }, 500);
            } else {
                timer.current = setTimeout(() => {
                    dispatch(
                        fetchPartnerSubMerchantsFn({
                            page: currentPage,
                            mode: partnerMode,
                            activeMerchant,
                            name: searchValue,
                        })
                    );
                }, 500);
            }

            return () => {
                clearTimeout(timer.current);
            };
        }
        // eslint-disable-next-line
    }, [searchValue, dispatch]);

    const handlePageClick = (data) => {
        let selected = data.selected;

        setCurrentPage(selected + 1);
    };

    // const openModal = (content: string) => {
    //     setSideContent(content);
    //     setModalIsOpen(true);
    // };

    // const closeModal = () => {
    //     setModalIsOpen(false);
    //     setSideContent("");
    // };

    // const renderSideContent = () => {
    //     switch (sideContent) {
    //         default:
    //             return null;
    //     }
    // };

    return (
        <>
            {/* <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent> */}
            <PartnerLayout>
                {merchantState.loading ? (
                    <Loading />
                ) : (
                    <>
                        <MerchantSection info={merchantState?.info} total={merchantState?.data?.length} />

                        <div className={styles.tableContainer}>
                            <div className="col-auto my-3">
                                <label className="sr-only" htmlFor="searchCustomer">
                                    Search merchants
                                </label>
                                <div className="input-group mb-2" style={{ backgroundColor: "#87BD40", border: 0 }}>
                                    <div className="input-group-prepend">
                                        <div
                                            style={{
                                                border: 0,
                                                backgroundColor: "transparent",
                                                borderRightColor: "transparent",
                                            }}
                                            className="input-group-text"
                                        >
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        value={searchValue}
                                        id="searchCustomer"
                                        className="form-control"
                                        placeholder="Search by merchant business name"
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        style={{
                                            border: 0,
                                            color: "#000",
                                            backgroundColor: "#87BD40",
                                            borderLeftColor: "transparent",
                                        }}
                                    />
                                </div>
                            </div>
                            <MerchantList
                                data={merchantState?.data}
                            />
                        </div>

                        {merchantState?.meta?.totalPages > 1 ? (
                            <nav aria-label="Page navigation example">
                                <ReactPaginate
                                    nextLabel={"next"}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    previousLabel={"previous"}
                                    activeClassName={"active"}
                                    pageClassName={"page-item"}
                                    nextClassName={"page-item"}
                                    onPageChange={handlePageClick}
                                    nextLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"page-link"}
                                    pageCount={merchantState?.meta?.totalPages}
                                />
                            </nav>
                        ) : null}
                    </>
                )}
            </PartnerLayout>
        </>
    );
};

export default SubMerchantScreen;
