import React from "react";
import {format, parseISO} from 'date-fns';

import Header from "components/Header/Header";
import styles from "./CustomerWallets.module.css";
import Table from "components/Table/Table";
import { numberWithCommas } from "utils/helpers";
// import ReactPaginate from "react-paginate";

interface CustomerWallets {
  data: object[];
  selectedWalletFn: any;
  meta: any;
  pageClickFn: any;
  permissions?: string[]
}

const CustomerWallets: React.FC<CustomerWallets> = ({
  data,
  selectedWalletFn,
  meta,
  pageClickFn,
  permissions
}) => {
  const renderRows = () => {
    const rows = data.map((each: any, index: number) => {
      return (
        <tr
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (permissions?.includes('BROWSE_CUSTOMERS')) {selectedWalletFn(each?.customerId);}
          }}
          key={index}
        >
          <td>{each?.accountName}</td>
          <td>&#8358;{numberWithCommas(each?.availableBalance)}</td>
          <td>{each?.accountNumber}</td>
          <td>{each?.email}</td>
          <td>{format(parseISO(each?.createdAt), 'dd MMMM, yyyy')}</td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <div className={styles.customerWallet}>
      <div className="mb-4">
        <Header>Customer Account</Header>
      </div>
      <div className={styles.customerWallet__content}>
        <Table
          noData={data.length === 0 ? true : false}
          headers={[
            "account name",
            "balance",
            "account number",
            "email",
            "created",
          ]}
        >
          {renderRows()}
        </Table>
        {/* <nav aria-label="Page navigation example">
          <ReactPaginate
            previousLabel={"previous"}
            nextLabel={"next"}
            // breakLabel={"..."}
            // breakClassName={"break-me"}
            pageCount={meta.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={pageClickFn}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item"}
          />
        </nav> */}
      </div>
    </div>
  );
};

export default CustomerWallets;
