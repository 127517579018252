import React from "react";
import routes from "routing/routes";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import config from "constants/api";
import styles from "./Navbar.module.css";
import LogoImg from "assets/images/new-logo.png";
import {
  selectPartnerState,
  setAccessMode,
  switchUserFn,
} from "slices/partnerSlice";

import DownArrow from "assets/images/down-arrow.svg";

const Navbar = () => {
  const dispatch = useDispatch();

  const [showMerchants, setShowMerchants] = React.useState(false);

  const user =
    !localStorage.getItem("user") ||
    localStorage.getItem("user") === "undefined"
      ? ""
      : JSON.parse(localStorage.getItem("user"));
  const { accessMode, activeMerchant } = useSelector(selectPartnerState);

  return (
    <div className={styles.navbar}>
      <div className={`${styles.navbar__content} container`}>
        <div>
          <Link to={routes.PARTNERTRANSACTION}>
            <img className={styles.navbar__logo} src={LogoImg} alt="" />
          </Link>
        </div>
        <div className="custom-control custom-switch">
          <input
            onChange={(e) => {
              dispatch(
                setAccessMode(e.target.checked ? "PRODUCTION" : "SANDBOX")
              );
              localStorage.setItem(
                config.ACCOUNT_MODE,
                e.target.checked ? "PRODUCTION" : "SANDBOX"
              );
            }}
            id="userMode"
            type="checkbox"
            className="custom-control-input"
            checked={accessMode === "PRODUCTION" ? true : false}
          />
          <label className="custom-control-label" htmlFor="userMode">
            {accessMode === "PRODUCTION" ? "Live Mode On" : "Test Mode On"}
          </label>
        </div>
        <div className={styles.navbar__rightContent}>
          <div className={styles.navbar__initials}>
            {user?.lastName.slice(0, 1) || ""}
            {user?.firstName.slice(0, 1) || ""}
          </div>
          <span className={styles.navbar__username}>
            {user.lastName} {user?.firstName}
          </span>
          <div
            className={styles.icon__container}
            onClick={() => setShowMerchants(!showMerchants)}
          >
            <img
              src={DownArrow}
              alt="Down Arrow"
              className={styles.down__arrow}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.merchant__container}
        style={{ display: showMerchants ? "block" : "none" }}
      >
        <p
          className={styles.merchant__text}
          style={{
            fontSize: "100%",
            cursor: "default",
            backgroundColor: "#d8d8d8",
            borderRadius: "5px 5px 0 0",
          }}
        >
          CURRENT MERCHANT
        </p>
        {activeMerchant ? (
          <div>
            <p className={styles.merchant__text}>
              {activeMerchant?.businessName}
            </p>
            <p
              className={styles.remove_merchant__text}
              onClick={() => {
                dispatch(switchUserFn(null));
                setShowMerchants(false);
              }}
            >
              De-select active merchant
            </p>
          </div>
        ) : (
          <p className={styles.merchant__text}>No merchant selected.</p>
        )}
      </div>
    </div>
  );
};

export default Navbar;
