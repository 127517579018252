import { createSlice, Dispatch } from "@reduxjs/toolkit";
import request from "constants/requests";
import { toast } from "react-toastify";

const initialState = {
    getProfile: {
        loading: false,
        data: {},
        error: {},
    },
    getAccessKeys: {
        loading: false,
        data: {},
        error: {},
    },
    updateProfile: {
        loading: false,
        data: {},
        error: {},
    },
};

const settingSlice = createSlice({
    name: "settings",
    initialState: initialState,
    reducers: {
        getProfile: (state) => {
            let { getProfile } = state;
            getProfile.loading = true;
            getProfile.error = {};
        },
        getProfileSuccess: (state, { payload }) => {
            let { getProfile } = state;
            getProfile.loading = false;
            getProfile.data = payload;
            getProfile.error = {};
        },
        getProfileFailed: (state, { payload }) => {
            let { getProfile } = state;
            getProfile.loading = false;
            getProfile.data = {};
            getProfile.error = payload;
        },
        updateProfile: (state) => {
            let { updateProfile } = state;
            updateProfile.loading = true;
            updateProfile.data = {};
            updateProfile.error = {};
        },
        updateProfileSuccess: (state, { payload }) => {
            let { updateProfile } = state;
            updateProfile.loading = false;
            updateProfile.data = payload;
            updateProfile.error = {};
        },
        updateProfileFailed: (state, { payload }) => {
            let { updateProfile } = state;
            updateProfile.loading = false;
            updateProfile.data = {};
            updateProfile.error = payload;
        },
        getAccessKeys: (state) => {
            let { getAccessKeys } = state;
            getAccessKeys.loading = true;
            getAccessKeys.error = {};
        },
        getAccessKeysSuccess: (state, { payload }) => {
            let { getAccessKeys } = state;
            getAccessKeys.loading = false;
            getAccessKeys.data = payload;
            getAccessKeys.error = {};
        },
        getAccessKeysFailed: (state, { payload }) => {
            let { getAccessKeys } = state;
            getAccessKeys.loading = false;
            getAccessKeys.data = {};
            getAccessKeys.error = payload;
        },
    },
});

export const {
    getProfile,
    getProfileSuccess,
    getProfileFailed,
    updateProfile,
    updateProfileSuccess,
    updateProfileFailed,
    getAccessKeys,
    getAccessKeysSuccess,
    getAccessKeysFailed,
} = settingSlice.actions;

export const getProfileFn = () => async (dispatch: Dispatch) => {
    try {
        dispatch(getProfile());

        const response = await request({
            method: "get",
            url: "/merchant/profile",
        });

        dispatch(getProfileSuccess(response?.data?.data));
    } catch (error) {
        dispatch(getProfileFailed(error?.response?.data || error?.response));
    }
};
export const updateProfileFn =
    (values: { firstName?: string; lastName?: string; phoneNumber?: string; callbackURL?: string; sandboxCallbackURL?: string }) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(updateProfile());
            const response = await request({
                url: "/merchant/profile",
                method: "patch",
                data: values,
            });
            dispatch(updateProfileSuccess(response?.data?.data));
            toast.success(response?.data?.message || "Success");
        } catch (error) {
            dispatch(updateProfileFailed(error?.response?.data || error?.response));
        }
    };

export const getAccessKeysFn = () => async (dispatch: Dispatch) => {
    try {
        dispatch(getAccessKeys());

        const response = await request({
            method: "get",
            url: "/merchant/my-access-keys",
        });

        dispatch(getAccessKeysSuccess(response?.data?.data));
    } catch (error) {
        dispatch(getAccessKeysFailed(error?.response?.data || error?.response));
    }
};

export const generateNewKeyFn = () => async (dispatch: Dispatch) => {
    try {
        dispatch(getAccessKeys());

        const response = await request({
            method: "post",
            url: "/merchant/generate-access-keys",
        });

        toast.success("New API KEY successfully generated.");

        dispatch(getAccessKeysSuccess(response?.data?.data));
    } catch (error) {
        dispatch(getAccessKeysFailed(error?.response?.data || error?.response));
    }
};

export const changePasswordFn = (password: string, cb) => async (dispatch: Dispatch) => {
    try {
        const { data } = await request({ method: "put", url: "/user/password", data: { password } });

        toast.success(data?.message);

        if (cb) {
            cb();
        }
    } catch (error) {
        toast.error(error?.response?.message || error?.message);
    }
};

export const selectSettingsState = (state: any) => state.settings;

export default settingSlice.reducer;
