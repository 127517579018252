import * as React from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AuthLayout from "layouts/Auth/Auth";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { forgotPasswordFn, selectAuthState } from "slices/authSlice";

import routes from "routing/routes";

const ForgotPasswordScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const authState = useSelector(selectAuthState);

    const { loading: forgotPasswordLoading } = authState.forgotPassword;

    return (
        <AuthLayout title="Change Password">
            <Formik
                initialValues={{ email: "" }}
                onSubmit={(values) => {
                    dispatch(forgotPasswordFn(values, () => history.push(routes.CHANGE_PASSWORD)));
                }}
            >
                {() => (
                    <Form>
                        <Field name="email" placeholder="Email Address" type="text" component={TextInput} />
                        {forgotPasswordLoading ? (
                            <Loading />
                        ) : (
                            <PrimaryButton type="submit" block size="lg" title="Reset Password" />
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default ForgotPasswordScreen;
