import * as React from "react";
import styles from "./Card.module.css";
import LineWaveImg from "assets/images/lineWave.svg";

interface Props {
	title?: string;
}

const Card: React.FC<React.PropsWithChildren<Props>> = ({ children, title }) => {
	return (
		<div className="container">
			<div className={styles.card}>
				{title ? <h2 className={styles.card__title}>{title}</h2> : null}
				{children}
				<div className={styles.card__waveContainer}>
					{[...Array(5)].map((x, i) => (
						<img key={i} className="mb-4" src={LineWaveImg} alt="Line" />
					))}
				</div>
			</div>
		</div>
	);
};

export default Card;
