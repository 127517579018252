import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Wallet from "./components/Wallet/Wallet";
import Loading from "components/Loading/Loading";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import WalletWithdraw from "./components/Withdraw/Withdraw";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import CustomerWallets from "./components/CustomerWallets/CustomerWallets";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import {
  selectCustomerState,
  getSingleCustomerFn,
} from "slices/customersSlice";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateWalletSection, {
  CreateWalletValuesTypes,
} from "./components/CreateWallet/CreateWallet";
import {
  getWalletsFn,
  selectWalletState,
  getWalletBalanceFn,
  createWalletFn,
} from "slices/walletSlice";
import { selectProfile } from "slices/merchantSlice";

const HomeScreen: React.FC = () => {
  const dispatch = useDispatch();

  const walletState = useSelector(selectWalletState);

  const { data: walletBalanceData, loading: balanceLoading } =
    walletState.balance;
  const {
    data: walletsData,
    meta: walletsMeta,
    loading: walletsLoading,
  } = walletState.wallets;

  const createWalletLoading = walletState.createWallet.loading;
  const customersState = useSelector(selectCustomerState);
  const { customerData: customer } = customersState;
  const { permissions: profilePermissions } = useSelector(selectProfile);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const fetchData = () => {
    if (profilePermissions?.includes("BROWSE_MERCHANT_WALLET")) {
      dispatch(getWalletBalanceFn());
    }
    if (profilePermissions?.includes("BROWSE_CUSTOMERS")) {
      dispatch(getWalletsFn({ page: currentPage }));
    }
  };

  useEffect(fetchData, [profilePermissions]);

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "createWallet") {
      return (
        <CreateWalletSection
          loading={createWalletLoading}
          error={walletState.createWallet.error}
          createWalletFn={createWallet}
        />
      );
    } else if (sideContent === "walletWithdraw") {
      return <WalletWithdraw />;
    } else if (sideContent === "customerDetail") {
      return (
        <CustomerDetail
          customer={customer}
          permissions={profilePermissions}
          closeModalFn={closeModal}
        />
      );
    }
    return null;
  };

  const getCustomer = (id: string) => {
    openModal("customerDetail");
    dispatch(getSingleCustomerFn(id));
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const createWallet = (values: CreateWalletValuesTypes) => {
    dispatch(
      createWalletFn(values, () => {
        dispatch(getWalletsFn({ page: currentPage }));
        closeModal();
      })
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const { id } = walletBalanceData;
  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {(!id && balanceLoading) || (!id && walletsLoading) ? (
          <Loading />
        ) : (
          <>
            <Wallet
              openSideContentFn={openModal}
              data={walletBalanceData}
              permissions={profilePermissions}
            />
            {!!profilePermissions?.includes("BROWSE_CUSTOMERS") && (
              <CustomerWallets
                meta={walletsMeta}
                data={walletsData}
                permissions={profilePermissions}
                selectedWalletFn={getCustomer}
                pageClickFn={handlePageClick}
              />
            )}
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default HomeScreen;
