import * as React from "react";
import { numberWithCommas } from "utils/helpers";
// import Loading from "components/Loading/Loading";
import format from "date-fns/format";

import { CATEGORY_DESCRIPTION } from "constants/transaction";

export interface PendingTransactionDetailProps {
  content: any;
}

const PendingTransactionDetail: React.FC<PendingTransactionDetailProps> = ({
  content,
}) => {
  return (
    <div>
      <h2>Pending Transaction</h2>
      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          {!!content?.metadata?.accountName && (
            <tr>
              <td>Account Name</td>
              <td align="right">{content?.metadata?.accountName}</td>
            </tr>
          )}
          {!!content?.metadata?.accountNumber && (
            <tr>
              <td>Account Number</td>
              <td align="right">{content?.metadata?.accountNumber}</td>
            </tr>
          )}
          <tr>
            <td>Status</td>
            <td align="right">{content?.status}</td>
          </tr>
          <tr>
            <td>Owner</td>
            <td align="right">
              {content?.creator?.firstName + " " + content?.creator?.lastName}
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td align="right">{content?.type}</td>
          </tr>
          <tr>
            <td>Amount</td>
            <td align="right">&#8358;{numberWithCommas(content?.amount)}</td>
          </tr>
          {!!content?.approvedBy && (
            <tr>
              <td>Approved By</td>
              <td align="right">
                {content?.approvedBy?.firstName +
                  " " +
                  content?.approvedBy?.lastName}
              </td>
            </tr>
          )}
          <tr>
            <td>Category</td>
            <td align="right">{CATEGORY_DESCRIPTION[content?.category]}</td>
          </tr>
          {!!content?.metadata?.reference && (
            <tr>
              <td>Reference</td>
              <td style={{ wordWrap: "break-word" }} align="right">
                {content?.metadata?.reference}
              </td>
            </tr>
          )}
          <tr>
            <td>Transaction Date</td>
            <td align="right">
              {format(new Date(content?.createdAt), "dd-MM-yyyy p")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PendingTransactionDetail;
