export const BUSINESS_CATEGORY = [
    "Small and Medium Scale Enterprise",
    "Large Retail Stores",
    "Supermarkets",
    "Online Stores",
    "Hotels",
    "Restaurants",
    "Tertiary Institutions",
    "Secondary Schools",
    "Betting & Gaming",
    "Delivery & Logistics",
    "Religious Organisations",
    "Utility Companies",
    "Insurance",
    "Government Organisations and Taxes",
    "Electricity Companies",
    "Petrol Stations",
    "Super Agent/ MMOs",
    "Pensions",
    "NGOs",
    "Others",
    "Savings and Investment",
    "Payment Aggregator",
    "Lending",
    "Cooperative",
    "Digital Banking",
    "Agriculture",
    "Tourism and Hospitality",
    "Educational Institutions",
    "FMCGs",
    "Forex",
    "eCommerce",
    "Microfinance Bank",
    "Health",
    "Entertainment and Social media",
];
