import * as React from "react";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import format from "date-fns/format";
import { CATEGORY_DESCRIPTION } from "constants/transaction";

export interface TransactionDetailProps {
  transaction: any;
  status?: string;
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({
  transaction,
  status,
}) => {
  const data = transaction?.data;

  if (transaction.loading) {
    return <Loading />;
  }

  switch (status) {
    case "approved":
      return (
        <div>
          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-borderless mt-4"
          >
            <tbody>
              {(data?.category === "BANK_TRANSFER" ||
                data?.category === "CUSTOMER_BANK_TRANSFER") && (
                <>
                  <tr>
                    <td>Account Name</td>
                    <td align="right" style={{ textTransform: "capitalize" }}>
                      {data?.metadata?.accountName}
                    </td>
                  </tr>
                  <tr>
                    <td>Account Number</td>
                    <td align="right" style={{ textTransform: "capitalize" }}>
                      {data?.metadata?.accountNumber}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td>Status</td>
                <td align="right" style={{ textTransform: "capitalize" }}>
                  {status}
                </td>
              </tr>
              {data?.metadata?.narration ? (
                <tr>
                  <td>Narration</td>
                  <td align="right">{data?.metadata?.narration}</td>
                </tr>
              ) : (
                <tr>
                  <td>Description</td>
                  <td align="right">{data?.description}</td>
                </tr>
              )}
              <tr>
                <td>Type</td>
                <td align="right">{data?.type}</td>
              </tr>

              <tr>
                <td>Amount</td>
                <td align="right">&#8358;{numberWithCommas(data?.amount)}</td>
              </tr>
              {!!data?.fee && (
                <tr>
                  <td>Transaction Fee</td>
                  <td align="right">&#8358;{numberWithCommas(data?.fee)}</td>
                </tr>
              )}
              {!!data?.vat && (
                <tr>
                  <td>VAT</td>
                  <td align="right">&#8358;{numberWithCommas(data?.vat)}</td>
                </tr>
              )}
              <tr>
                <td>Balance Before</td>
                <td align="right">
                  &#8358;{numberWithCommas(data?.balance_before)}
                </td>
              </tr>
              <tr>
                <td>Balance After</td>
                <td align="right">
                  &#8358;{numberWithCommas(data?.balance_after)}
                </td>
              </tr>
              <tr>
                <td>Category</td>
                <td align="right">{CATEGORY_DESCRIPTION[data?.category]}</td>
              </tr>
              <tr>
                <td>Currency</td>
                <td align="right">{data?.currency}</td>
              </tr>
              <tr>
                <td>Reference</td>
                <td style={{ wordWrap: "break-word" }} align="right">
                  {data?.reference}
                </td>
              </tr>

              {data?.category === "BANK_TRANSFER" && (
                <>
                  {data?.metadata?.sessionID && (
                    <tr>
                      <td>SessionID</td>
                      <td style={{ wordWrap: "break-word" }} align="right">
                        {data?.metadata?.sessionID}
                      </td>
                    </tr>
                  )}
                </>
              )}
              <tr>
                <td>Transaction Date</td>
                <td align="right">
                  {format(new Date(data?.createdAt), "dd-MM-yyyy p")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case "batch":
      return (
        <div>
          <table
            style={{ tableLayout: "fixed" }}
            className="table table-striped table-borderless mt-4"
          >
            <tbody>
              <tr>
                <td>Category</td>
                <td align="right">{CATEGORY_DESCRIPTION[data?.category]}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td align="right">{data?.type}</td>
              </tr>

              <tr>
                <td>Total Transactions</td>
                <td align="right">{data?.all_references.length}</td>
              </tr>
              <tr>
                <td>Passed Transactions</td>
                <td align="right">{data?.passed_references.length}</td>
              </tr>
              <tr>
                <td>Failed Transactions</td>
                <td align="right">{data?.failed_references.length}</td>
              </tr>
              <tr>
                <td>Reference</td>
                <td style={{ wordWrap: "break-word" }} align="right">
                  {data?.reference}
                </td>
              </tr>
              <tr>
                <td>Transaction Date</td>
                <td align="right">
                  {format(new Date(data?.createdAt), "dd-MM-yyyy p")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    default:
      return null;
  }
};

export default TransactionDetail;
