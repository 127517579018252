import React from "react";
import Header from "components/Header/Header";
// import DottedImg from "assets/images/addWallet.svg";

import styles from "./Merchant.module.css";

const Merchant = ({ data }) => {
    return (
        <div className={styles.wallet}>
            <div className="mb-2">
                <Header>
                    Merchants {/*<img alt="Option" className={styles.wallet__headerIcon} src={DottedImg} />*/}
                </Header>
            </div>

            <div className={styles.wallet__content}>
                <div className={styles.transaction__balanceSheet}>
                    <p className={styles.transaction__balanceSheetTitle}>Total Number of Merchants</p>
                    <h3 className={styles.transaction__balanceSheetAmount}>{data?.totalMerchants || 0}</h3>
                </div>
                <div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Active Merchants</p>
                    <h3 className={styles.wallet__amount}>{data?.activeMerchants || 0}</h3>
                </div>
                {/*<div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Pending Merchants</p>
                    <h3 className={styles.wallet__amount}>{data.pendingMerchants}</h3>
    </div>*/}
                <div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Frozen Merchants</p>
                    <h3 className={styles.wallet__amount}>{data?.frozenMerchants || 0}</h3>
                </div>
            </div>
        </div>
    );
};

export default Merchant;
