import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../constants/api";

import styles from "./Customers.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import CustomerDetail from "./components/CustomerDetail/CustomerDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
  getCustomersFn,
  // getSingleCustomerFn,
  // selectSingleCustomer,
  selectCustomers,
  selectBankAccessModeState,
} from "slices/bankSlice";
import { PrimaryButton } from "components/Button/Button";
import routes from "routing/routes";
import request from "constants/requests";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const customersState = useSelector(selectCustomers);
  const modeState = useSelector(selectBankAccessModeState);
  // const singleCustomerState = useSelector(selectSingleCustomer);
  // All customers
  const {
    data: customersData,
    meta: customersMeta,
    loading: customerIsLoading,
  } = customersState;

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(customersMeta?.page || 1);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [fetching, setFetching] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);

  const fetchCustomers = () => {
    dispatch(getCustomersFn({ mode: modeState, page: currentPage }));
  };

  useEffect(fetchCustomers, [currentPage, modeState]);

  useEffect(() => {
    if (!searchValue && searchCategory) {
      setSearchCategory("");
    }
  }, [searchValue, searchCategory]);

  const exportCustomersFn = async ({
    currentPage,
    mode,
  }: {
    currentPage?: number;
    mode?: string;
  }) => {
    setLoading(true);

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/admin/customers/export`;
      let params = "";

      if (currentPage) {
        params = params + `currentPage=${currentPage}`;
      }
      if (params && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params && mode) {
        params = params + `mode=${mode}`;
      }
      if (params && searchValue) {
        params = params + `&phoneNumber=${searchValue}`;
      }
      if (!params && searchValue) {
        params = params + `phoneNumber=${searchValue}`;
      }

      if (params?.length) {
        URLToPDF = `${URLToPDF}?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "customers.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        setLoading(false);
      };
      httpClient.send();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const renderRows = () => {
    const rows = customersData?.map((each: any) => {
      return (
        <tr style={{ cursor: "pointer" }} key={each?.id}>
          <td onClick={() => getCustomer(each)}>
            {each?.lastName} {each?.firstName}
          </td>
          <td onClick={() => getCustomer(each)}>{each?.dateOfBirth}</td>
          <td onClick={() => getCustomer(each)}>{each?.phoneNumber}</td>
          <td onClick={() => getCustomer(each)}>{each?.email}</td>
          <td onClick={() => getCustomer(each)}>
            {format(parseISO(each?.createdAt), "dd MMMM, yyyy")}
          </td>
          <td>
            <PrimaryButton
              title="View Transactions"
              size="sm"
              link={`${routes.BANK_CUSTOMER}/${each?.id}/transactions`}
            />
          </td>
        </tr>
      );
    });
    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const createCustomerCard = async (e) => {
    const { files } = e.target;

    if (files?.length > 0) {
      setCardLoading(true);
      const formData = new FormData();
      formData.set("cards", files[0]);

      try {
        const { data } = await request({
          method: "post",
          url: "/directcard/create-cards",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        toast.success(data?.message);
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message);
      } finally {
        setCardLoading(false);
      }
    }
  };

  const renderSideContent = () => {
    if (sideContent === "customerDetail") {
      return (
        <CustomerDetail
          customer={{ data: selectedCustomer, loading: customerIsLoading }}
          closeModalFn={closeModal}
          fetchCustomers={fetchCustomers}
        />
      );
    } else {
      return null;
    }
  };

  const getCustomer = (customer: Record<string, any>) => {
    setSelectedCustomer(customer);
    openModal("customerDetail");
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <BankLayout>
        {customerIsLoading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Customers</Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of customers
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {customersMeta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  if (
                    (searchValue && !searchCategory) ||
                    (!searchValue && searchCategory)
                  ) {
                    toast.error(
                      "Search value and search category are required"
                    );
                    return;
                  }

                  setFetching(true);

                  dispatch(
                    getCustomersFn({
                      mode: modeState,
                      search: searchValue,
                      searchBy: searchCategory,
                      cb: () => {
                        setFetching(false);
                      },
                    })
                  );
                }}
                className={styles.search__container}
              >
                <div className={styles.search__category}>
                  <div className={styles.search__content}>
                    <label className="sr-only" htmlFor="searchCustomer">
                      Search customer
                    </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fas fa-search"></i>
                        </div>
                      </div>
                      <input
                        type="text"
                        id="searchCustomer"
                        value={searchValue}
                        className="form-control"
                        style={{ borderLeftColor: "transparent" }}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search Customer"
                      />
                    </div>
                  </div>
                  <select
                    value={searchCategory}
                    className={styles.category}
                    onChange={(e) => setSearchCategory(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    <option value="PHONE_NUMBER">Phone Number</option>
                    <option value="ACCOUNT_NUMBER">Account Number</option>
                  </select>
                </div>
                <label htmlFor="card" className={styles.create__card}>
                  {cardLoading ? "Creating Cards..." : "Create Customers Card"}
                  <input
                    type="file"
                    id="card"
                    onChange={createCustomerCard}
                    style={{ display: "none" }}
                  />
                </label>
                <PrimaryButton
                  title={loading ? "Exporting..." : "Export Customer List"}
                  type="button"
                  className={styles.export}
                  onClick={() =>
                    exportCustomersFn({ mode: modeState, currentPage })
                  }
                />
              </form>
              <Table
                noData={customersData?.length === 0 ? true : false}
                headers={[
                  "name",
                  "date of birth",
                  "phone number",
                  "email",
                  "date",
                  "action",
                ]}
              >
                {fetching ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              {customersMeta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={customersMeta?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default CustomersScreen;
