import React, { Fragment } from "react";
import { object, string, number, boolean } from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import TextInput from "components/TextInput/TextInput";
import { BUSINESS_CATEGORY } from "constants/business";
import { PrimaryButton } from "components/Button/Button";

const CreateSubMerchant: React.FC<{ loading: boolean; onSubmit: any }> = ({ loading, onSubmit }) => {
    return (
        <div>
            <h2 className="mb-5">Create Agent</h2>
            <Formik
                onSubmit={onSubmit}
                validationSchema={RegisterSchema}
                initialValues={{
                    bvn: "",
                    email: "",
                    lastName: "",
                    password: "",
                    firstName: "",
                    agreement: "",
                    phoneNumber: "",
                    businessName: "",
                    businessType: "",
                    accountNumber: "",
                }}
            >
                {(props) => {
                    const businessTypeClassname = `form-control form-control-lg ${
                        props.errors.businessType && props.touched.businessType ? "is-invalid" : ""
                    }`;

                    return (
                        <Form>
                            <Field name="firstName" placeholder="First Name*" type="text" component={TextInput} />

                            <Field name="lastName" placeholder="Last Name*" type="text" component={TextInput} />

                            <Field name="email" placeholder="Email Address*" type="email" component={TextInput} />

                            <Field
                                name="password"
                                type="password"
                                component={TextInput}
                                placeholder="Password*"
                                autoComplete="new-password"
                            />

                            <Field name="phoneNumber" placeholder="Phone number*" type="text" component={TextInput} />

                            <Field name="businessName" placeholder="Business Name*" type="text" component={TextInput} />
                            <Field name="bvn" placeholder="BVN*" type="text" component={TextInput} />

                            <div className="form-group">
                                <Field component="select" name="businessType" placeholder="Business Type" className={businessTypeClassname}>
                                    <option value="" disabled hidden>
                                        Select Business Type
                                    </option>
                                    {BUSINESS_CATEGORY.map((type) => (
                                        <option key={type}>{type}</option>
                                    ))}
                                </Field>
                                <ErrorMessage name="businessType">
                                    {(error) => <div className="invalid-feedback">{error}</div>}
                                </ErrorMessage>
                            </div>

                            <Field name="agreement">
                                {({ field, form, meta }: any) => (
                                    <div className="form-group form-check  ">
                                        <input type="checkbox" className="form-check-input" id="agreement" {...field} />
                                        <label className="form-check-label" htmlFor="agreement">
                                            I agree to be contacted by Tagpay about offers
                                        </label>
                                        {meta?.error && <div className="invalid-feedback">{meta?.error}</div>}
                                    </div>
                                )}
                            </Field>

                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <PrimaryButton type="submit" block size="lg" title="Proceed" />
                                </Fragment>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CreateSubMerchant;

const RegisterSchema = object().shape({
    firstName: string().min(2, "Too Short!").max(70, "Too Long!").required("Required"),
    lastName: string().min(2, "Too Short!").max(70, "Too Long!").required("Required"),
    phoneNumber: string().min(11, "Too Short!").max(11, "Too Long!").required("Required"),
    businessName: string().min(2, "Too Short!").max(70, "Too Long!").required("Required"),
    businessType: string().trim().required("Business type is required."),
    password: string().min(6, "Too Short!").max(70, "Too Long!").required("Required"),
    email: string().email("Invalid email").required("Required"),
    agreement: boolean().required("You must accept agreement"),
    bvn: string().required("Bvn is required.").length(11),
    charges: object().shape({
        bvnChargeV1: number(),
        fundingRate: number(),
        fundingRateMax: number(),
        tier_1_daily_limit: number(),
        tier_2_daily_limit: number(),
        tier_3_daily_limit: number(),
        bvnVerificationCharge: number(),
        walletToWalletTransfer: number(),
        walletReservationCharge: number(),
        transferCharges: object()
            .shape({
                max5000: number(),
                max50000: number(),
                min50000: number(),
            })
            .notRequired(),
    }),
});
