import * as React from "react";
import styles from "./SideContent.module.css";

interface SideContentProps {
  modalIsOpen: boolean;
  closeModalFn: () => void;
  upperModal?: boolean;
  isCentralized?: boolean;
}

const SideContent: React.FC<React.PropsWithChildren<SideContentProps>> = ({
  modalIsOpen,
  children,
  upperModal,
  isCentralized,
  closeModalFn,
}) => {
  return (
    <div
      className={
        modalIsOpen
          ? isCentralized
            ? styles.centralized
            : upperModal
            ? styles.sideContentNew
            : styles.sideContent
          : styles.noDisplay
      }
    >
      {children}
      {!!isCentralized && (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "transparent",
          }}
          onClick={closeModalFn}
        />
      )}
    </div>
  );
};

export default SideContent;
