import React, { Fragment, useState, useEffect } from "react";
import { object, string } from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import TextInput from "components/TextInput/TextInput";
import { BUSINESS_CATEGORY } from "constants/business";
import { PrimaryButton } from "components/Button/Button";
import request from "constants/requests";

const CreateSubMerchant: React.FC<{ loading: boolean; onSubmit: any }> = ({
  loading,
  onSubmit,
}) => {
  const [roles, setRoles] = useState([]);

  const fetchRolesFn = async () => {
    try {
      const { data } = await request({
        method: "get",
        url: "/team/admin/roles",
      });
      setRoles(data?.data);
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    fetchRolesFn();
  }, []);

  return (
    <div>
      <h2 className="mb-5">Create Admin</h2>
      <Formik
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
          role: "",
        }}
      >
        {(props) => {
          const roleClassName = `form-control form-control-lg ${
            props.errors.role && props.touched.role ? "is-invalid" : ""
          }`;

          return (
            <Form>
              <Field
                name="firstName"
                placeholder="First Name*"
                type="text"
                component={TextInput}
              />

              <Field
                name="lastName"
                placeholder="Last Name*"
                type="text"
                component={TextInput}
              />

              <Field
                name="email"
                placeholder="Email Address*"
                type="email"
                component={TextInput}
              />

              <Field
                name="phoneNumber"
                placeholder="Phone number*"
                type="text"
                component={TextInput}
              />

              <Field
                name="password"
                type="password"
                component={TextInput}
                placeholder="Password*"
                autoComplete="new-password"
              />

              <div className="form-group">
                <Field
                  component="select"
                  name="role"
                  placeholder="Select Role"
                  className={roleClassName}
                >
                  <option value="" disabled hidden>
                    Select Roles
                  </option>
                  {roles.map((each) => (
                    <option key={each?.name}>{each?.name}</option>
                  ))}
                </Field>
                <ErrorMessage name="role">
                  {(error) => <div className="invalid-feedback">{error}</div>}
                </ErrorMessage>
              </div>

              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <PrimaryButton
                    type="submit"
                    block
                    size="lg"
                    title="Proceed"
                  />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateSubMerchant;

const ValidationSchema = object().shape({
  firstName: string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  phoneNumber: string()
    .min(11, "Too Short!")
    .max(11, "Too Long!")
    .required("Required"),
  password: string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: string().email("Invalid email").required("Required"),
  role: string().required("Role is required"),
});
