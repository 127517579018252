import React, { Fragment, useEffect } from "react";
import { object, string} from "yup";
import { Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import {selectMerchantRoles, getRolesFn} from 'slices/merchantSlice';

const validationSchema = object().shape({
    role: string().required('Role is required'),
    approvalLimit: string().required('Approval limit is required')
});

const UpdateMemberRole: React.FC<{ loading: boolean; content: any; onSubmit: any }> = ({ loading, content, onSubmit }) => {
    const dispatch = useDispatch();

    const rolesState = useSelector(selectMerchantRoles);

    const fetchRoles = () => {
        dispatch(getRolesFn());
    }

    useEffect(fetchRoles, []);

    return (
        <div>
            <h2 className="mb-5">Update Member</h2>
            <Formik
                onSubmit={(values) => onSubmit({id: content?.id, roleId: values.role, approvalLimit: values.approvalLimit})}
                validationSchema={validationSchema}
                initialValues={{
                    role: content?.role || '',
                    approvalLimit: content?.approvalLimit || content?.approvalLimit === 0 ? 0 : ''
                }}
            >
                {({errors, touched}) => {
                    return (
                        <Form>
                            <Field name="role" component='select' className={`form-control form-control-lg mb-3 ${
                                errors.role && touched.role ? "is-invalid" : ""}`} placeholder="Role*" type="text" value={content?.roleId}>
                                <option value=''>-- Select Role --</option>
                                {rolesState?.data?.map((each) => <option value={each?.id} key={each?.id}>{each?.name}</option>)}
                            </Field>
                            <Field name="approvalLimit" placeholder="Approval Limit*" type="text" component={TextInput} />
                            

                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <PrimaryButton type="submit" block size="lg" title="Update" />
                                </Fragment>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default UpdateMemberRole;


