import { createSlice } from "@reduxjs/toolkit";

import config from "constants/api";
import request from "constants/requests";
import { toast } from "react-toastify";

const initialState = {
  accessMode:
    localStorage.getItem(config.ACCOUNT_MODE) === "PRODUCTION"
      ? "PRODUCTION"
      : "SANDBOX",
  roles: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  createRole: {
    loading: false,
    info: null,
    data: null,
    error: null,
  },
  updateRole: {
    loading: false,
    info: null,
    data: null,
    error: null,
  },
  permissions: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  teamMembers: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  invitations: {
    loading: false,
    data: null,
    meta: {},
    error: null,
  },
  merchantList: {
    loading: false,
    data: [],
    meta: {},
    error: null,
  },
  singleMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  inviteTeamMembers: {
    loading: false,
    data: null,
    info: null,
    error: null,
  },
  resendInvitation: {
    loading: false,
    data: null,
    info: null,
    error: null,
  },
  acceptInvitation: {
    loading: false,
    data: null,
    info: null,
    error: null,
  },
  switchMerchant: {
    loading: false,
    data: null,
    info: null,
    error: null,
  },
  updateMemberRole: {
    loading: false,
    data: null,
    info: null,
    error: null,
  },
  profile: {
    loading: false,
    data: null,
    permissions: null,
    error: null,
  },
  activateMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  deactivateMerchant: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantInfo: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantCharges: {
    loading: false,
    data: null,
    error: null,
  },
  updateMerchantLimit: {
    loading: false,
    data: null,
    error: null,
  },
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    resetRoles: (state) => {
      const { roles } = state;

      roles.loading = true;
      roles.data = [];
      roles.meta = {};
      roles.error = null;
    },
    rolesSuccess: (state, { payload }) => {
      const { roles } = state;

      roles.loading = false;
      roles.data = payload.data;
      roles.meta = payload.meta;
      roles.error = null;
    },
    rolesFailure: (state, { payload }) => {
      const { roles } = state;

      roles.loading = false;
      roles.data = [];
      roles.meta = {};
      roles.error = payload;
    },
    resetCreateRole: (state) => {
      const { createRole } = state;

      createRole.loading = true;
      createRole.info = null;
      createRole.data = null;
      createRole.error = null;
    },
    createRoleSuccess: (state, { payload }) => {
      const { createRole } = state;

      createRole.loading = false;
      createRole.data = payload.data;
      createRole.info = payload.info;
      createRole.error = null;
    },
    createRoleFailure: (state, { payload }) => {
      const { createRole } = state;

      createRole.loading = false;
      createRole.data = [];
      createRole.info = null;
      createRole.error = payload;
    },
    resetUpdateRole: (state) => {
      const { updateRole } = state;

      updateRole.loading = true;
      updateRole.info = null;
      updateRole.data = null;
      updateRole.error = null;
    },
    updateRoleSuccess: (state, { payload }) => {
      const { updateRole } = state;

      updateRole.loading = false;
      updateRole.info = payload.info;
      updateRole.data = payload.data;
      updateRole.error = null;
    },
    updateRoleFailure: (state, { payload }) => {
      const { updateRole } = state;

      updateRole.loading = false;
      updateRole.info = null;
      updateRole.data = null;
      updateRole.error = payload;
    },
    resetPermissions: (state) => {
      const { permissions } = state;

      permissions.loading = true;
      permissions.data = [];
      permissions.meta = {};
      permissions.error = null;
    },
    permissionsSucess: (state, { payload }) => {
      const { permissions } = state;

      permissions.loading = false;
      permissions.data = payload.data;
      permissions.meta = payload.meta;
      permissions.error = null;
    },
    permissionsFailure: (state, { payload }) => {
      const { permissions } = state;

      permissions.loading = false;
      permissions.data = [];
      permissions.meta = {};
      permissions.error = payload;
    },
    resetTeamMembers: (state) => {
      const { teamMembers } = state;

      teamMembers.loading = true;
      teamMembers.data = [];
      teamMembers.meta = {};
      teamMembers.error = null;
    },
    teamMembersSuccess: (state, { payload }) => {
      const { teamMembers } = state;

      teamMembers.loading = false;
      teamMembers.data = payload.data;
      teamMembers.meta = payload.meta;
      teamMembers.error = null;
    },
    teamMembersFailure: (state, { payload }) => {
      const { teamMembers } = state;

      teamMembers.loading = false;
      teamMembers.data = [];
      teamMembers.meta = {};
      teamMembers.error = payload;
    },
    resetInvitations: (state) => {
      const { invitations } = state;

      invitations.loading = true;
      invitations.data = [];
      invitations.meta = {};
      invitations.error = null;
    },
    invitationsSuccess: (state, { payload }) => {
      const { invitations } = state;

      invitations.loading = false;
      invitations.data = payload.data;
      invitations.meta = payload.meta;
      invitations.error = null;
    },
    invitationsFailure: (state, { payload }) => {
      const { invitations } = state;

      invitations.loading = false;
      invitations.data = [];
      invitations.meta = {};
      invitations.error = payload;
    },
    resetMerchantList: (state) => {
      const { merchantList } = state;

      merchantList.loading = true;
      merchantList.data = [];
      merchantList.meta = {};
      merchantList.error = null;
    },
    merchantListSuccess: (state, { payload }) => {
      const { merchantList } = state;

      merchantList.loading = false;
      merchantList.data = payload.data;
      merchantList.meta = payload.meta;
      merchantList.error = null;
    },
    merchantListFailure: (state, { payload }) => {
      const { merchantList } = state;

      merchantList.loading = false;
      merchantList.data = [];
      merchantList.meta = {};
      merchantList.error = payload;
    },
    resetSingleMerchant: (state) => {
      const { singleMerchant } = state;

      singleMerchant.loading = true;
      singleMerchant.data = null;
      singleMerchant.error = null;
    },
    singleMerchantSuccess: (state, { payload }) => {
      const { singleMerchant } = state;

      singleMerchant.loading = false;
      singleMerchant.data = payload.data;
      singleMerchant.error = null;
    },
    singleMerchantFailed: (state, { payload }) => {
      const { singleMerchant } = state;

      singleMerchant.loading = false;
      singleMerchant.data = null;
      singleMerchant.error = payload;
    },
    resetInviteTeamMembers: (state) => {
      const { inviteTeamMembers } = state;

      inviteTeamMembers.loading = true;
      inviteTeamMembers.data = null;
      inviteTeamMembers.info = null;
      inviteTeamMembers.error = null;
    },
    inviteTeamMembersSuccess: (state, { payload }) => {
      const { inviteTeamMembers } = state;

      inviteTeamMembers.loading = false;
      inviteTeamMembers.data = payload.data;
      inviteTeamMembers.info = payload.info;
      inviteTeamMembers.error = null;
    },
    inviteTeamMembersFailure: (state, { payload }) => {
      const { inviteTeamMembers } = state;

      inviteTeamMembers.loading = false;
      inviteTeamMembers.data = null;
      inviteTeamMembers.info = null;
      inviteTeamMembers.error = payload;
    },
    resetResendInvitation: (state) => {
      const { resendInvitation } = state;

      resendInvitation.loading = true;
      resendInvitation.data = null;
      resendInvitation.info = null;
      resendInvitation.error = null;
    },
    resendInvitationSuccess: (state, { payload }) => {
      const { resendInvitation } = state;

      resendInvitation.loading = false;
      resendInvitation.data = payload.data;
      resendInvitation.info = payload.info;
      resendInvitation.error = null;
    },
    resendInvitationFailure: (state, { payload }) => {
      const { resendInvitation } = state;

      resendInvitation.loading = false;
      resendInvitation.data = null;
      resendInvitation.info = null;
      resendInvitation.error = payload;
    },
    resetAcceptInvitation: (state) => {
      const { acceptInvitation } = state;

      acceptInvitation.loading = true;
      acceptInvitation.data = null;
      acceptInvitation.info = null;
      acceptInvitation.error = null;
    },
    acceptInvitationSuccess: (state, { payload }) => {
      const { acceptInvitation } = state;

      acceptInvitation.loading = false;
      acceptInvitation.data = payload.data;
      acceptInvitation.info = payload.info;
      acceptInvitation.error = null;
    },
    acceptInvitationFailure: (state, { payload }) => {
      const { acceptInvitation } = state;

      acceptInvitation.loading = false;
      acceptInvitation.data = null;
      acceptInvitation.info = null;
      acceptInvitation.error = payload;
    },
    resetSwitchMerchant: (state) => {
      const { switchMerchant } = state;

      switchMerchant.loading = true;
      switchMerchant.data = null;
      switchMerchant.info = null;
      switchMerchant.error = null;
    },
    switchMerchantSuccess: (state, { payload }) => {
      const { switchMerchant } = state;

      switchMerchant.loading = false;
      switchMerchant.data = payload.data;
      switchMerchant.info = payload.info;
      switchMerchant.error = null;
    },
    switchMerchantFailure: (state, { payload }) => {
      const { switchMerchant } = state;

      switchMerchant.loading = false;
      switchMerchant.data = null;
      switchMerchant.info = null;
      switchMerchant.error = payload;
    },
    resetUpdateMemberRole: (state) => {
      const { updateMemberRole } = state;

      updateMemberRole.loading = true;
      updateMemberRole.data = null;
      updateMemberRole.info = null;
      updateMemberRole.error = null;
    },
    updateMemberRoleSuccess: (state, { payload }) => {
      const { updateMemberRole } = state;

      updateMemberRole.loading = false;
      updateMemberRole.data = payload.data;
      updateMemberRole.info = payload.info;
      updateMemberRole.error = null;
    },
    updateMemberRoleFailure: (state, { payload }) => {
      const { updateMemberRole } = state;

      updateMemberRole.loading = false;
      updateMemberRole.data = null;
      updateMemberRole.info = null;
      updateMemberRole.error = payload;
    },
    resetGetProfile: (state) => {
      const { profile } = state;

      profile.loading = true;
      profile.data = null;
      profile.permissions = null;
      profile.error = null;
    },
    profileSuccess: (state, { payload }) => {
      const { profile } = state;

      profile.loading = false;
      profile.data = payload.data;
      profile.permissions = payload.permissions;
      profile.error = null;
    },
    profileFailure: (state, { payload }) => {
      const { profile } = state;

      profile.loading = false;
      profile.data = null;
      profile.permissions = null;
      profile.error = payload;
    },
    resetActivateMerchant: (state) => {
      const { activateMerchant } = state;

      activateMerchant.loading = true;
      activateMerchant.data = null;
      activateMerchant.error = null;
    },
    activateMerchantSuccess: (state, { payload }) => {
      const { activateMerchant } = state;

      activateMerchant.loading = false;
      activateMerchant.data = payload.data;
      activateMerchant.error = null;
    },
    activateMerchantFailed: (state, { payload }) => {
      const { activateMerchant } = state;

      activateMerchant.loading = false;
      activateMerchant.data = null;
      activateMerchant.error = payload;
    },
    resetDeactivateMerchant: (state) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = true;
      deactivateMerchant.data = null;
      deactivateMerchant.error = null;
    },
    deactivateMerchantSuccess: (state, { payload }) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = false;
      deactivateMerchant.data = payload.data;
      deactivateMerchant.error = null;
    },
    deactivateMerchantFailed: (state, { payload }) => {
      const { deactivateMerchant } = state;

      deactivateMerchant.loading = false;
      deactivateMerchant.data = null;
      deactivateMerchant.error = payload;
    },
    resetUpdateMerchantInfo: (state) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = true;
      updateMerchantInfo.data = null;
      updateMerchantInfo.error = null;
    },
    updateMerchantInfoSuccess: (state, { payload }) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = false;
      updateMerchantInfo.data = payload.data;
      updateMerchantInfo.error = null;
    },
    updateMerchantInfoFailed: (state, { payload }) => {
      const { updateMerchantInfo } = state;

      updateMerchantInfo.loading = false;
      updateMerchantInfo.data = null;
      updateMerchantInfo.error = payload;
    },
    resetUpdateMerchantCharges: (state) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = true;
      updateMerchantCharges.data = null;
      updateMerchantCharges.error = null;
    },
    updateMerchantChargesSuccess: (state, { payload }) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = false;
      updateMerchantCharges.data = payload.data;
      updateMerchantCharges.error = null;
    },
    updateMerchantChargesFailed: (state, { payload }) => {
      const { updateMerchantCharges } = state;

      updateMerchantCharges.loading = false;
      updateMerchantCharges.data = null;
      updateMerchantCharges.error = payload;
    },
    resetUpdateMerchantLimit: (state) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = true;
      updateMerchantLimit.data = null;
      updateMerchantLimit.error = null;
    },
    updateMerchantLimitSuccess: (state, { payload }) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = false;
      updateMerchantLimit.data = payload.data;
      updateMerchantLimit.error = null;
    },
    updateMerchantLimitFailed: (state, { payload }) => {
      const { updateMerchantLimit } = state;

      updateMerchantLimit.loading = false;
      updateMerchantLimit.data = null;
      updateMerchantLimit.error = payload;
    },
  },
});

export const {
  resetRoles,
  rolesSuccess,
  rolesFailure,
  resetCreateRole,
  createRoleSuccess,
  createRoleFailure,
  resetUpdateRole,
  updateRoleSuccess,
  updateRoleFailure,
  resetPermissions,
  permissionsSucess,
  permissionsFailure,
  resetTeamMembers,
  teamMembersSuccess,
  teamMembersFailure,
  resetInvitations,
  invitationsSuccess,
  invitationsFailure,
  resetMerchantList,
  merchantListSuccess,
  merchantListFailure,
  resetInviteTeamMembers,
  inviteTeamMembersSuccess,
  inviteTeamMembersFailure,
  resetResendInvitation,
  resendInvitationSuccess,
  resendInvitationFailure,
  resetAcceptInvitation,
  acceptInvitationSuccess,
  acceptInvitationFailure,
  resetSwitchMerchant,
  switchMerchantSuccess,
  switchMerchantFailure,
  resetUpdateMemberRole,
  updateMemberRoleSuccess,
  updateMemberRoleFailure,
  resetGetProfile,
  profileSuccess,
  profileFailure,
  resetSingleMerchant,
  singleMerchantSuccess,
  singleMerchantFailed,
  resetActivateMerchant,
  activateMerchantSuccess,
  activateMerchantFailed,
  resetDeactivateMerchant,
  deactivateMerchantSuccess,
  deactivateMerchantFailed,
  resetUpdateMerchantInfo,
  updateMerchantInfoSuccess,
  updateMerchantInfoFailed,
  resetUpdateMerchantCharges,
  updateMerchantChargesSuccess,
  updateMerchantChargesFailed,
  resetUpdateMerchantLimit,
  updateMerchantLimitSuccess,
  updateMerchantLimitFailed,
} = merchantSlice.actions;

export const getRolesFn = () => async (dispatch) => {
  dispatch(resetRoles());

  try {
    const { data } = await request({
      method: "get",
      url: "/team/roles",
    });

    dispatch(rolesSuccess({ data: data?.data, meta: {} }));
  } catch (error) {
    if (error?.response) {
      dispatch(rolesFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(rolesFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const createRoleFn =
  ({ role, permissions }) =>
  async (dispatch) => {
    dispatch(resetCreateRole());

    try {
      const { data } = await request({
        method: "post",
        url: "/team/roles",
        data: { name: role, permissions },
      });

      dispatch(createRoleSuccess({ data: data?.data, info: data?.message }));
      toast.success(data?.message);
    } catch (error) {
      if (error?.response) {
        dispatch(createRoleFailure(error?.response?.data || error?.response));
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(createRoleFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateRoleFn =
  ({ id, role, permissions }) =>
  async (dispatch) => {
    dispatch(resetUpdateRole());

    try {
      const { data } = await request({
        method: "patch",
        url: "/team/roles/" + id,
        data: { name: role, permissions },
      });

      dispatch(updateRoleSuccess({ data: data?.data, info: data?.message }));
      toast.success(data?.message);
    } catch (error) {
      if (error?.response) {
        dispatch(updateRoleFailure(error?.response?.data || error?.response));
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateRoleFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getPermissionsFn = () => async (dispatch) => {
  dispatch(resetPermissions());

  try {
    const { data } = await request({
      method: "get",
      url: "/team/permissions",
    });

    dispatch(permissionsSucess({ data: data?.data, meta: {} }));
  } catch (error) {
    if (error?.response) {
      dispatch(permissionsFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(permissionsFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const getTeamMembersFn = () => async (dispatch) => {
  dispatch(resetTeamMembers());

  try {
    const { data } = await request({
      method: "get",
      url: "/team/members",
    });

    dispatch(teamMembersSuccess({ data: data?.data, meta: data?.metadata }));
  } catch (error) {
    if (error?.response) {
      dispatch(teamMembersFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(teamMembersFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const getInvitationsFn =
  ({ metadata }) =>
  async (dispatch) => {
    if (metadata.currentPage === 1) {
      dispatch(resetInvitations());
    }

    try {
      const { data } = await request({
        method: "get",
        url: "/team/invitations",
        params: { page: metadata.currentPage, perPage: metadata.postsPerPage },
      });

      dispatch(invitationsSuccess({ data: data?.data, meta: data?.metadata }));
    } catch (error) {
      if (error?.response) {
        dispatch(invitationsFailure(error?.response?.data || error?.response));
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(invitationsFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const getMerchantListFn = () => async (dispatch) => {
  dispatch(resetMerchantList());

  try {
    const { data } = await request({
      method: "get",
      url: "/team/merchants",
    });

    dispatch(merchantListSuccess({ data: data?.data, meta: {} }));
  } catch (error) {
    if (error?.response) {
      dispatch(merchantListFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(merchantListFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const getSingleMerchantFn =
  ({ merchantId, mode }) =>
  async (dispatch) => {
    dispatch(resetSingleMerchant());

    try {
      const { data } = await request({
        method: "get",
        url: "/merchant/details/" + merchantId,
        params: { mode },
      });

      dispatch(singleMerchantSuccess({ data: data?.data }));
    } catch (error) {
      if (error?.response) {
        dispatch(
          singleMerchantFailed(error?.response?.data || error?.response)
        );
      } else {
        dispatch(singleMerchantFailed(error?.message));
      }
    }
  };

export const inviteTeamMembersFn =
  ({ roleId, email, approvalLimit }) =>
  async (dispatch) => {
    dispatch(resetInviteTeamMembers());

    try {
      const { data } = await request({
        method: "post",
        url: "/team/invitations",
        data: { roleId, email, approvalLimit },
      });

      dispatch(
        inviteTeamMembersSuccess({ data: data?.message, info: data?.message })
      );
      toast.success(data?.message);
    } catch (error) {
      if (error?.response) {
        dispatch(
          inviteTeamMembersFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(inviteTeamMembersFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const resendInvitationFn =
  ({ email }) =>
  async (dispatch) => {
    dispatch(resetResendInvitation());

    try {
      const { data } = await request({
        method: "post",
        url: "/team/invitations/resend",
        data: { email },
      });

      dispatch(
        resendInvitationSuccess({ data: data?.message, info: data?.message })
      );
      toast.success(data?.message);
    } catch (error) {
      if (error?.response) {
        dispatch(
          resendInvitationFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(resendInvitationFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const acceptInvitationFn =
  ({ invitationCode, firstName, lastName, phoneNumber, password }, cb) =>
  async (dispatch) => {
    dispatch(resetAcceptInvitation());

    try {
      const { data } = await request({
        method: "post",
        url: "/team/invitations/accept",
        data: {
          invitationCode,
          firstName,
          lastName,
          phoneNumber,
          password: btoa(password),
        },
      });

      dispatch(
        acceptInvitationSuccess({ data: data?.message, info: data?.message })
      );
      toast.success(data?.message);
      setTimeout(() => {
        cb("/");
      }, 5000);
    } catch (error) {
      if (error?.response) {
        dispatch(
          acceptInvitationFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(acceptInvitationFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const switchMerchantFn =
  ({ merchantId }) =>
  async (dispatch) => {
    dispatch(resetSwitchMerchant());

    try {
      const { data } = await request({
        method: "post",
        url: "/team/merchants/switch",
        data: { merchantId },
      });

      dispatch(
        switchMerchantSuccess({ data: data?.message, info: data?.message })
      );
      window.location.reload();
    } catch (error) {
      if (error?.response) {
        dispatch(
          switchMerchantFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(switchMerchantFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMemberRoleFn =
  ({ id, roleId, approvalLimit }) =>
  async (dispatch) => {
    dispatch(resetUpdateMemberRole());

    try {
      const { data } = await request({
        method: "put",
        url: "/team/member/" + id,
        data: { roleId, approvalLimit },
      });

      dispatch(
        updateMemberRoleSuccess({ data: data?.message, info: data?.message })
      );
      toast.success(data?.message);
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMemberRoleFailure(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMemberRoleFailure(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const profileFn = () => async (dispatch) => {
  dispatch(resetGetProfile());

  try {
    const { data } = await request({
      method: "get",
      url: "/user/profile",
    });

    dispatch(
      profileSuccess({ data: data?.data, permissions: data?.permissions })
    );
    localStorage.setItem("user", JSON.stringify(data?.data));
  } catch (error) {
    if (error?.response) {
      dispatch(profileFailure(error?.response?.data || error?.response));
      toast.error(error?.response?.data || error?.response);
    } else {
      dispatch(profileFailure(error?.message));
      toast.error(error?.message);
    }
  }
};

export const activateMerchantFn =
  ({ merchantId }, cb) =>
  async (dispatch) => {
    dispatch(resetActivateMerchant());

    try {
      const { data } = await request({
        method: "patch",
        url: "/merchant/activate",
        data: { merchantId },
      });

      dispatch(activateMerchantSuccess({ data: data?.message }));
      toast.success(data?.message);
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(activateMerchantFailed(error?.response?.data.message));
        toast.error(error?.response?.data.message);
      } else {
        dispatch(activateMerchantFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const deactivateMerchantFn =
  ({ merchantId }, cb) =>
  async (dispatch) => {
    dispatch(resetDeactivateMerchant());

    try {
      const { data } = await request({
        method: "patch",
        url: "/merchant/deactivate",
        data: { merchantId },
      });

      dispatch(deactivateMerchantSuccess({ data: data?.message }));
      toast.success(data?.message);
      cb();
    } catch (error) {
      if (error?.response) {
        dispatch(deactivateMerchantFailed(error?.response?.data.message));
        toast.error(error?.response?.data.message);
      } else {
        dispatch(deactivateMerchantFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMerchantInfoFn =
  (merchantId: string, basicInformations: any, capabilities: any) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantInfo());

    try {
      await request({
        method: "put",
        url: "/merchant/details/" + merchantId,
        data: { ...basicInformations, ...capabilities },
      });

      dispatch(
        updateMerchantInfoSuccess({
          data: "Merchant informations was updated successfully",
        })
      );
      toast.success("Merchant informations was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantInfoFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantInfoFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMerchantChargesFn =
  ({ merchantId, chargesInformations }) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantCharges());

    try {
      await request({
        method: "patch",
        url: "/merchant/charges",
        data: { merchantId, ...chargesInformations },
      });

      dispatch(
        updateMerchantChargesSuccess({
          data: "Merchant charges was updated successfully",
        })
      );
      toast.success("Merchant charges was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantChargesFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantChargesFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const updateMerchantLimitFn =
  ({ merchantId, limitInformations }) =>
  async (dispatch) => {
    dispatch(resetUpdateMerchantLimit());

    try {
      await request({
        method: "patch",
        url: "/merchant/limits",
        data: { merchantId, ...limitInformations },
      });

      dispatch(
        updateMerchantLimitSuccess({
          data: "Merchant limit was updated successfully",
        })
      );
      toast.success("Merchant limit was updated successfully");
    } catch (error) {
      if (error?.response) {
        dispatch(
          updateMerchantLimitFailed(error?.response?.data || error?.response)
        );
        toast.error(error?.response?.data || error?.response);
      } else {
        dispatch(updateMerchantLimitFailed(error?.message));
        toast.error(error?.message);
      }
    }
  };

export const selectMerchantRoles = (state) => state.merchant.roles;
export const selectMerchantCreateRole = (state) => state.merchant.createRole;
export const selectMerchantUpdateRole = (state) => state.merchant.updateRole;
export const selectMerchantPermissions = (state) => state.merchant.permissions;
export const selectTeamMembers = (state) => state.merchant.teamMembers;
export const selectInvitations = (state) => state.merchant.invitations;
export const selectMerchantList = (state) => state.merchant.merchantList;
export const selectInviteTeamMembers = (state) =>
  state.merchant.inviteTeamMembers;
export const selectResendInvitation = (state) =>
  state.merchant.resendInvitation;
export const selectAcceptInvitation = (state) =>
  state.merchant.acceptInvitation;
export const selectSwitchMerchant = (state) => state.merchant.switchMerchant;
export const selectUpdateMemberRole = (state) =>
  state.merchant.updateMemberRole;
export const selectProfile = (state) => state.merchant.profile;
export const selectSingleMerchantState = (state) =>
  state.merchant.singleMerchant;
export const selectAccessModeState = (state) =>
  state.merchant.accessMode || "PRODUCTION";

export default merchantSlice.reducer;
