import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { PrimaryButton } from "components/Button/Button";
import request from "constants/requests";
import FileImage from "../../../../assets/images/file-upload.png";
import { validateFileSize } from "utils/helpers";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  bvn?: string;
  nin?: string;
  tier?: string;
  dateOfBirth: string;
  address: string;
}

interface GeneralResponse {
  status: boolean;
  message: string;
}

export interface UploadType {
  refetch: () => void;
  closeModal: () => void;
}

export const UploadBatchCards: React.FC<UploadType> = ({
  refetch,
  closeModal,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleBatchCreateCard = async (values, { resetForm }) => {
    const formdata = new FormData();

    formdata.append("cards", values?.file);

    setSubmitting(true);
    try {
      const { data }: { data: GeneralResponse } = await request({
        method: "post",
        url: "/fip/cards",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(data?.message);
      refetch();
      closeModal();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <h2 className="mb-5">Upload Batch Card</h2>
      <Formik
        initialValues={{
          file: null,
        }}
        validationSchema={CreateWalletSchema}
        onSubmit={handleBatchCreateCard}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <label
              htmlFor="upload-card"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "150px",
                borderWidth: "1px",
                borderColor: "lightgray",
                borderStyle: "dashed",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <img
                src={FileImage}
                alt=""
                style={{ width: "40px", height: "auto" }}
              />
              <p
                style={{
                  color: "#4a4a4a",
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: 600,
                  margin: 0,
                  marginTop: "8px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                {values?.file ? "File Uploaded" : "Upload File"}
              </p>
              {!values?.file && (
                <p
                  style={{
                    fontSize: "13px",
                    color: "#4a4a4a",
                    opacity: 0.8,
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  File must not be larger than 20MB
                </p>
              )}
            </label>
            <input
              type="file"
              name="file"
              id="upload-card"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.value) {
                  setFieldValue("file", e.target.files[0]);
                  e.target.value = "";
                }
              }}
              accept=".csv"
            />
            {errors?.file && touched?.file && (
              <p
                style={{
                  fontSize: "13px",
                  marginTop: "3px",
                  marginLeft: "1px",
                  color: "red",
                  opacity: 0.8,
                }}
              >
                <>{errors?.file}</>
              </p>
            )}

            <div style={{ marginTop: "50px" }}>
              {submitting ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <PrimaryButton type="submit" block size="lg" title="Upload" />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const CreateWalletSchema = Yup.object().shape({
  file: Yup.mixed()
    .required("File is required")
    .test("fileSize", "File is too large", (val) => validateFileSize(val, 20)),
});
