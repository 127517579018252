import React from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getWalletsFn } from "slices/walletSlice";
import {
  getSingleCustomerFn,
  updateCustomerNINFn,
  selectUpdateCustomerNIN,
} from "slices/customersSlice";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email?: string;
  address?: string;
  tier?: string;
  customerId?: string;
}

export interface CreateWalletSectionProps {
  editWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const UpgradeWalletTierSection: React.FC<{
  customerData: Record<string, any>;
  closeModal: () => void;
}> = ({ customerData, closeModal }) => {
  const dispatch = useDispatch();
  const ninUpdateState = useSelector(selectUpdateCustomerNIN);

  const validationSchema = Yup.object().shape({
    nin: Yup.string()
      .required("NIN is required")
      .length(11, "NIN must be 11 characters long"),
  });

  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div>
      <h2 className="mb-5">Upgrade Tier</h2>
      <Formik
        enableReinitialize
        initialValues={{
          nin: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          dispatch(
            updateCustomerNINFn({
              payload: {
                nin: values?.nin,
                customerId: customerData?.customerId,
              },
              cb: () => {
                dispatch(getWalletsFn({ page: 1 }));
                dispatch(getSingleCustomerFn(customerData?.customerId));
                closeModal();
                toast.success(ninUpdateState?.data);
                actions.resetForm();
              },
            })
          );
        }}
      >
        {() => (
          <Form>
            <>
              <Field
                name="nin"
                placeholder="NIN"
                type="text"
                component={TextInput}
                maxLength={11}
              />
              <ErrorMessage
                name="nin"
                className="invalid-feedback"
                component="div"
              />
            </>

            {ninUpdateState?.loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title={`Upgrade to Tier 2`}
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpgradeWalletTierSection;
