import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import styles from "./Subsidiaries.module.css";
import AddWalletImg from "assets/images/addWallet.svg";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateSubMerchantSection from "./components/CreateSubsidiary/CreateSubsidiary";

import {
  getCustomersFn,
  selectCreateMerchantState,
  createSubMerchantAccountFn,
  selectSubMerchantState,
  fetchSubMerchantsFn,
} from "slices/customersSlice";
import { selectProfile } from "slices/merchantSlice";

const CustomersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const subMerchantState = useSelector(selectSubMerchantState);
  const createMerchantState = useSelector(selectCreateMerchantState);
  const { permissions: profilePermissions } = useSelector(selectProfile);

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    subMerchantState?.meta?.page || 1
  );

  const fetchSubMerchants = () => {
    !!profilePermissions?.includes("BROWSE_SUBSIDIARY_MERCHANT") &&
      dispatch(fetchSubMerchantsFn({ page: currentPage, name: "" }));
  };

  useEffect(fetchSubMerchants, [currentPage, profilePermissions]);

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const renderRows = () => {
    const rows = subMerchantState?.data?.map((each: any) => {
      return (
        <tr
          key={each?.id}
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`/subsidiaries/${each?.id}`)}
        >
          <td>{each?.businessName}</td>
          <td>{each?.phoneNumber}</td>
          <td>&#8358;{numberWithCommas(each?.availableBalance)}</td>
          <td>{statusFn(each?.review)}</td>
          <td>{format(parseISO(each?.createdAt), "MMM dd, yyyy")}</td>
        </tr>
      );
    });

    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const renderSideContent = () => {
    if (sideContent === "createSubMerchant") {
      return (
        <CreateSubMerchantSection
          loading={createMerchantState.loading}
          onSubmit={(values) => {
            dispatch(
              createSubMerchantAccountFn(values, () => {
                fetchSubMerchants();
                closeModal();
              })
            );
          }}
        />
      );
    } else {
      return null;
    }
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <DashboardLayout>
        {subMerchantState.loading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>
                Agents
                {!!profilePermissions?.includes(
                  "MANAGE_SUBSIDIARY_MERCHANT"
                ) && (
                  <>
                    <img
                      alt="Customer"
                      className="ml-2"
                      src={AddWalletImg}
                      style={{ cursor: "pointer" }}
                      onClick={() => openModal("createSubMerchant")}
                      data-tip="Create Agents"
                      data-for="createSubsidiary"
                    />
                    <ReactTooltip id="createSubsidiary" place="right" />
                  </>
                )}
              </Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of agents
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {subMerchantState?.meta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(getCustomersFn({ search: searchValue }));
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
              </form>
              <Table
                noData={subMerchantState?.data?.length === 0 ? true : false}
                headers={[
                  "MERCHANT NAME",
                  "PHONE NO",
                  "BALANCE",
                  "STATUS",
                  "DATE",
                ]}
              >
                {renderRows()}
              </Table>

              {subMerchantState?.meta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    pageCount={subMerchantState?.meta?.totalPages}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default CustomersScreen;
