import React from "react";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import store from "./store";
import Routes from "./routing";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

export const dispatch = store.dispatch;
export const selector = store.getState;

function App() {
  return (
    <Provider store={store}>
      <ToastContainer autoClose={5000} />
      <Routes />
    </Provider>
  );
}

export default App;
