import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./CardDetails.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import request from "constants/requests";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import CardListDetails from "../CardListDetails/CardListDetails";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import { PrimaryButton } from "components/Button/Button";
import config from "../../../../../constants/api";
import BankLayout from "layouts/Bank/Bank";

interface ICardDetails {
  id: string;
  batchNumber: string;
  maskedPan: string;
  cardholder: string;
  status: string;
  expiry: string;
  accountNumber: string;
  isCardBlocked: boolean;
  pinSet: boolean;
}

interface ICardResponse {
  status: boolean;
  responseCode: string;
  size: number;
  page: number;
  totalCount: number;
  totalPages: number;
  data: ICardDetails[];
}

const STATUS_OBJ = {
  ACTIVE: { label: "Active", bgColor: "#FBB900" },
  INACTIVE: { label: "Inactive", bgColor: "red" },
};

const perPage = 50;

const BankCardDetailsScreen: React.FC = () => {
  const { id } = useParams() as Record<string, string | undefined>;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState<ICardDetails[]>([]);
  const [loading, setLoading] = useState("loading");
  const [metaData, setMetaData] = useState({ totalRecords: 0, totalPage: 1 });
  const [error, setError] = useState("");
  const [currentItems, setCurrentItems] = useState<ICardDetails[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedCard, setSelectedCard] = useState<ICardDetails>(null);
  const [sideContent, setSideContent] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setCurrentItems(cards?.slice(itemOffset, endOffset));
  }, [itemOffset, cards]);

  const renderRows = () => {
    return currentItems?.map((each, index: number) => {
      return currentItems?.length ? (
        <tr
          key={index}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedCard(each);
            openModal("details");
          }}
        >
          <td
            style={{
              display: "flex",
              alignItems: "center",
              width: "17%",
              wordWrap: "break-word",
            }}
          >
            {each?.maskedPan || "-"}
          </td>
          <td style={{ width: "19%", wordWrap: "break-word" }}>
            {each?.cardholder}
          </td>
          <td className={styles.descriptionRow} style={{ width: "10%" }}>
            {each?.expiry
              ? `${each?.expiry?.slice(2)}/${each?.expiry?.slice(0, 2)}`
              : "-"}
          </td>
          <td style={{ width: "15%", wordWrap: "break-word" }}>
            {each?.accountNumber || "-"}
          </td>
          <td style={{ width: "12%" }}>{each?.pinSet ? "Yes" : "No"}</td>
          <td style={{ width: "12%" }}>
            {each?.status ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    backgroundColor:
                      STATUS_OBJ[each?.status?.toUpperCase()]?.bgColor,
                  }}
                />
                <p style={{ flex: 1, margin: 0 }}>
                  {STATUS_OBJ[each?.status?.toUpperCase()]?.label}
                </p>
              </div>
            ) : (
              "-"
            )}
          </td>
          <td style={{ width: "15%" }}>{each?.isCardBlocked ? "Yes" : "No"}</td>
        </tr>
      ) : (
        <tr>
          <td colSpan={7} className="text-center">
            No Card Available
          </td>
        </tr>
      );
    });
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data?.selected;
    const newOffset = (selected * perPage) % cards.length;

    setItemOffset(newOffset);
    setCurrentPage(selected + 1);
  };

  const getCardDetails = async () => {
    setError("");

    const params = { currentPage, perPage };

    try {
      const { data }: { data: ICardResponse } = await request({
        method: "get",
        url: `/fip/batch/${id}`,
        params,
      });
      setCards(data?.data);
      setMetaData({
        totalRecords: 0,
        totalPage: 1,
      });
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
    } finally {
      setLoading("");
    }
  };

  const renderSideContent = () => {
    if (sideContent === "details") {
      return (
        <CardListDetails
          card={selectedCard}
          closeModalFn={closeModal}
          refetch={() => {
            setLoading("fetching");
            getCardDetails();
          }}
          showModal={modalIsOpen}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (id) {
      getCardDetails();
    }
  }, [id, currentPage]);

  const downloadCardBatchRecords = async () => {
    setDownloading(true);

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/fip/batch/${id}/download`;

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "batch-records.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        setDownloading(false);
      };
      httpClient.send();
    } catch (error) {
      setDownloading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <>
      <BankLayout>
        <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
        <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
          {renderSideContent()}
        </SideContent>
        {loading === "loading" ? (
          <Loading isSpaced />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Cards</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Cards
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {cards?.length || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <div
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <PrimaryButton
                  title={
                    downloading ? "Downloading..." : "Download Batch Records"
                  }
                  onClick={downloadCardBatchRecords}
                  disabled={downloading}
                />
              </div>
              <Table
                headers={[
                  "Pan",
                  "card holder",
                  "expiry",
                  "account number",
                  "Is pin set",
                  "status",
                  "Is card blocked",
                ]}
                widths={["17%", "19%", "10%", "15%", "12%", "12%", "15%"]}
                noData={
                  cards?.length === 0 && !loading && !error ? true : false
                }
              >
                {loading === "fetching" ? (
                  <tr className="bg-white">
                    <td colSpan={7} className="text-center">
                      <Loading isSpaced />
                    </td>
                  </tr>
                ) : error ? (
                  <tr className="bg-white">
                    <td colSpan={7} className="text-center">
                      <div
                        style={{
                          padding: "50px 20px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          rowGap: "5px",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          Unable to get card records
                        </p>
                        <button
                          style={{
                            padding: "10px 30px",
                            borderRadius: "5px",
                            outline: "none",
                            border: 0,
                            backgroundColor: "var(--primary-color)",
                            color: "white",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setLoading("fetching");
                            getCardDetails();
                          }}
                        >
                          Try Again
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {metaData?.totalPage > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    forcePage={currentPage - 1}
                    pageCount={metaData?.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default BankCardDetailsScreen;
