import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "components/Loading/Loading";
import styles from "./Transaction.module.css";
import { numberWithCommas } from "utils/helpers";
import AmountModal from "../AmountModal/AmountModal";
import ModalBackground from "layouts/Bank/components/ModalBackground/ModalBackground";
import { debitWalletFn, creditWalletFn, getSingleCustomerFn } from "slices/customersSlice";

const CustomerDetail = ({ transaction, closeModalFn }) => {
    const enumCategory = {
        CARD_TOPUP: "Card Topup",
        BANK_TRANSFER: "Bank Transfer",
        AIRTIME_PURCHASE: "Airtime Purchase",
        BVN_VERIFICATION: "BVN Verification",
        WALLET_RESERVATION: "Wallet Reservation",
        DEBIT_CUSTOMER_WALLET: "Debit Customer Wallet",
        CREDIT_CUSTOMER_WALLET: "Credit Customer Wallet",
        WALLET_FUNDED_THROUGH_BANK_TRANSFER: "Wallet Funded Through Bank Transfer",
        MERCHANT_WALLET_SETTLEMENT: "Merchant Wallet Settlement",
        WALLET_CREDITED_BY_MERCHANT: "Wallet Credited By Merchant",
    };

    const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    const dispatch = useDispatch();

    const debitWallet = (values: { amount: string }) => {
        dispatch(
            debitWalletFn(
                {
                    amount: values.amount,
                    customerId: transaction?.data?.customerId,
                    reference: Date.now(),
                },
                (customerId) => {
                    dispatch(getSingleCustomerFn(customerId));
                    closeModal();
                }
            )
        );
    };

    const creditWallet = (values: { amount: string }) => {
        dispatch(
            creditWalletFn(
                {
                    amount: values.amount,
                    customerId: transaction?.data?.customerId,
                    reference: Date.now(),
                },
                (customerId) => {
                    dispatch(getSingleCustomerFn(customerId));
                    closeModal();
                }
            )
        );
    };

    const closeModal = () => {
        setAmountModalIsOpen(false);
        setModalContent("");
    };

    const renderModalContent = () => {
        if (modalContent === "creditWallet") {
            return <AmountModal modalIsOpen={amountModalIsOpen} closeModalFn={closeModal} submitForm={creditWallet} />;
        } else if (modalContent === "debitWallet") {
            return <AmountModal modalIsOpen={amountModalIsOpen} closeModalFn={closeModal} submitForm={debitWallet} />;
        }
    };

    const {
        type,
        status,
        amount,
        category,
        currency,
        description,
        reference,
        createdAt,
        balance_before,
        balance_after,
        metadata,
    } = transaction.data;

    if (transaction.loading) {
        return <Loading />;
    }

    return (
        <div>
            <ModalBackground modalIsOpen={amountModalIsOpen} closeModalFn={closeModal} />
            {renderModalContent()}
            <div>
                <p className={styles.customerDetail__fullName}>Transaction Details</p>
            </div>

            <table style={{ tableLayout: "fixed" }} className="table table-striped table-borderless mt-4">
                <tbody>
                    <tr>
                        <td>Status</td>
                        <td align="right">{status}</td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td align="right">{type}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td align="right">&#8358;{numberWithCommas(amount)}</td>
                    </tr>
                    <tr>
                        <td>Customer Balance</td>
                        <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
                    </tr>
                    <tr>
                        <td>Balance Before</td>
                        <td align="right">&#8358;{numberWithCommas(balance_before)}</td>
                    </tr>
                    <tr>
                        <td>Balance After</td>
                        <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td align="right">{enumCategory[category]}</td>
                    </tr>
                    {metadata?.narration ? (
                        <tr>
                            <td>Narration</td>
                            <td align="right">{metadata?.narration}</td>
                        </tr>
                    ) : (
                        <tr>
                            <td>Description</td>
                            <td align="right">{description}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Currency</td>
                        <td align="right">{currency}</td>
                    </tr>
                    <tr>
                        <td>Reference</td>
                        <td align="right" style={{ wordWrap: "break-word" }}>
                            {reference}
                        </td>
                    </tr>
                    <tr>
                        <td>Created At</td>
                        <td align="right">{createdAt}</td>
                    </tr>
                    {/* <tr>
            <td>Reference</td>
            <td align="right">{accountReference}</td>
          </tr> */}
                </tbody>
            </table>
        </div>
    );
};

export default CustomerDetail;
