export default {
  LOGIN: "/",
  KYC: "/kyc",
  HOME: "/home",
  REGISTER: "/register",
  SETTINGS: "/settings",
  CUSTOMERS: "/customers",
  SEND_MONEY: "/send-money",
  VERIFICATION: "/verification",
  TRANSACTIONS: "/transactions",
  PROFILE: "/settings/profile",
  SECURITY: "/settings/security",
  ACCOUNT_VERIFICATION: "/settings/account-verification",
  NOTIFICATIONS: "/settings/notifications",
  DEVELOPER_KEYS: "/settings/developer-keys",
  VIA_WALLET: "/send-money/via-wallet",
  VIA_BANK: "/send-money/via-bank",
  ACCOUNT_REVIEW: "/account-review",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  VIA_MOBILE_MONEY: "/send-money/via-mobile-money",
  ROLES: "/roles",
  TEAM: "/team",
  ACCEPT_INVITATION: "/accept-invitation",
  TRANSACTION_HISTORY: "/transactions/history",
  CARDS: "/cards",
  CARDDETAILS: "/cards/:id",

  PARTNER_HOME: "/partner/home",
  PARTNERMERCHANT: "/partner/merchants",
  PARTNERTRANSACTION: "/partner/transactions",
  PARTNER_SUBSIDIARY_MERCHANT: "/partner/merchant/subsidiary",

  CARD_HOLDERS: "/card-holders",
  ACTIVATED_CARDS: "/activated-cards",

  BANK_HOME: "/bank/home",
  BANK_REPORT: "/bank/report",
  BANK_MERCHANT: "/bank/merchants",
  BANK_TRANSACTION: "/bank/transactions",
  BANK_PARTNER: "/bank/partners",
  BANK_CUSTOMER: "/bank/customers",
  BANK_NIP_SETTINGS: "/bank/nip-settings",
  BANK_CASH_LOAD: "/bank/cash-load",
  BANK_CASH_UNLOAD: "/bank/cash-unload",
  BANK_AUDIT_TRAIL: "/bank/audit-trail",
  BANK_SETTINGS: "/bank/settings",
  BANK_AGENTS: "/bank/agents",
  BANK_ROLES: "/bank/roles",
  BANK_ADMINS: "/bank/admins",
  BANK_CARDS: "/bank/cards",
  BANK_CARDDETAILS: "/bank/cards/:id",

  SUBSIDIARIES: "/subsidiaries",
};
