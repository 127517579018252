import React, { useState } from "react";
import BankDetails from "components/BankDetails/BankDetails";
import Loading from "components/Loading/Loading";
import styles from "./CustomerDetail.module.css";
import { OutlineButton, PrimaryButton } from "components/Button/Button";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import AmountModal from "../AmountModal/AmountModal";
import TransferModal from "../../../Customers/components/Transfer/Transfer";
import {
  debitWalletFn,
  creditWalletFn,
  getSingleCustomerFn,
  freezeWalletFn,
  unfreezeWalletFn,
  selectFreezeCustomer,
  upgradeCustomerTierFn,
  selectUpgradeCustomerTier,
} from "slices/customersSlice";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "utils/helpers";
import format from "date-fns/format";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import UpdateBVNModal from "screens/Merchant/Customers/components/UpdateBVN/UpdateBVN";
import PinSelectionModal from "../PinSelection/PinSelection";

export interface CustomerDetailProps {
  customer: {
    loading: boolean;
    data: any;
    error: any;
  };
  closeModalFn?: () => void | undefined;
  permissions?: string[];
}

const TIER_OBJ = {
  TIER_1: "Tier 1",
  TIER_2: "Tier 2",
  TIER_3: "Tier 3",
};

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  customer,
  closeModalFn,
  permissions,
}) => {
  const [amountModalIsOpen, setAmountModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showReason, setShowReason] = useState(false);
  const freezeWalletState = useSelector(selectFreezeCustomer);
  const upgradeTierState = useSelector(selectUpgradeCustomerTier);

  const dispatch = useDispatch();

  const debitWallet = (values: { amount: string }) => {
    dispatch(
      debitWalletFn(
        {
          amount: values.amount,
          customerId: customer?.data?.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const creditWallet = (values: { amount: string }) => {
    dispatch(
      creditWalletFn(
        {
          amount: values.amount,
          customerId: customer?.data?.customerId,
          reference: Date.now(),
        },
        (customerId) => {
          dispatch(getSingleCustomerFn(customerId));
          closeModal();
        }
      )
    );
  };

  const openModal = (content: string) => {
    setModalContent(content);
    setAmountModalIsOpen(true);
  };

  const closeModal = () => {
    setAmountModalIsOpen(false);
    setModalContent("");
  };

  const {
    email,
    status,
    bookedBalance,
    availableBalance,
    accountName,
    accountNumber,
    bankName,
    lastName,
    firstName,
    customerId,
    accountReference,
    phoneNumber,
    createdAt,
    reason,
    tier,
    isBVNVerificationRequired,
    isNINVerified,
  } = customer?.data;

  const renderModalContent = () => {
    if (modalContent === "creditWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={creditWallet}
          status="Credit"
        />
      );
    } else if (modalContent === "debitWallet") {
      return (
        <AmountModal
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          submitForm={debitWallet}
          status="Debit"
        />
      );
    } else if (modalContent === "transfer") {
      return <TransferModal customer={customerId} />;
    } else if (modalContent === "update bvn") {
      return (
        <UpdateBVNModal customerData={customer?.data} closeModal={closeModal} />
      );
    } else if (modalContent === "pin selection") {
      return (
        <PinSelectionModal
          customerData={customer?.data}
          closeModal={closeModal}
          refetch={() => {
            dispatch(getSingleCustomerFn(customerId));
          }}
        />
      );
    }
  };

  const renderWalletActivation = ({
    customerId,
    status,
  }: {
    customerId: string;
    status: string;
  }) => {
    if (status === "FROZEN" && (isNINVerified || !isBVNVerificationRequired)) {
      return (
        <button
          onClick={() => {
            dispatch(
              unfreezeWalletFn(customerId, () => {
                if (closeModalFn) closeModalFn();
              })
            );
          }}
          type="button"
          className="btn btn-light btn-block"
          style={{ border: "1px solid #384C55" }}
        >
          Unfreeze Account
        </button>
      );
    } else if (
      status === "ACTIVE" &&
      tier === "TIER_1" &&
      isNINVerified &&
      !isBVNVerificationRequired
    ) {
      return (
        <button
          onClick={() => {
            dispatch(
              upgradeCustomerTierFn({
                payload: { customerId: customerId },
                cb: () => {
                  dispatch(getSingleCustomerFn(customerId));
                },
              })
            );
          }}
          type="button"
          className="btn btn-light btn-block"
          style={{ border: "1px solid #384C55" }}
          disabled={upgradeTierState?.loading}
        >
          {upgradeTierState?.loading ? "Upgrading..." : "Upgrade to Tier 2"}
        </button>
      );
    } else if (
      status === "ACTIVE" &&
      tier === "TIER_2" &&
      isNINVerified &&
      !isBVNVerificationRequired
    ) {
      return (
        <button
          onClick={() => {
            dispatch(
              upgradeCustomerTierFn({
                payload: { customerId: customerId },
                cb: () => {
                  dispatch(getSingleCustomerFn(customerId));
                },
              })
            );
          }}
          type="button"
          className="btn btn-light btn-block"
          style={{ border: "1px solid #384C55" }}
          disabled={upgradeTierState?.loading}
        >
          {upgradeTierState?.loading ? "Upgrading..." : "Upgrade to Tier 3"}
        </button>
      );
    } else if (
      status === "ACTIVE" &&
      tier === "TIER_3" &&
      isNINVerified &&
      !isBVNVerificationRequired
    ) {
      return null;
    } else {
      return (
        <button
          onClick={() => {
            openModal("update bvn");
          }}
          type="button"
          className="btn btn-light btn-block"
          style={{ border: "1px solid #384C55" }}
        >
          {isBVNVerificationRequired && !isNINVerified
            ? "Update BVN/NIN"
            : isBVNVerificationRequired
            ? "Update BVN"
            : "Update NIN"}
        </button>
      );
    }
  };

  if (customer.loading) {
    return <Loading />;
  }

  return showReason ? (
    <div className={styles.reason__container}>
      <h2>Reason</h2>
      <Formik
        initialValues={{ reason: "" }}
        validationSchema={object().shape({
          reason: string().required("Reason is required"),
        })}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            freezeWalletFn(customerId, values?.reason, () => {
              closeModalFn();
              setShowReason(false);
              resetForm();
            })
          );
        }}
      >
        {() => (
          <Form className={styles.form__container}>
            <Field
              type="text"
              as="textarea"
              name="reason"
              className={styles.input}
            />
            <ErrorMessage
              name="reason"
              className={styles.error}
              component="p"
            />
            <div className={styles.btn__container}>
              {freezeWalletState?.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <PrimaryButton
                  title="Submit"
                  type="submit"
                  className={styles.submit}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div>
      <ModalBackground
        modalIsOpen={amountModalIsOpen}
        closeModalFn={closeModal}
      />
      {modalContent === "transfer" ? (
        <SideContent modalIsOpen={amountModalIsOpen} closeModalFn={closeModal}>
          {renderModalContent()}
        </SideContent>
      ) : modalContent === "update bvn" ? (
        <SideContent modalIsOpen={amountModalIsOpen} closeModalFn={closeModal}>
          {renderModalContent()}
        </SideContent>
      ) : modalContent === "pin selection" ? (
        <SideContent modalIsOpen={amountModalIsOpen} closeModalFn={closeModal}>
          {renderModalContent()}
        </SideContent>
      ) : (
        <SideContent
          modalIsOpen={amountModalIsOpen}
          closeModalFn={closeModal}
          upperModal
        >
          {renderModalContent()}
        </SideContent>
      )}
      <div>
        <p className={styles.customerDetail__fullName}>
          {lastName} {firstName}
        </p>
        <p className={styles.customerDetail__email}>{email}</p>
      </div>
      <BankDetails
        accountName={accountName}
        accountNumber={accountNumber}
        bankName={bankName}
      />

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Status</td>
            <td align="right">{status}</td>
          </tr>
          <tr>
            <td>Book Balance</td>
            <td align="right">&#8358;{numberWithCommas(bookedBalance)}</td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td align="right">&#8358;{numberWithCommas(availableBalance)}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td align="right">{phoneNumber}</td>
          </tr>
          <tr>
            <td>Tier</td>
            <td align="right">{TIER_OBJ[tier]}</td>
          </tr>
          {!!reason && (
            <tr>
              <td>Reason</td>
              <td align="right">{reason}</td>
            </tr>
          )}
          <tr>
            <td>Reference</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {accountReference}
            </td>
          </tr>
          <tr>
            <td>Created At</td>
            <td align="right">{format(new Date(createdAt), "MMM dd, yyyy")}</td>
          </tr>
        </tbody>
      </table>

      {!!permissions?.includes("WALLET_CREDIT_DEBIT") && (
        <OutlineButton
          onClick={() => openModal("creditWallet")}
          block
          title="Credit Acount"
        />
      )}
      {!!permissions?.includes("WALLET_CREDIT_DEBIT") && (
        <OutlineButton
          onClick={() => openModal("debitWallet")}
          block
          title="Debit Account"
        />
      )}
      {!!permissions?.includes("BROWSE_TRANSACTIONS") && (
        <OutlineButton
          link={`/customers/transactions/${customerId}`}
          block
          title="View Transactions"
        />
      )}
      {!!permissions?.includes("MANAGE_TRANSFER") && (
        <OutlineButton
          onClick={() => openModal("transfer")}
          block
          title="Transfer"
        />
      )}
      {!!permissions?.includes("UPDATE_CUSTOMERS") && (
        <OutlineButton
          onClick={() => openModal("pin selection")}
          block
          title="Pin Selection"
        />
      )}
      {!!permissions?.includes("ENABLE_DISABLE_WALLET") &&
        renderWalletActivation({
          customerId,
          status,
        })}
      {!!permissions?.includes("ENABLE_DISABLE_WALLET") &&
        status === "ACTIVE" && (
          <button
            onClick={() => {
              setShowReason(true);
            }}
            type="button"
            className="btn btn-light btn-block"
            style={{ border: "1px solid red" }}
          >
            Freeze Account
          </button>
        )}
    </div>
  );
};

export default CustomerDetail;
