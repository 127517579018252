import React, { Fragment } from "react";
import { object, string, array } from "yup";
import { Field, Form, Formik } from "formik";

import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import styles from "./UpdateRole.module.css";

const validationSchema = object().shape({
  role: string().required("Role is required"),
  permissions: array()
    .required("Permission is required")
    .min(1, "Permissions must have atleast one value"),
});

const UpdateRole: React.FC<{
  loading: boolean;
  onSubmit: any;
  content: any;
  permissions: any[];
}> = ({ loading, onSubmit, content, permissions }) => {
  return (
    <div>
      <h2 className="mb-5">Update Role</h2>
      <Formik
        onSubmit={(values) =>
          onSubmit({
            id: content?.id,
            name: values.role,
            permissions: values.permissions,
          })
        }
        validationSchema={validationSchema}
        initialValues={{
          role: content?.name || "",
          permissions: content?.permissions || [],
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Field
                name="role"
                placeholder="Role*"
                type="text"
                component={TextInput}
              />
              <div className="my-3">
                <p className="my-2 font-weight-bold">Permissions:</p>
                {permissions
                  ?.filter(
                    (each) =>
                      [
                        "MANAGE_BALANCE_SETTLEMENT",
                        "SET_TRANSACTION_PIN",
                        "MANAGE_AIRTIME",
                        "MANAGE_PREPAID_CARD",
                      ].includes(each?.name) === false
                  )
                  .map((each) => (
                    <div className={styles.input__container} key={each?.name}>
                      <input
                        name="role"
                        type="checkbox"
                        className="mt-1"
                        value={each?.name}
                        checked={values.permissions.includes(each?.name)}
                        onChange={(e) => {
                          if (values.permissions.includes(e.target.value)) {
                            setFieldValue(
                              "permissions",
                              values.permissions.filter(
                                (data) => data !== e.target.value
                              )
                            );
                          } else {
                            setFieldValue("permissions", [
                              ...values.permissions,
                              e.target.value,
                            ]);
                          }
                        }}
                      />{" "}
                      <span className="ml-2">{each?.description}</span>
                    </div>
                  ))}
              </div>

              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <PrimaryButton type="submit" block size="lg" title="Update" />
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateRole;
