import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import { numberWithCommas } from "utils/helpers";
import PartnerLayout from "layouts/Partner/Partner";
import DownArrow from "assets/images/down-arrow.svg";
import TransactionDetail from "./components/TransactionDetail";
import { CATEGORY_LIST, CATEGORY_DESCRIPTION } from "constants/transaction";
import SideContent from "layouts/Partner/components/SideContent/SideContent";
import ModalBackground from "layouts/Partner/components/ModalBackground/ModalBackground";
import {
    fetchTransactionFn,
    selectPartnerModeState,
    getTransactionByFilterFn,
    selectActiveMerchantState,
    selectPartnerTransactionState,
} from "slices/partnerSlice";

import styles from "./Transaction.module.css";

const Transaction = () => {
    const timer: any = useRef();
    const startTrackingSearch = useRef(false);
    const dispatch = useDispatch();

    const transaction = useSelector(selectPartnerTransactionState);
    const activeMerchant = useSelector(selectActiveMerchantState);

    const partnerMode = useSelector(selectPartnerModeState);

    const [sideContent, setSideContent] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [partnerTransaction, setPartnerTransaction] = useState(null);
    const [currentPage, setCurrentPage] = useState(transaction?.meta?.page || 1);
    const [filterInputs, setFilterInputs] = useState({
        type: "ALL",
        status: undefined,
        category: undefined,
    });

    useEffect(() => {
        dispatch(fetchTransactionFn({ page: currentPage, mode: partnerMode, activeMerchant }));
    }, [dispatch, currentPage, partnerMode, activeMerchant]);

    useEffect(() => {
        if (!startTrackingSearch.current) {
            startTrackingSearch.current = true;
        } else {
            if (timer.current) {
                clearTimeout(timer.current);

                timer.current = setTimeout(() => {
                    filterRequest();
                }, 500);
            } else {
                timer.current = setTimeout(() => {
                    filterRequest();
                }, 500);
            }

            return () => {
                clearTimeout(timer.current);
            };
        }

        function filterRequest() {
            const { type, category, status } = filterInputs;

            dispatch(
                getTransactionByFilterFn({
                    type,
                    status,
                    category,
                    mode: partnerMode,
                    reference: searchValue,
                    merchantId: activeMerchant?.id,
                })
            );
        }

        // eslint-disable-next-line
    }, [searchValue, dispatch, activeMerchant]);

    const renderRows = () => {
        const rows = transaction?.data?.map((each, index) => {
            return (
                <tr style={{ cursor: "pointer" }} key={index} onClick={() => viewSingleTransaction(each)}>
                    <td style={{ display: "flex", alignItems: "center" }}>
                        <span
                            style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                                display: "inline-block",
                                backgroundColor:
                                    each?.status?.toLowerCase() === "success"
                                        ? each?.status?.toLowerCase() === "pending"
                                            ? "#FBB900"
                                            : "green"
                                        : "red",
                            }}
                        />
                        &nbsp;&nbsp;&#8358;{numberWithCommas(each?.amount, 0)}
                    </td>
                    <td>{each?.type}</td>
                    <td>{each?.currency}</td>
                    <td>{each?.reference}</td>
                    <td>{CATEGORY_DESCRIPTION[each?.category]}</td>
                </tr>
            );
        });

        return rows;
    };

    const handlePageClick = (data: { selected }) => {
        let selected = data.selected;
        const currentPage = selected + 1;
        setCurrentPage(currentPage);
    };

    const viewSingleTransaction = (data) => {
        setPartnerTransaction(data);
        openModal("transactionDetail");
    };

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const renderSideContent = () => {
        if (sideContent === "transactionDetail") {
            return <TransactionDetail transaction={{ data: partnerTransaction }} />;
        } else {
            return null;
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        setFilterInputs({ ...filterInputs, [name]: value });
    };

    const applyFilter = (e) => {
        e.preventDefault();
        const { type, category, status } = filterInputs;

        dispatch(
            getTransactionByFilterFn({
                type,
                status,
                category,
                mode: partnerMode,
                reference: searchValue,
                merchantId: activeMerchant ? activeMerchant.id : undefined,
            })
        );

        setShowFilter(false);
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <PartnerLayout>
                {transaction.loading ? (
                    <Loading />
                ) : (
                    <div className={styles.partner}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>Transactions</Header>
                            <div>
                                <p className={styles.partner__totalText}>Total Numbers of Transactions</p>
                                <h4 className={styles.partner__totalAmount}>{transaction?.meta?.totalRecords || 0}</h4>
                            </div>
                        </div>
                        <div className={styles.wallet__content}>
                            <div className={styles.transaction__balanceSheet}>
                                <p className={styles.transaction__balanceSheetTitle}>Credit Transactions</p>
                                <h3 className={styles.transaction__balanceSheetAmount}>
                                    &#x20A6;{numberWithCommas(`${transaction?.info?.creditTransactions || 0}`)}
                                </h3>
                            </div>
                            <div className={styles.transaction__balanceSheet}>
                                <p className={styles.transaction__balanceSheetTitle}>Debit Transactions</p>
                                <h3 className={styles.transaction__balanceSheetAmount}>
                                    &#x20A6;{numberWithCommas(`${transaction?.info?.debitTransactions || 0}`)}
                                </h3>
                            </div>
                            <div className={styles.wallet__vl} />
                            <div className={styles.transaction__section}>
                                <p className={styles.wallet__amountTitle}>Total Transactions</p>
                                <h3 className={styles.wallet__amount}>
                                    &#x20A6;{numberWithCommas(`${transaction?.info?.totalTransactions || 0}`)}
                                </h3>
                            </div>
                        </div>

                        <div className={styles.partner__content}>
                            <form
                                onSubmit={applyFilter}
                                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                            >
                                <div className="col-auto my-3" style={{ width: "70%" }}>
                                    <label className="sr-only" htmlFor="searchTransaction">
                                        Search transaction
                                    </label>
                                    <div className="input-group mb-2" style={{ backgroundColor: "#87BD40" }}>
                                        <div className="input-group-prepend">
                                            <div
                                                style={{
                                                    borderRightColor: "transparent",
                                                    backgroundColor: "transparent",
                                                }}
                                                className="input-group-text"
                                            >
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            value={searchValue}
                                            id="searchTransaction"
                                            className="form-control"
                                            placeholder="Search transaction reference"
                                            onChange={(e) => setSearchValue(e.target.value)}
                                            style={{ borderLeftColor: "transparent", backgroundColor: "#87BD40" }}
                                        />
                                    </div>
                                </div>
                                <p
                                    style={{
                                        width: "15%",
                                        fontSize: "90%",
                                        display: "flex",
                                        cursor: "pointer",
                                        padding: "5px 15px",
                                        alignItems: "center",
                                        borderRadius: "30px",
                                        textTransform: "capitalize",
                                        justifyContent: "space-between",
                                        border: "1px solid rgba(0,0,0,.2)",
                                    }}
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <span>{filterInputs.type || "ALL"}</span>{" "}
                                    <img src={DownArrow} alt="Down Arrow" style={{ width: ".7rem", height: ".7rem" }} />
                                </p>
                            </form>

                            <div
                                className={styles.filter__container}
                                style={{ display: showFilter ? "block" : "none" }}
                            >
                                <div className={styles.filter__top}>
                                    <p>Filter</p>
                                    <button type="button" onClick={applyFilter}>
                                        Apply
                                    </button>
                                </div>
                                <div className={styles.filter__bottom}>
                                    <div className={styles.filter__type}>
                                        <p>Transaction Type</p>
                                        <select name="type" onChange={handleFilterChange}>
                                            <option value="ALL">All</option>
                                            <option value="DEBIT">Debit</option>
                                            <option value="CREDIT">Credit</option>
                                        </select>
                                    </div>
                                    <div className={styles.filter__type}>
                                        <p>Transaction Status</p>
                                        <select name="status" onChange={handleFilterChange}>
                                            <option value="">All</option>
                                            <option value="success">Success</option>
                                            <option value="failed">Failed</option>
                                        </select>
                                    </div>
                                    <div className={styles.filter__type}>
                                        <p>Transaction Category</p>
                                        <select name="category" onChange={handleFilterChange}>
                                            <option value="">All</option>
                                            {CATEGORY_LIST.map((each, index) => (
                                                <option value={each} key={index}>
                                                    {CATEGORY_DESCRIPTION[each]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <Table
                                noData={transaction?.data?.length === 0 ? true : false}
                                headers={["amount", "type", "currency", "reference", "category"]}
                            >
                                {renderRows()}
                            </Table>

                            {transaction?.meta?.totalPages > 1 ? (
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        activeClassName={"active"}
                                        pageClassName={"page-item"}
                                        nextClassName={"page-item"}
                                        onPageChange={handlePageClick}
                                        nextLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"page-link"}
                                        pageCount={transaction?.meta?.totalPages || 0}
                                    />
                                </nav>
                            ) : null}
                        </div>
                    </div>
                )}
            </PartnerLayout>
            <div
                className={styles.popup__hover}
                onClick={() => setShowFilter(false)}
                style={{ display: showFilter ? "block" : "none" }}
            />
        </>
    );
};

export default Transaction;
