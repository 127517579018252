import { toast } from "react-toastify";

export const numberWithCommas = (n: string, decimalPlaces = 2) => {
  return parseFloat(n || "0")
    .toFixed(decimalPlaces)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const handlingErrors = (error, dispatch, callback) => {
  if (error.response) {
    dispatch(callback(error?.response?.data?.message || error.message));
  } else {
    toast.error(error.message);
    dispatch(callback(error.message));
  }
};

export const handleInputValidation = (val: string) => {
  return {
    onlyNumber:
      /^[0-9]*$/.test(val) || /^-?\d+(\.\d{1,2})?$/.test(val) ? true : false,
    onlyAlphabet: /^[a-zA-Z]*$/.test(val) ? true : false,
    alphabetWithSpace: /^[a-zA-Z][a-zA-Z ]*$/.test(val) ? true : false,
    numbersWithDecimal: /^\d*\.?\d*$/.test(val) ? true : false,
  };
};

export const validateFileSize = (
  value: Record<string, any>,
  defaultSize?: number
) => {
  const size = value?.name ? value?.size : 2;
  const maxSize = defaultSize ? defaultSize * 1024 * 1024 : 20 * 1024 * 1024;

  if (size <= maxSize) {
    return true;
  }

  return false;
};
