import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

import { PrimaryButton } from "components/Button/Button";
import {selectParentMerchantsState, selectAssignMerchantState, getParentMerchantsFn, assignMerchantFn} from 'slices/bankSlice';

import styles from './AssignMerchant.module.css';

const CreatePartner = ({closeModal, partner}) => {
    const {data} = useSelector(selectParentMerchantsState);
    const {loading} = useSelector(selectAssignMerchantState);
    const dispatch = useDispatch();

    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState('');

    const fetchMainMerchant = () => {
        if (!data.length) {
            // eslint-disable-next-line
            dispatch(getParentMerchantsFn());
        } else {
            setOptions(data.map(record => ({value: record?.id, label: record?.businessName})));
        } 
    }

    useEffect(fetchMainMerchant, [data.length]);

    const validation = () => {
        let message = true;

        if (!selectedOption) {
            setError('Merchant is required');
            message = false;
        }

        return message;
    }

    const handleChange = (selected) => {
        setSelectedOption(selected);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        if (!validation()) return;

        dispatch(assignMerchantFn(partner, selectedOption.value, () => {
            setSelectedOption(null);
            closeModal();
        }))

    }

    return <div className={styles.container}>
        <h2 className="mb-5">Assign Merchant</h2>
        
        <form className={styles.formContainer} onSubmit={handleSubmit}>
            <div className={styles.selectContainer}>
                <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    className={!error ? styles.select : styles.selectError}
                    isSearchable
                    placeholder='Select Merchant'
                />
                {error && <div className={styles.error}>{error}</div>}
            </div>
            
            {loading ? (
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <PrimaryButton type="submit" block title="Assign" />
                )}
        </form>
    </div>
}

export default CreatePartner;