import React, { useState } from "react";
import Table from "components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { numberWithCommas } from "utils/helpers";
import { getTransactionFn, selectBankState } from "slices/bankSlice";
import { CATEGORY_LIST, CATEGORY_DESCRIPTION } from "constants/transaction";
import { useParams } from "react-router-dom";

import styles from "./TransactionList.module.css";
import DownArrow from "assets/images/down-arrow.svg";

const TransactionList = ({ data, openModal, setTransaction, searchUpdater, filterUpdater }) => {
    const { customer } = useParams() as Record<string, string | undefined>;
    const dispatch = useDispatch();
    const { accessMode } = useSelector(selectBankState);

    const [searchValue, setSearchValue] = searchUpdater;
    const [showFilter, setShowFilter] = useState(false);
    const [filterInputs, setFilterInputs] = filterUpdater;

    const renderTable = () =>
        data.map((record) => (
            <tr style={{ cursor: "pointer" }} key={record.id} onClick={() => viewSingleTransaction(record)}>
                <td style={{ display: "flex", alignItems: "center" }}>
                    <span
                        style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            display: "inline-block",
                            backgroundColor:
                                record?.status?.toLowerCase() === "success"
                                    ? record?.status?.toLowerCase() === "pending"
                                        ? "#FBB900"
                                        : "green"
                                    : "red",
                        }}
                    />
                    &nbsp;&nbsp;&#8358;{numberWithCommas(record?.amount)}
                </td>
                <td>{record?.type === "CREDIT" ? "Credit" : "Debit"}</td>
                <td>{record?.currency}</td>
                <td>{record?.reference}</td>
                <td>{format(parseISO(record?.createdAt), "MMM dd, yyyy p")}</td>
            </tr>
        ));

    const viewSingleTransaction = (data) => {
        setTransaction(data);
        openModal("transactionDetail");
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === "status" && value === "") {
            setFilterInputs({ ...filterInputs, status: null });
        } else {
            setFilterInputs({ ...filterInputs, [name]: value });
        }
    };

    const applyFilter = (e) => {
        e.preventDefault();
        const { type, category, status } = filterInputs;

        dispatch(
            getTransactionFn({ type, status, category, reference: searchValue, accessMode, merchantId: customer })
        );
        setShowFilter(false);
    };

    return (
        <>
            <form
                onSubmit={applyFilter}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                <div className="col-auto my-3" style={{ width: "70%" }}>
                    <label className="sr-only" htmlFor="searchTransaction">
                        Search transaction
                    </label>
                    <div className="input-group mb-2" style={{ backgroundColor: "#87BD40" }}>
                        <div className="input-group-prepend">
                            <div
                                className="input-group-text"
                                style={{
                                    borderRightColor: "transparent",
                                    backgroundColor: "transparent",
                                }}
                            >
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                        <input
                            type="text"
                            value={searchValue}
                            id="searchTransaction"
                            className="form-control"
                            placeholder="Search transaction reference"
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ borderLeftColor: "transparent", backgroundColor: "#87BD40" }}
                        />
                    </div>
                </div>
                <p
                    style={{
                        width: "15%",
                        border: "1px solid rgba(0,0,0,.2)",
                        borderRadius: "30px",
                        padding: "5px 15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        fontSize: "90%",
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                >
                    <span>{filterInputs?.type || "ALL"}</span>{" "}
                    <img src={DownArrow} alt="Down Arrow" style={{ width: ".7rem", height: ".7rem" }} />
                </p>
            </form>

            <div className={styles.filter__container} style={{ display: showFilter ? "block" : "none" }}>
                <div className={styles.filter__top}>
                    <p>Filter</p>
                    <button type="button" onClick={applyFilter}>
                        Apply
                    </button>
                </div>
                <div className={styles.filter__bottom}>
                    <div className={styles.filter__type}>
                        <p>Transaction Type</p>
                        <select name="type" value={filterInputs.type} onChange={handleFilterChange}>
                            <option value="ALL">All</option>
                            <option value="DEBIT">Debit</option>
                            <option value="CREDIT">Credit</option>
                        </select>
                    </div>
                    <div className={styles.filter__type}>
                        <p>Transaction Status</p>
                        <select name="status" value={filterInputs?.status} onChange={handleFilterChange}>
                            <option value="">All</option>
                            <option value="success">Success</option>
                            <option value="failed">Failed</option>
                        </select>
                    </div>
                    <div className={styles.filter__type}>
                        <p>Transaction Category</p>
                        <select name="category" value={filterInputs?.category} onChange={handleFilterChange}>
                            <option value="">All</option>
                            {CATEGORY_LIST.map((each, index) => (
                                <option value={each} key={index}>
                                    {CATEGORY_DESCRIPTION[each]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className={styles.tableContainer}>
                <div className="col-auto my-3"></div>
                <Table noData={data.length === 0} headers={["AMOUNT", "TYPE", "CURRENCY", "REFERENCE", "DATE"]}>
                    {renderTable()}
                </Table>
            </div>
            <div
                className={styles.popup__hover}
                onClick={() => setShowFilter(false)}
                style={{ display: showFilter ? "block" : "none" }}
            />
        </>
    );
};

export default TransactionList;
