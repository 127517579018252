import React from "react";
import Header from "components/Header/Header";

import styles from "./Merchant.module.css";
// import DottedImg from "assets/images/addWallet.svg";

const Merchant = ({ total, info }) => {
    return (
        <div className={styles.wallet}>
            <div className="mb-4 d-flex justify-content-between">
                <Header>
                    Subsidiary Merchants
                </Header>
                <div>
                    <p className={styles.totalText}>Total Numbers of Subsidiary Merchant</p>
                    <h4 className={styles.totalAmount}>{total || 0}</h4>
                </div>
            </div>
            <div className={styles.wallet__content}>
                <div className={styles.transaction__balanceSheet}>
                    <p className={styles.transaction__balanceSheetTitle}>Total Number of Subsidiary Merchants</p>
                    <h3 className={styles.transaction__balanceSheetAmount}>{info?.totalMerchants || 0}</h3>
                </div>
                <div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Active Subsidiary Merchants</p>
                    <h3 className={styles.wallet__amount}>{info?.activeMerchants || 0}</h3>
                </div>
                {/*<div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Pending Subsidiary Merchants</p>
                    <h3 className={styles.wallet__amount}>{info.pendingMerchants}</h3>
    </div>*/}
                <div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Frozen Subsidiary Merchants</p>
                    <h3 className={styles.wallet__amount}>{info?.frozenMerchants || 0}</h3>
                </div>
            </div>
        </div>
    );
};

export default Merchant;
