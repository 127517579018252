import * as React from "react";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import format from "date-fns/format";

export interface TransactionDetailProps {
    transaction: any;
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({ transaction }) => {
    if (transaction.loading) {
        return <Loading />;
    }

    const { type, amount, createdAt, reference, description, balance_after, balance_before, metadata } =
        transaction?.data;
    return (
        <div>
            <table style={{ tableLayout: "fixed" }} className="table table-striped table-borderless mt-4">
                <tbody>
                    {metadata?.narration ? (
                        <tr>
                            <td colSpan={2}>
                                <p>Narration</p>
                                <p>{metadata?.narration}</p>
                            </td>
                        </tr>
                    ) : (
                        <tr>
                            <td colSpan={2}>
                                <p>Description</p>
                                <p>{description}</p>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>Type</td>
                        <td align="right">{type}</td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td align="right">&#8358;{numberWithCommas(amount)}</td>
                    </tr>
                    <tr>
                        <td>Balance Before</td>
                        <td align="right">&#8358;{numberWithCommas(balance_before)}</td>
                    </tr>
                    <tr>
                        <td>Balance After</td>
                        <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <p>Reference</p>
                            <p>{reference}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <p>Transaction Time</p>
                            <p>{format(new Date(createdAt), "dd-MM-yyyy p")}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TransactionDetail;
