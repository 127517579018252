import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import TransactionSection from "./components/Transaction/Transaction";
import SideContent from "layouts/Bank/components/SideContent/SideContent";
import TransactionList from "./components/TransactionList/TransactionList";
import TransactionDetail from "./components/Transaction/TransactionDetail";
import ModalBackground from "layouts/Bank/components/ModalBackground/ModalBackground";

import { selectBankState, getTransactionFn } from "slices/bankSlice";
import styles from "./components/TransactionList/TransactionList.module.css";

const Transaction: React.FC = () => {
    const { customer } = useParams() as Record<string, string | undefined>;
    const dispatch = useDispatch();
    const bankState = useSelector(selectBankState);

    const { loading, data, meta } = bankState.transaction;

    const [sideContent, setSideContent] = useState("");
    const [transaction, setTransaction] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(meta ? meta.page : 1);

    const searchUpdater = useState("");
    const filterUpdater = useState({
        type: "ALL",
        status: "",
        category: "",
    });

    useEffect(() => {
        dispatch(getTransactionFn({ page: currentPage, accessMode: bankState.accessMode, merchantId: customer }));
    }, [bankState.accessMode, dispatch, currentPage, customer]);

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const handlePageClick = (data) => {
        const selected = data.selected;

        setCurrentPage(selected + 1);
    };

    const renderSideContent = () => {
        if (sideContent === "transactionDetail") {
            return <TransactionDetail transaction={{ data: transaction, loading }} />;
        } else {
            return null;
        }
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <BankLayout>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <TransactionSection />

                        <div className={styles.partner__content}>
                            <TransactionList
                                data={data}
                                openModal={openModal}
                                searchUpdater={searchUpdater}
                                filterUpdater={filterUpdater}
                                setTransaction={setTransaction}
                            />

                            {meta?.totalPages > 1 && (
                                <nav aria-label="navigation">
                                    <ReactPaginate
                                        nextLabel={"next"}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        activeClassName={"active"}
                                        previousLabel={"previous"}
                                        nextClassName={"page-item"}
                                        pageClassName={"page-item"}
                                        pageCount={meta?.totalPages}
                                        onPageChange={handlePageClick}
                                        nextLinkClassName={"page-link"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        containerClassName={"pagination"}
                                        previousLinkClassName={"page-link"}
                                    />
                                </nav>
                            )}
                        </div>
                    </>
                )}
            </BankLayout>
        </>
    );
};

export default Transaction;
