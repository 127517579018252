import React from "react";
import ReactTooltip from 'react-tooltip';

import Header from "components/Header/Header";
import AddWallet from 'assets/images/addWallet.svg';

import styles from "./PartnerHeader.module.css";

const PartnerHeader = ({ totalRecords, openModal }: { totalRecords?: number; openModal: any}) => {
    return (
        <div className={styles.wallet}>
            <div className="mb-4 d-flex justify-content-between">
                <Header>Partners  <img alt="Option" className={styles.wallet__headerIcon} src={AddWallet} onClick={() => openModal('createPartner')} data-tip='Create Partner'
                            data-for='createPartner' /><ReactTooltip id='createPartner' place="right" /></Header>
                <div>
                    <p className={styles.totalText}>Total numbers of partners</p>
                    <h4 className={styles.totalAmount}>{totalRecords ?? 0}</h4>
                </div>
            </div>
        </div>
    );
};

export default PartnerHeader;
