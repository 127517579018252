import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import PartnerLayout from "layouts/Partner/Partner";
import Loading from "components/Loading/Loading";
import Customer from "./components/Customer/Customer";
import MerchantSection from "./components/Merchant/Merchant";
import Transaction from "./components/Transaction/Transaction";
import MerchantList from "./components/MerchantList/MerchantList";
import { selectPartnerHomeState, selectPartnerModeState, getHomeRecordFn } from "slices/partnerSlice";

const HomeScreen: React.FC = () => {
    const dispatch = useDispatch();
    const homeState = useSelector(selectPartnerHomeState);
    const partnerMode = useSelector(selectPartnerModeState);

    const { loading, merchant, customer, transaction, merchantList } = homeState;

    const fetchData = () => {
        dispatch(getHomeRecordFn({ mode: partnerMode }));
    };

    useEffect(fetchData, [partnerMode]);

    return (
        <PartnerLayout>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Transaction data={transaction} />

                    <MerchantSection data={merchant} />

                    <Customer data={customer} />

                    <MerchantList data={merchantList} />
                </>
            )}
        </PartnerLayout>
    );
};

export default HomeScreen;
