import React, { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import styles from "./Settings.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import {
  selectNipStatusCodes,
  getNipStatusCodeFn,
  updateNipStatusCodeFn,
  selectBankAccessModeState,
} from "slices/bankSlice";

const NIPSettingsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const nipStatusCodes = useSelector(selectNipStatusCodes);
  const modeState = useSelector(selectBankAccessModeState);

  const fetchCustomers = () => {
    dispatch(getNipStatusCodeFn());
  };

  useEffect(fetchCustomers, [modeState]);

  const renderRows = () => {
    const rows = nipStatusCodes?.data?.map((each: any) => {
      return (
        <tr key={each?.id}>
          <td>{each?.responseCode}</td>
          <td>{each?.responseDescription}</td>
          <td>{format(parseISO(each?.updatedAt), "dd MMMM, yyyy")}</td>
          <td style={{ textAlign: "center" }}>
            <div
              className={classNames(
                styles.animation,
                styles.switch,
                each?.applyAutoReversal === 1
                  ? styles.switchedOn
                  : styles.switchedOff
              )}
              onClick={() =>
                dispatch(
                  updateNipStatusCodeFn({
                    responseCode: each?.responseCode,
                    applyAutoReversal: each?.applyAutoReversal === 1 ? 0 : 1,
                    callback: () => dispatch(getNipStatusCodeFn(true)),
                  })
                )
              }
            >
              <div className={classNames(styles.bulb, styles.animation)} />
            </div>
          </td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <BankLayout>
      {nipStatusCodes?.loading ? (
        <Loading />
      ) : (
        <div className={styles.customers}>
          <div className="mb-4 d-flex justify-content-between">
            <Header>NIP Reversal Settings</Header>
            <div>
              <p className={styles.customers__totalText}>
                Total Numbers of status
              </p>
              <h4 className={styles.customers__totalAmount}>
                {nipStatusCodes?.data?.length || 0}
              </h4>
            </div>
          </div>

          <div className={styles.customers__content}>
            <Table
              noData={nipStatusCodes?.data?.length === 0 ? true : false}
              headers={[
                "response code",
                "description",
                "last updated",
                "Reverse transaction",
              ]}
            >
              {renderRows()}
            </Table>
          </div>
        </div>
      )}
    </BankLayout>
  );
};

export default NIPSettingsScreen;
