import React from "react";
import { numberWithCommas } from "utils/helpers";

import styles from "./Transaction.module.css";

const Transaction = ({ data }) => {
    return (
        <div className={styles.wallet__content}>
            <div className={styles.transaction__balanceSheet}>
                <p className={styles.transaction__balanceSheetTitle}>Balance Sheet</p>
                <h3 className={styles.transaction__balanceSheetAmount}>
                    &#x20A6;{numberWithCommas(`${data?.balanceSheet || 0}`)}
                </h3>
            </div>
            <div className={styles.wallet__vl} />
            <div className={styles.transaction__section}>
                <p className={styles.wallet__amountTitle}>Pending Settlement</p>
                <h3 className={styles.wallet__amount}>&#x20A6;{numberWithCommas(`${data?.pendingSettlement || 0}`)}</h3>
            </div>
            <div className={styles.wallet__vl} />
            <div className={styles.transaction__section}>
                <p className={styles.wallet__amountTitle}>Total Transactions</p>
                <h3 className={styles.wallet__amount}>
                    &#x20A6;
                    {numberWithCommas(
                        `${
                            data?.creditTransactions || data?.debitTransactions
                                ? Number(data?.creditTransactions) + Number(data?.debitTransactions)
                                : 0
                        }`
                    )}
                </h3>
            </div>
        </div>
    );
};

export default Transaction;
