import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import PartnerLayout from "layouts/Partner/Partner";
import { selectPartnerState, getMerchantActivatedCardFn } from "slices/partnerSlice";

import styles from "./PartnerActivatedCard.module.css";

const PartnerActivatedCardScreen = () => {
    const dispatch = useDispatch();
    const partnerState = useSelector(selectPartnerState);

    const { activatedCard, accessMode, activeMerchant } = partnerState;

    const [currentPage, setCurrentPage] = useState(activatedCard?.meta?.page);

    const fetchMerchantCardHolders = () => {
        if (activeMerchant) {
            dispatch(getMerchantActivatedCardFn({ page: currentPage }));
        }
    };

    useEffect(fetchMerchantCardHolders, [accessMode, activeMerchant, currentPage]);

    const renderRows = () => {
        const rows = activatedCard?.data?.map((each) => (
            <tr style={{ cursor: "pointer" }} key={each?.id}>
                <td>{each?.firstName}</td>
                <td>{each?.lastName}</td>
                <td>{each?.email}</td>
                <td>{each?.phoneNumber}</td>
                <td>{each?.last6}</td>
            </tr>
        ));

        return rows;
    };

    const handlePageClick = (data) => {
        let selected = data.selected;
        const presentPage = selected + 1;
        setCurrentPage(presentPage);
    };

    return (
        <>
            <PartnerLayout>
                {!activeMerchant ? (
                    <div className={styles.partner}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>Activated Cards</Header>
                        </div>

                        <div className={styles.partner__content}>You do not have any active merchant selected.</div>
                    </div>
                ) : activatedCard?.loading ? (
                    <Loading />
                ) : (
                    <div className={styles.partner}>
                        <div className="mb-4 d-flex justify-content-between">
                            <Header>Activated Cards</Header>
                            <div>
                                <p className={styles.partner__totalText}>Total Numbers of Cards</p>
                                <h4 className={styles.partner__totalAmount}>{activatedCard?.meta?.totalRecords || 0}</h4>
                            </div>
                        </div>

                        <div className={styles.partner__content}>
                            <Table
                                noData={activatedCard?.data?.length === 0 ? true : false}
                                headers={["First name", "Last Name", "email", "Phone Number", "Last 6"]}
                            >
                                {renderRows()}
                            </Table>

                            {activatedCard?.meta?.totalPages > 1 ? (
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        pageCount={activatedCard?.meta?.totalPages || 0}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                    />
                                </nav>
                            ) : null}
                        </div>
                    </div>
                )}
            </PartnerLayout>
        </>
    );
};

export default PartnerActivatedCardScreen;
