import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import styles from "./Settings.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import {
  selectSettings,
  selectUpdateSettings,
  updateSettingsFn,
  getSettingsFn,
  selectBankAccessModeState,
} from "slices/bankSlice";
import TextInput from "components/TextInput/TextInput";
import { OutlineButton } from "components/Button/Button";

const SettingsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(selectSettings);
  const { loading: updateLoading } = useSelector(selectUpdateSettings);
  const modeState = useSelector(selectBankAccessModeState);

  const fetchSettings = () => {
    !updateLoading && dispatch(getSettingsFn());
  };

  useEffect(fetchSettings, [modeState]);

  return (
    <BankLayout>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.customers}>
          <div className="mb-4 d-flex justify-content-between">
            <Header>Settings</Header>
            {/* <div>
              <p className={styles.customers__totalText}>
                Total Numbers of status
              </p>
              <h4 className={styles.customers__totalAmount}>
                {nipStatusCodes?.data?.length || 0}
              </h4>
            </div> */}
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={data?.reduce((acc, each) => {
              return { ...acc, [each?.name]: each?.value };
            }, {})}
            onSubmit={async (values) =>
              dispatch(
                updateSettingsFn(values, () => dispatch(getSettingsFn()))
              )
            }
          >
            {() => (
              <Form className={styles.customers__content}>
                <Table
                  noData={data?.length === 0 ? true : false}
                  headers={["Name", "Value"]}
                >
                  {data?.map((each: any) => {
                    return (
                      <tr key={each?.name}>
                        <td>{each?.label}</td>
                        <td>
                          <Field
                            type="text"
                            component={TextInput}
                            name={each?.name}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </Table>
                {updateLoading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  data?.length > 0 && (
                    <div className={styles.bottom__div}>
                      <OutlineButton
                        title="Edit"
                        type="submit"
                        className={styles.form__btn}
                      />
                    </div>
                  )
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}
    </BankLayout>
  );
};

export default SettingsScreen;
