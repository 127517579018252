import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import ReactTooltip from "react-tooltip";
import request from "constants/requests";
import { toast } from "react-toastify";

import styles from "./Admins.module.css";
import AddWalletImg from "assets/images/addWallet.svg";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateAdmin from "./components/CreateAdmin/CreateAdmin";

const perPage = 20;

const AdminsScreen: React.FC = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<any>({});
  const [sideContent, setSideContent] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(meta?.page || 1);
  const [submitting, setSubmitting] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fetchAdmins = async () => {
    setLoading(true);

    const params: any = {};

    if (searchValue) {
      params.name = searchValue;
    }
    if (perPage) {
      params.perPage = perPage;
    }
    if (currentPage) {
      params.page = currentPage;
    }

    try {
      const { data } = await request({
        method: "get",
        url: "/admin",
        params,
      });
      setAdmins(data?.data);
      setMeta(data?.metadata);
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [currentPage]);

  const createAdmin = async (content, cb) => {
    setSubmitting(true);

    try {
      const { data } = await request({
        method: "post",
        url: "/admin",
        data: content,
      });
      toast.success(data?.message);
      cb();
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const renderRows = () => {
    const rows = admins?.map((each: any) => {
      return (
        <tr key={each?.id}>
          <td>{each?.firstName + " " + each?.lastName}</td>
          <td>{each?.email}</td>
          <td>{format(parseISO(each?.createdAt), "MMM dd, yyyy")}</td>
        </tr>
      );
    });

    return rows;
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
  };

  const renderSideContent = () => {
    if (sideContent === "createAdmin") {
      return (
        <CreateAdmin
          loading={submitting}
          onSubmit={(values, { resetForm }) => {
            createAdmin(values, () => {
              fetchAdmins();
              closeModal();
              resetForm();
            });
          }}
        />
      );
    } else {
      return null;
    }
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <BankLayout>
        {loading && currentPage === 1 ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>
                Admins
                <>
                  <img
                    alt="Admin"
                    className="ml-2"
                    src={AddWalletImg}
                    style={{ cursor: "pointer" }}
                    onClick={() => openModal("createAdmin")}
                    data-tip="Create Admin"
                    data-for="createAdmin"
                  />
                  <ReactTooltip id="createAdmin" place="right" />
                </>
              </Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total numbers of admins
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {meta?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchAdmins();
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search admin
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search admin with name"
                    />
                  </div>
                </div>
                {/* <PrimaryButton title="Export Agent List" type="button" /> */}
              </form>
              <Table
                noData={admins?.length === 0 ? true : false}
                headers={["NAME", "EMAIL", "DATE"]}
              >
                {loading ? (
                  <tr>
                    <td colSpan={3} style={{ margin: "1rem 0" }}>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              {meta?.totalPages > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    pageCount={meta?.totalPages}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default AdminsScreen;
