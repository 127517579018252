import React, { Fragment, useState } from "react";
import { object, string } from "yup";
import AuthLayout from "layouts/Auth/Auth";
import { Field, Form, Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextInput from "components/TextInput/TextInput";
import { updateKycFn, selectAuthState } from "slices/authSlice";
import { PrimaryButton, OutlineButton } from "components/Button/Button";

import routes from "routing/routes";

const KYCScreen: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [image, setImage] = useState({});
    const [imageName, setImageName] = useState("");
    const { id: merchantId } = useParams() as Record<string, string | undefined>;

    const authState = useSelector(selectAuthState);
    const { loading: kycLoading } = authState.kyc;

    const submitKYC = (values: { bvn: string }) => {
        dispatch(
            updateKycFn({
                merchantId,
                image: image,
                bvn: values.bvn,
                imageTitle: imageName,
                cb: () => history.push(routes.ACCOUNT_REVIEW),
            })
        );
    };

    const fileSelectedHandler = (event: any) => {
        if (event.target.files[0]) {
            setImage(event.target.files[0]);
            setImageName(event.target.files[0].name);
        }
    };

    return (
        <AuthLayout title="Director's Details">
            <Formik
                onSubmit={submitKYC}
                initialValues={{ bvn: "" }}
                validationSchema={object().shape({ bvn: string().length(11, "Must be 11 characters") })}
            >
                {() => (
                    <Form>
                        <Field name="bvn" placeholder="BVN" component={TextInput} />

                        <div className="form-group">
                            <div className="custom-file">
                                <input
                                    type="file"
                                    id="cac"
                                    className="custom-file-input"
                                    onChange={fileSelectedHandler}
                                    accept="image/png, image/jpeg,image/jpg,.pdf"
                                />
                                <label className="custom-file-label" htmlFor="cac">
                                    Choose file
                                </label>
                            </div>
                        </div>

                        {kycLoading ? (
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <PrimaryButton type="submit" block size="lg" title="Submit" />
                                <OutlineButton block size="lg" title="Skip" />
                            </Fragment>
                        )}
                    </Form>
                )}
            </Formik>
        </AuthLayout>
    );
};

export default KYCScreen;
