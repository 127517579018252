import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  bvn?: string;
  nin?: string;
  tier?: string;
  dateOfBirth: string;
  address: string;
}

export interface CreateWalletSectionProps {
  createWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const CreateWalletSection: React.FC<CreateWalletSectionProps> = ({
  createWalletFn,
  loading,
  error,
}) => {
  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };
  return (
    <div style={{ overflowY: "auto" }}>
      <h2 className="mb-5">Create Customer</h2>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          dateOfBirth: "",
          bvn: "",
          nin: "",
          tier: "",
          address: "",
        }}
        validationSchema={CreateWalletSchema}
        onSubmit={(values, actions) => {
          const credentials: CreateWalletValuesTypes = {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            phoneNumber: values?.phoneNumber,
            dateOfBirth: values?.dateOfBirth,
            address: values?.address,
          };

          if (values?.bvn) {
            credentials.bvn = values?.bvn;
          }
          if (values?.nin) {
            credentials.nin = values?.nin;
          }
          if (values?.tier) {
            credentials.tier = values?.tier;
          }

          createWalletFn(credentials);
          if (!error) {
            actions.resetForm();
          }

          // console.log(values);
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col">
                <Field
                  name="firstName"
                  placeholder="First Name*"
                  type="text"
                  component={TextInput}
                />
              </div>
              <div className="col">
                <Field
                  name="lastName"
                  placeholder="Last Name*"
                  type="text"
                  component={TextInput}
                />
              </div>
            </div>
            <Field
              name="email"
              placeholder="Email*"
              type="email"
              component={TextInput}
            />
            <Field
              name="phoneNumber"
              placeholder="Phone*"
              type="tel"
              component={TextInput}
            />
            <div className="form-group">
              <Field
                name="tier"
                component="select"
                placeholder="Favorite Color"
                className={`form-control form-control-lg ${
                  errors.tier && touched.tier ? "is-invalid" : ""
                }`}
              >
                <option value="">Select Tier</option>
                <option value="TIER_1">Tier 1</option>
                <option value="TIER_2">Tier 2</option>
                <option value="TIER_3">Tier 3</option>
              </Field>
              {errors.tier && touched.tier && (
                <div className="invalid-feedback">{errors.tier}</div>
              )}
            </div>
            <Field
              name="bvn"
              placeholder="BVN"
              type="text"
              component={TextInput}
              maxLength={11}
            />
            <Field
              name="nin"
              placeholder="NIN"
              type="text"
              component={TextInput}
              maxLength={11}
            />
            <Field
              name="dateOfBirth"
              placeholder="YYYY-MM-DD"
              type="date"
              component={TextInput}
            />
            <Field
              name="address"
              placeholder="Address*"
              type="text"
              component={TextInput}
            />

            {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Create Customer"
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateWalletSection;

const CreateWalletSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .min(3, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(3, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    phoneNumber: Yup.string()
      .min(3, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),
    bvn: Yup.string().when("nin", {
      is: (val) => val,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .length(11, "BVN length should be 11 characters!"),
    }),
    dateOfBirth: Yup.date()
      .max(new Date(), "DOB must not be more than today")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    address: Yup.string().required("Required"),
    tier: Yup.string(),
    nin: Yup.string().when("bvn", {
      is: (val) => val,
      then: (schema) => schema,
      otherwise: (schema) =>
        schema
          .length(11, "NIN length should be 11 characters!"),
    }),
  },
  [["bvn", "nin"]]
);
