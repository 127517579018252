import React from "react";

import styles from "./AuditTrailDetail.module.css";

import Loading from "components/Loading/Loading";
import format from "date-fns/format";

export interface AuditTrailDetailProps {
  details: {
    loading: boolean;
    data: any;
  };
  closeModal: () => void;
}

const AuditTrailDetail: React.FC<AuditTrailDetailProps> = ({
  details,
  closeModal,
}) => {
  const { name, mode, description, request, createdAt, Merchant } =
    details?.data;

  const REQUEST_KEYS = request ? Object.keys(request) : null;

  if (details.loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={styles.header}>
        <p>Audit Trail Details</p>
        {/* <img
          src={require("../../../../../assets/images/closeModal.svg")}
          alt="Close Icon"
          onClick={closeModal}
        /> */}
      </div>
      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          {!!name && (
            <tr>
              <td>Name</td>
              <td align="right" style={{ wordWrap: "break-word" }}>
                {name}
              </td>
            </tr>
          )}
          {!!mode && (
            <tr>
              <td>Mode</td>
              <td align="right" style={{ textTransform: "capitalize" }}>
                {mode}
              </td>
            </tr>
          )}
          {!!description && (
            <tr>
              <td>Description</td>
              <td align="right">{description}</td>
            </tr>
          )}
          {!!Merchant && (
            <tr>
              <td>Business Name</td>
              <td align="right">{Merchant?.businessName}</td>
            </tr>
          )}
          {!!createdAt && (
            <tr>
              <td>Created At</td>
              <td align="right">
                {format(new Date(createdAt), "MMM dd, yyyy")}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!!REQUEST_KEYS && <p className={styles.request__header}>Request</p>}
      {!!REQUEST_KEYS && (
        <table
          style={{ tableLayout: "fixed" }}
          className="table table-striped table-borderless mt-4"
        >
          <tbody>
            {REQUEST_KEYS?.map((data) => {
              if (typeof request[data] === "object") {
                return (
                  <tr key={data}>
                    <td style={{ textTransform: "capitalize" }}>{data}</td>
                    <td align="right" style={{ wordWrap: "break-word" }}>
                      {JSON.stringify(request[data])}
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={data}>
                  <td style={{ textTransform: "capitalize" }}>{data}</td>
                  <td align="right" style={{ wordWrap: "break-word" }}>
                    {request[data]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AuditTrailDetail;
