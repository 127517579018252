import React, { useState } from "react";
import Table from "components/Table/Table";
import { format, parseISO } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import config from "../../../../../constants/api";
import { selectBankAccessModeState } from "slices/bankSlice";

import styles from "./MerchantList.module.css";
import { numberWithCommas } from "utils/helpers";
import { PrimaryButton } from "components/Button/Button";
import routes from "routing/routes";

import DownArrow from "assets/images/down-arrow.svg";

const MerchantList = ({ data, openModal, getMerchantFn }) => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fetching, setFetching] = useState(false);

  const dispatch = useDispatch();

  const mode = useSelector(selectBankAccessModeState);

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const exportMerchantFn = async ({
    currentPage,
    mode,
  }: {
    currentPage?: number;
    mode?: string;
  }) => {
    setLoading(true);

    try {
      let httpClient = new XMLHttpRequest();
      let URLToPDF = `${config.API_URL}/merchant/export`;
      let params = "";

      if (currentPage) {
        params = params + `currentPage=${currentPage}`;
      }
      if (params && mode) {
        params = params + `&mode=${mode}`;
      }
      if (!params && mode) {
        params = params + `mode=${mode}`;
      }
      if (params && searchValue) {
        params = params + `&phoneNumber=${searchValue}`;
      }
      if (!params && searchValue) {
        params = params + `phoneNumber=${searchValue}`;
      }
      if (params && startDate) {
        params = params + `&startDate=${startDate}`;
      }
      if (!params && startDate) {
        params = params + `startDate=${startDate}`;
      }
      if (params && endDate) {
        params = params + `&endDate=${endDate}`;
      }
      if (!params && endDate) {
        params = params + `endDate=${endDate}`;
      }

      if (params?.length) {
        URLToPDF = `${URLToPDF}?${params}`;
      }

      httpClient.open("GET", URLToPDF, true);
      httpClient.responseType = "blob";
      httpClient.setRequestHeader(
        "X-Access-Token",
        localStorage.getItem(config.ACCESS_TOKEN)
      );

      httpClient.onload = function () {
        const file = new Blob([httpClient.response], { type: "text/csv" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "merchants.csv";
        link.click();
        URL.revokeObjectURL(fileURL);
        setLoading(false);
      };
      httpClient.send();
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const renderTable = () =>
    data?.map((record) => (
      <tr key={record?.id} style={{ cursor: "pointer" }}>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.businessName}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.accountNumber}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {record?.phoneNumber}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          &#8358;{numberWithCommas(record?.availableBalance)}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {statusFn(record?.review)}
        </td>
        <td onClick={() => history.push("/bank/merchants/" + record?.id)}>
          {format(parseISO(record?.createdAt), "MMM dd, yyyy")}
        </td>
        <td>
          <PrimaryButton
            title="View Transactions"
            size="sm"
            link={`${routes.BANK_MERCHANT}/${record?.id}/transactions`}
          />
        </td>
      </tr>
    ));

  const applyFilter = (e) => {
    e.preventDefault();
    setFetching(true);
    dispatch(
      getMerchantFn({
        startDate,
        endDate,
        cb: () => {
          setFetching(false);
        },
      })
    );
    setShowFilter(false);
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.search__container}>
        <form
          className={styles.search__content}
          onSubmit={(e) => {
            e.preventDefault();
            setFetching(true);
            dispatch(
              getMerchantFn({
                phoneNumber: searchValue,
                cb: () => {
                  setFetching(false);
                },
              })
            );
            setStartDate(null);
            setEndDate(null);
          }}
        >
          <label className="sr-only" htmlFor="searchMerchant">
            Search merchant
          </label>
          <div
            className="input-group mb-2"
            style={{ backgroundColor: "#87BD40", border: 0 }}
          >
            <div className="input-group-prepend">
              <div
                style={{
                  borderRightColor: "transparent",
                  backgroundColor: "transparent",
                  border: 0,
                }}
                className="input-group-text"
              >
                <i className="fas fa-search"></i>
              </div>
            </div>
            <input
              type="text"
              value={searchValue}
              id="searchMerchant"
              className="form-control"
              onChange={(e) => setSearchValue(e.target.value)}
              style={{
                borderLeftColor: "transparent",
                backgroundColor: "#87BD40",
                color: "#fff",
                border: 0,
              }}
              placeholder="Search by phone number"
            />
          </div>
        </form>
        <div className={styles.export__container}>
          <PrimaryButton
            title={loading ? "Exporting..." : "Export Merchant List"}
            type="button"
            className={styles.export}
            onClick={() => exportMerchantFn({ mode })}
          />
          <p
            style={{
              width: "12rem",
              border: "1px solid rgba(0,0,0,.2)",
              borderRadius: "30px",
              padding: "8px 15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              textTransform: "capitalize",
              fontSize: "90%",
            }}
            onClick={() => setShowFilter(!showFilter)}
          >
            <span>ALL</span>{" "}
            <img
              src={DownArrow}
              alt="Down Arrow"
              style={{ width: ".7rem", height: ".7rem" }}
            />
          </p>
        </div>
      </div>
      <div
        className={styles.filter__container}
        style={{ display: showFilter ? "block" : "none" }}
      >
        <div className={styles.filter__top}>
          <p>Filter</p>
          <button type="button" onClick={applyFilter}>
            Apply
          </button>
        </div>
        <div className={styles.filter__bottom}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={80}
            scrollableYearDropdown
            className={styles.date__container}
            placeholderText="Start Date"
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={80}
            scrollableYearDropdown
            className={styles.date__container}
            placeholderText="End Date"
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <Table
        noData={data.length === 0}
        headers={[
          "MERCHANT NAME",
          "ACCOUNT NO",
          "PHONE NO",
          "BALANCE",
          "STATUS",
          "DATE",
          "ACTION",
        ]}
      >
        {fetching ? (
          <tr>
            <td colSpan={7}>
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          renderTable()
        )}
      </Table>
      <div
        className={styles.popup__hover}
        onClick={() => setShowFilter(false)}
        style={{ display: showFilter ? "block" : "none" }}
      />
    </div>
  );
};

export default MerchantList;
