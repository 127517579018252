import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";

import { PrimaryButton } from "components/Button/Button";
import styles from "./FilterCards.module.css";
import request from "constants/requests";

export interface UploadType {
  showModal: boolean;
  handleFilter: (x: any) => void;
}

export const FilterBatchCards: React.FC<UploadType> = ({
  showModal,
  handleFilter,
}) => {
  const [merchants, setMerchants] = useState([]);

  const getMerchantsFn = async () => {
    try {
      const { data } = await request({
        method: "get",
        url: "/merchant",
        params: { perPage: 1000000 },
      });
      setMerchants(data?.data);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    if (showModal) {
      getMerchantsFn();
    }
  }, [showModal]);

  const handleSubmit = async (values) => {
    handleFilter(values);
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <h2 className="mb-5">Filter Batch Card</h2>
      <Formik
        initialValues={{
          merchant: "",
        }}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form className={styles.content__container}>
              <div className="form-group">
                <Field
                  name="merchant"
                  component="select"
                  placeholder="Select Merchant"
                  className={`form-control form-control-lg`}
                >
                  <option value="">Select Merchant</option>
                  {merchants?.map((each) => (
                    <option value={each?.id} key={each?.id}>
                      {each?.businessName}
                    </option>
                  ))}
                </Field>
              </div>

              <div style={{ marginTop: "20px" }}>
                <PrimaryButton
                  type="submit"
                  block
                  size="lg"
                  title="Apply Filter"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
