import React from "react";
import { format, parseISO } from "date-fns";
import Table from "components/Table/Table";
import { Link } from "react-router-dom";
import Header from "components/Header/Header";
// import DottedImg from "assets/images/dotted.svg";
import routes from "routing/routes";

import styles from "./MerchantList.module.css";
import { numberWithCommas } from "utils/helpers";
import ArrowForward from "assets/images/arrowForward.svg";

const MerchantList = ({ data }) => {
    const renderTable = () =>
        data.slice(0, 10).map((record) => (
            <tr key={record?.id}>
                <td>{record?.businessName}</td>
                <td>{record?.accountNumber}</td>
                <td>{record?.phoneNumber}</td>
                <td>&#8358;{numberWithCommas(record?.availableBalance)}</td>
                <td>{["IN-REVIEW", "PENDING"].includes(record?.review) ? "Pending" : "Active"}</td>
                <td>{format(parseISO(record?.createdAt), "MMM dd, yyyy")}</td>
            </tr>
        ));

    return (
        <>
            <div className="mb-2">
                <Header>
                    Recent Merchants {/*<img alt="Option" className={styles.wallet__headerIcon} src={DottedImg} />*/}
                </Header>
            </div>

            <div className={styles.tableContainer}>
                <Table
                    noData={data.length === 0}
                    headers={["MERCHANT NAME", "ACCOUNT NO", "PHONE NO", "BALANCE", "STATUS", "DATE"]}
                >
                    {renderTable()}
                </Table>

                <p className={styles.goToMerchantText}>
                    <Link to={routes.BANK_MERCHANT}>
                        See all merchant accounts <img src={ArrowForward} alt="Proceed" />
                    </Link>
                </p>
            </div>
        </>
    );
};

export default MerchantList;
