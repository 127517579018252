import React from "react";

const RoleDetails: React.FC<{ content: any; permissions: any[] }> = ({
  content,
  permissions,
}) => {
  return (
    <div>
      <h2>Role Details</h2>

      <div className="mt-4">
        <div className="mb-1">
          <p className="bg-light px-2 py-2 font-weight-bold">Role:</p>
          <p className="pl-4">{content?.name}</p>
        </div>
        <div className="mt-2">
          <p className="bg-light px-2 py-2 font-weight-bold">Permissions:</p>
          {content?.permissions
            ?.filter(
              (each) =>
                [
                  "MANAGE_BALANCE_SETTLEMENT",
                  "SET_TRANSACTION_PIN",
                  "MANAGE_AIRTIME",
                  "MANAGE_PREPAID_CARD",
                ].includes(each) === false
            )
            ?.map((each) => (
              <p className="pl-4" key={each}>
                {permissions?.find((data) => data?.name === each)?.description}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RoleDetails;
