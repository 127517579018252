import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import request from "constants/requests";

const initialState = {
  bankList: {
    loading: false,
    data: [],
    error: {},
  },
  bankAccountDetails: {
    loading: false,
    data: {},
    error: {},
  },
  bankTransfer: {
    loading: false,
    data: {},
    error: {},
  },
  userByPhone: {
    loading: false,
    data: {},
    error: {},
  },
  customerBankTransfer: {
    loading: false,
    data: {},
    error: {},
  },
  batchBankTransfer: {
    loading: false,
    data: null,
    error: null,
  },
  batchCustomerBankTransfer: {
    loading: false,
    data: null,
    error: null,
  },
  uploadBulkCreditCustomer: {
    loading: false,
    data: null,
    error: null,
  },
  processBulkCreditCustomer: {
    loading: false,
    data: null,
    error: null,
  },
  getBulkCreditCustomerRecords: {
    loading: false,
    data: null,
    error: null,
  },
  deleteBulkCreditCustomerRecord: {
    loading: false,
    data: null,
    error: null,
  },
};

const sendMoneySlice = createSlice({
  name: "sendMoney",
  initialState: initialState,
  reducers: {
    getBankList: (state) => {
      let { bankList } = state;
      bankList.loading = true;
      bankList.data = [];
      bankList.error = {};
    },
    getBankListSuccess: (state, { payload }) => {
      let { bankList } = state;
      bankList.loading = false;
      bankList.data = payload;
      bankList.error = {};
    },
    getBankListFailed: (state, { payload }) => {
      let { bankList } = state;
      bankList.loading = false;
      bankList.data = [];
      bankList.error = payload;
    },
    getBankAccountDetails: (state) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = true;
      bankAccountDetails.data = {};
      bankAccountDetails.error = {};
    },
    getBankAccountDetailsSuccess: (state, { payload }) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = payload;
      bankAccountDetails.error = {};
    },
    getBankAccountDetailsFailed: (state, { payload }) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = {};
      bankAccountDetails.error = payload;
    },
    postBankTransfer: (state) => {
      let { bankTransfer } = state;
      bankTransfer.loading = true;
      bankTransfer.data = {};
      bankTransfer.error = {};
    },
    postBankTransferSuccess: (state, { payload }) => {
      let { bankTransfer } = state;
      bankTransfer.loading = false;
      bankTransfer.data = payload;
      bankTransfer.error = {};
    },
    postBankTransferFailed: (state, { payload }) => {
      let { bankTransfer } = state;
      bankTransfer.loading = false;
      bankTransfer.data = {};
      bankTransfer.error = payload;
    },
    getUserByPhone: (state) => {
      let { userByPhone } = state;
      userByPhone.loading = true;
      userByPhone.data = {};
      userByPhone.error = {};
    },
    getUserByPhoneSuccess: (state, { payload }) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = payload;
      userByPhone.error = {};
    },
    getUserByPhoneFailed: (state, { payload }) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = {};
      userByPhone.error = payload;
    },
    clearUserByPhone: (state) => {
      let { userByPhone } = state;
      userByPhone.loading = false;
      userByPhone.data = {};
      userByPhone.error = {};
    },
    clearBankAccountDetails: (state) => {
      let { bankAccountDetails } = state;
      bankAccountDetails.loading = false;
      bankAccountDetails.data = {};
      bankAccountDetails.error = {};
    },
    resetCustomerBankTransfer: (state) => {
      const { customerBankTransfer } = state;

      customerBankTransfer.loading = true;
      customerBankTransfer.data = {};
      customerBankTransfer.error = {};
    },
    customerBankTransferSuccess: (state, { payload }) => {
      const { customerBankTransfer } = state;

      customerBankTransfer.loading = false;
      customerBankTransfer.data = payload.data;
      customerBankTransfer.error = {};
    },
    customerBankTransferFailed: (state, { payload }) => {
      const { customerBankTransfer } = state;

      customerBankTransfer.loading = false;
      customerBankTransfer.data = {};
      customerBankTransfer.error = payload;
    },
    resetBatchBankTransfer: (state) => {
      const { batchBankTransfer } = state;

      batchBankTransfer.loading = true;
      batchBankTransfer.data = null;
      batchBankTransfer.error = null;
    },
    batchBankTransferSuccess: (state, { payload }) => {
      const { batchBankTransfer } = state;

      batchBankTransfer.loading = false;
      batchBankTransfer.data = payload;
      batchBankTransfer.error = null;
    },
    batchBankTransferFailure: (state, { payload }) => {
      const { batchBankTransfer } = state;

      batchBankTransfer.loading = true;
      batchBankTransfer.data = null;
      batchBankTransfer.error = null;
    },
    resetBatchCustomerBankTransfer: (state) => {
      const { batchCustomerBankTransfer } = state;

      batchCustomerBankTransfer.loading = true;
      batchCustomerBankTransfer.data = null;
      batchCustomerBankTransfer.error = null;
    },
    batchCustomerBankTransferSuccess: (state, { payload }) => {
      const { batchCustomerBankTransfer } = state;

      batchCustomerBankTransfer.loading = false;
      batchCustomerBankTransfer.data = payload;
      batchCustomerBankTransfer.error = null;
    },
    batchCustomerBankTransferFailure: (state, { payload }) => {
      const { batchCustomerBankTransfer } = state;

      batchCustomerBankTransfer.loading = false;
      batchCustomerBankTransfer.data = null;
      batchCustomerBankTransfer.error = payload;
    },
    resetUploadBulkCreditCustomer: (state) => {
      const { uploadBulkCreditCustomer } = state;

      uploadBulkCreditCustomer.loading = true;
      uploadBulkCreditCustomer.data = null;
      uploadBulkCreditCustomer.error = null;
    },
    uploadBulkCreditCustomerSuccess: (state, { payload }) => {
      const { uploadBulkCreditCustomer } = state;

      uploadBulkCreditCustomer.loading = false;
      uploadBulkCreditCustomer.data = payload;
      uploadBulkCreditCustomer.error = null;
    },
    uploadBulkCreditCustomerFailure: (state, { payload }) => {
      const { uploadBulkCreditCustomer } = state;

      uploadBulkCreditCustomer.loading = false;
      uploadBulkCreditCustomer.data = null;
      uploadBulkCreditCustomer.error = payload;
    },
    resetProcessBulkCreditCustomer: (state) => {
      const { processBulkCreditCustomer } = state;

      processBulkCreditCustomer.loading = true;
      processBulkCreditCustomer.data = null;
      processBulkCreditCustomer.error = null;
    },
    processBulkCreditCustomerSuccess: (state, { payload }) => {
      const { processBulkCreditCustomer } = state;

      processBulkCreditCustomer.loading = false;
      processBulkCreditCustomer.data = payload;
      processBulkCreditCustomer.error = null;
    },
    processBulkCreditCustomerFailure: (state, { payload }) => {
      const { processBulkCreditCustomer } = state;

      processBulkCreditCustomer.loading = false;
      processBulkCreditCustomer.data = null;
      processBulkCreditCustomer.error = payload;
    },
    resetGetBulkCreditCustomerRecords: (state) => {
      const { getBulkCreditCustomerRecords } = state;

      getBulkCreditCustomerRecords.loading = true;
      getBulkCreditCustomerRecords.data = null;
      getBulkCreditCustomerRecords.error = null;
    },
    getBulkCreditCustomerRecordsSuccess: (state, { payload }) => {
      const { getBulkCreditCustomerRecords } = state;

      getBulkCreditCustomerRecords.loading = false;
      getBulkCreditCustomerRecords.data = payload;
      getBulkCreditCustomerRecords.error = null;
    },
    getBulkCreditCustomerRecordsFailure: (state, { payload }) => {
      const { getBulkCreditCustomerRecords } = state;

      getBulkCreditCustomerRecords.loading = false;
      getBulkCreditCustomerRecords.data = null;
      getBulkCreditCustomerRecords.error = payload;
    },
    resetDeleteBulkCreditCustomerRecord: (state) => {
      const { deleteBulkCreditCustomerRecord } = state;

      deleteBulkCreditCustomerRecord.loading = true;
      deleteBulkCreditCustomerRecord.data = null;
      deleteBulkCreditCustomerRecord.error = null;
    },
    deleteBulkCreditCustomerRecordSuccess: (state, { payload }) => {
      const { deleteBulkCreditCustomerRecord } = state;

      deleteBulkCreditCustomerRecord.loading = false;
      deleteBulkCreditCustomerRecord.data = payload;
      deleteBulkCreditCustomerRecord.error = null;
    },
    deleteBulkCreditCustomerRecordFailure: (state, { payload }) => {
      const { deleteBulkCreditCustomerRecord } = state;

      deleteBulkCreditCustomerRecord.loading = false;
      deleteBulkCreditCustomerRecord.data = null;
      deleteBulkCreditCustomerRecord.error = payload;
    },
  },
});

export const {
  getBankList,
  getBankListSuccess,
  getBankListFailed,
  getBankAccountDetails,
  getBankAccountDetailsSuccess,
  getBankAccountDetailsFailed,
  postBankTransfer,
  postBankTransferSuccess,
  postBankTransferFailed,
  getUserByPhone,
  getUserByPhoneSuccess,
  getUserByPhoneFailed,
  clearUserByPhone,
  clearBankAccountDetails,
  resetCustomerBankTransfer,
  customerBankTransferSuccess,
  customerBankTransferFailed,
  resetBatchBankTransfer,
  batchBankTransferSuccess,
  batchBankTransferFailure,
  resetBatchCustomerBankTransfer,
  batchCustomerBankTransferSuccess,
  batchCustomerBankTransferFailure,
  resetUploadBulkCreditCustomer,
  uploadBulkCreditCustomerSuccess,
  uploadBulkCreditCustomerFailure,
  resetProcessBulkCreditCustomer,
  processBulkCreditCustomerSuccess,
  processBulkCreditCustomerFailure,
  resetGetBulkCreditCustomerRecords,
  getBulkCreditCustomerRecordsSuccess,
  getBulkCreditCustomerRecordsFailure,
  resetDeleteBulkCreditCustomerRecord,
  deleteBulkCreditCustomerRecordSuccess,
  deleteBulkCreditCustomerRecordFailure,
} = sendMoneySlice.actions;

export const getBankListFn =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(getBankList());
      const response = await request({
        method: "get",
        url: "/transfer/banks",
      });
      // console.log(response.data.banks);
      const bankList = response?.data?.banks;
      const sortBanksAlphabetically = (list: any) =>
        list.sort((a: any, b: any) => a?.name.localeCompare(b?.name));
      dispatch(getBankListSuccess(sortBanksAlphabetically(bankList)));
    } catch (error) {
      dispatch(getBankListFailed(error?.response?.data || error?.response));
    }
  };

export const getBankAccountDetailsFn =
  (sortCode: string, accountNumber: string) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(getBankAccountDetails());
      const response = await request({
        method: "get",
        url: "/transfer/account/details",
        params: {
          sortCode,
          accountNumber,
        },
      });
      // throw new Error();
      dispatch(getBankAccountDetailsSuccess(response?.data?.account));
      console.log("Account: ", response?.data?.account);
    } catch (error) {
      dispatch(
        getBankAccountDetailsFailed(error?.response?.data || error?.response)
      );
    }
  };

export const clearBankAccountDetailsFn =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(clearBankAccountDetails());
  };

export const getUserByPhoneFn =
  (phoneNumber: string, cb?: (id: string) => void) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(getUserByPhone());
      const response = await request({
        method: "get",
        url: "/customer/phone",
        params: {
          phoneNumber,
        },
      });

      dispatch(getUserByPhoneSuccess(response?.data));
      if (cb) {
        cb(response?.data?.customer?.id);
      }
    } catch (error) {
      dispatch(getUserByPhoneFailed(error?.response?.data || error?.response));
    }
  };

export const clearUserByPhoneFn =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    dispatch(clearUserByPhone());
  };

export const postBankTransferFn =
  (
    values: {
      amount: string;
      accountNumber: string;
      narration: string;
      sortCode: string;
      accountName: string;
    },
    cb?: () => void
  ) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(postBankTransfer());
      const response = await request({
        method: "post",
        url: "/transfer/bank",
        data: values,
      });
      dispatch(postBankTransferSuccess(response?.data));
      toast.success(response?.data?.message || "Success");
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        postBankTransferFailed(error?.response?.data || error?.response)
      );
    }
  };

export const customerBankTransferFn =
  (
    values: {
      amount: string;
      accountNumber: string;
      narration: string;
      sortCode: string;
      accountName: string;
      customerId: string;
    },
    cb?: () => void
  ) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(resetCustomerBankTransfer());
      const response = await request({
        method: "post",
        url: "/transfer/bank/customer",
        data: values,
      });
      dispatch(customerBankTransferSuccess({ data: response?.data }));
      toast.success(response?.data?.message || "Success");
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        customerBankTransferFailed(error?.response?.data || error?.response)
      );
    }
  };

export const batchBankTransferFn =
  (payload, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetBatchBankTransfer());
      const { data } = await request({
        method: "post",
        url: "/transfer/bank/batch",
        data: payload,
      });
      dispatch(batchBankTransferSuccess(data?.message));
      toast.success(data?.message || "Success");
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        batchBankTransferFailure(error?.response?.data || error?.response)
      );
    }
  };

export const batchCustomerBankTransferFn =
  (payload, cb?: () => void) => async (dispatch) => {
    try {
      dispatch(resetBatchBankTransfer());
      const { data } = await request({
        method: "post",
        url: "/transfer/bank/batch",
        data: payload,
      });
      dispatch(batchBankTransferSuccess(data?.message));
      toast.success(data?.message || "Success");
      if (cb) {
        cb();
      }
    } catch (error) {
      dispatch(
        batchBankTransferFailure(error?.response?.data || error?.response)
      );
    }
  };

export const getBulkCreditCustomerRecordsFn = () => async (dispatch) => {
  dispatch(resetGetBulkCreditCustomerRecords());

  try {
    const { data } = await request({
      method: "get",
      url: "/wallet/bulk-credit-customer-records",
    });
    dispatch(getBulkCreditCustomerRecordsSuccess(data?.data));
  } catch (error) {
    dispatch(
      getBulkCreditCustomerRecordsFailure(
        error?.response?.data || error?.response
      )
    );
  }
};

export const uploadBulkCreditCustomerFn =
  ({ formData, cb }: { formData: any; cb?: () => void }) =>
  async (dispatch) => {
    dispatch(resetUploadBulkCreditCustomer());

    try {
      const { data } = await request({
        method: "post",
        url: "/wallet/upload-bulk-credit-customer",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(uploadBulkCreditCustomerSuccess("File Uploaded Successfully"));
      toast.success("File Uploaded Successfully");
      if (data?.data) {
        cb();
      }
    } catch (error) {
      dispatch(
        uploadBulkCreditCustomerFailure(
          error?.response?.data || error?.response
        )
      );
    }
  };

export const processBulkCreditCustomerFn =
  (batchReference, cb?: () => void) => async (dispatch) => {
    dispatch(resetProcessBulkCreditCustomer());

    try {
      const { data } = await request({
        method: "post",
        url: "/wallet/process-bulk-credit-customer",
        data: { batchReference },
      });

      dispatch(processBulkCreditCustomerSuccess(data?.message));
      toast.success(data?.message);
      if (data?.message) {
        cb();
      }
    } catch (error) {
      dispatch(
        processBulkCreditCustomerFailure(
          error?.response?.data || error?.response
        )
      );
    }
  };

export const deleteBulkCreditCustomerFn =
  (batchId, cb?: () => void) => async (dispatch) => {
    dispatch(resetDeleteBulkCreditCustomerRecord());

    try {
      const { data } = await request({
        method: "delete",
        url: `/wallet/bulk-credit-customer/${batchId}`,
      });

      dispatch(deleteBulkCreditCustomerRecordSuccess(data?.message));
      toast.success(data?.message);
      if (data?.message) {
        cb();
      }
    } catch (error) {
      dispatch(
        deleteBulkCreditCustomerRecordFailure(
          error?.response?.data || error?.response
        )
      );
    }
  };

export const selectSendMoneyState = (state: any) => state.sendMoney;

export default sendMoneySlice.reducer;
