import * as React from "react";

export interface LoadingProps {
  isSpaced?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isSpaced = false }) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ padding: isSpaced ? "50px 0px" : 0 }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
