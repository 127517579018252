import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import styles from "./AuditTrail.module.css";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import BankLayout from "layouts/Bank/Bank";
import AuditTrailDetails from "./components/AuditTrailDetail/AuditTrailDetail";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import {
  selectAuditTrail,
  getAuditTrailFn,
  selectBankAccessModeState,
} from "slices/bankSlice";

const perPage = 20;

const AuditTrailScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, data, meta } = useSelector(selectAuditTrail);
  const modeState = useSelector(selectBankAccessModeState);

  const [sideContent, setSideContent] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAuditTrail, setSelectedAuditTrail] = useState<any>(null);
  const [auditLoading, setAuditLoading] = useState(false);

  const fetchAuditTrails = () => {
    setAuditLoading(true);
    dispatch(
      getAuditTrailFn({
        mode: modeState,
        currentPage,
        perPage,
        cb: () => setAuditLoading(false),
      })
    );
  };

  useEffect(fetchAuditTrails, [currentPage, modeState, perPage]);

  const renderRows = () => {
    const rows = data?.map((each: any) => {
      return (
        <tr style={{ cursor: "pointer" }} key={each?.id}>
          <td onClick={() => getAuditTrail(each?.id)}>{each?.id}</td>
          <td onClick={() => getAuditTrail(each?.id)}>{each?.name}</td>
          <td onClick={() => getAuditTrail(each?.id)}>{each?.description}</td>
          <td onClick={() => getAuditTrail(each?.id)}>
            {format(parseISO(each?.createdAt), "dd MMMM, yyyy")}
          </td>
        </tr>
      );
    });
    return rows;
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const renderSideContent = () => {
    if (sideContent === "auditTrailDetails") {
      return (
        <AuditTrailDetails
          details={{ data: selectedAuditTrail, loading }}
          closeModal={closeModal}
        />
      );
    } else {
      return null;
    }
  };

  const getAuditTrail = (id: string) => {
    const foundAudit = data?.find(
      (record) => record?.id === id || record?.phoneNumber === id
    );

    if (foundAudit) {
      setSelectedAuditTrail(foundAudit);
      openModal("auditTrailDetails");
      return;
    }

    toast.error("No record found");
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>

      <BankLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.customers}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Audit Trail</Header>
              <div>
                <p className={styles.customers__totalText}>
                  Total Numbers of audit trail
                </p>
                <h4 className={styles.customers__totalAmount}>
                  {meta?.totalRecord || 0}
                </h4>
              </div>
            </div>

            <div className={styles.customers__content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getAuditTrail(searchValue);
                }}
              >
                <div className="col-auto my-3">
                  <label className="sr-only" htmlFor="searchCustomer">
                    Search customer
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="searchCustomer"
                      value={searchValue}
                      className="form-control"
                      style={{ borderLeftColor: "transparent" }}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search with customer’s phone number"
                    />
                  </div>
                </div>
              </form>
              <Table
                noData={data?.length === 0 ? true : false}
                headers={["id", "category", "description", "time stamp"]}
              >
                {auditLoading ? (
                  <tr>
                    <td colSpan={4} style={{ padding: "2rem" }}>
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              {Math.ceil(meta?.totalRecord / perPage) > 1 && (
                <nav aria-label="pagination">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    pageCount={
                      meta?.totalRecord
                        ? Math.ceil(meta?.totalRecord / perPage)
                        : 1
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                </nav>
              )}
            </div>
          </div>
        )}
      </BankLayout>
    </>
  );
};

export default AuditTrailScreen;
