import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import styles from "./CustomerDetail.module.css";

import Loading from "components/Loading/Loading";
import BankDetails from "components/BankDetails/BankDetails";
import { numberWithCommas } from "utils/helpers";
import format from "date-fns/format";
import {
  selectFreezeCustomer,
  selectUnfreezeCustomer,
  freezeWalletFn,
  unfreezeWalletFn,
} from "slices/customersSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import request from "constants/requests";

export interface CustomerDetailProps {
  customer: {
    loading: boolean;
    data: any;
  };
  closeModalFn?: () => void | undefined;
  fetchCustomers?: () => void;
}

const TIER_OBJ = {
  TIER_1: "Tier 1",
  TIER_2: "Tier 2",
  TIER_3: "Tier 3",
};

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  customer,
  closeModalFn,
  fetchCustomers,
}) => {
  const dispatch = useDispatch();
  const freezeWalletState = useSelector(selectFreezeCustomer);
  const unfreezeWalletState = useSelector(selectUnfreezeCustomer);
  const [showReason, setShowReason] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    email,
    wallet,
    lastName,
    firstName,
    tier,
    createdAt,
    phoneNumber,
    id,
  } = customer?.data;

  const renderWalletActivation = ({ customerId, status }: any) => {
    if (unfreezeWalletState?.loading) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    if (status === "ACTIVE") {
      return (
        <button
          type="button"
          className="btn btn-light btn-block"
          onClick={() => {
            setShowReason(true);
          }}
          style={{ border: "1px solid red" }}
        >
          Freeze Account
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-light btn-block"
          onClick={() => {
            dispatch(
              unfreezeWalletFn(customerId, () => {
                closeModalFn();
                fetchCustomers();
              })
            );
          }}
          style={{ border: "1px solid #384C55" }}
        >
          Unfreeze Account
        </button>
      );
    }
  };

  const linkWalletToCard = async () => {
    setLoading(true);

    try {
      const { data } = await request({
        method: "post",
        url: "/directcard/create-card",
        data: { customerId: id },
      });

      toast.success(
        data?.status?.message || "Card linked to wallet successfully"
      );
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  if (customer.loading) {
    return <Loading />;
  }

  return showReason ? (
    <div className={styles.reason__container}>
      <h2>Reason</h2>
      <Formik
        initialValues={{ reason: "" }}
        validationSchema={object().shape({
          reason: string().required("Reason is required"),
        })}
        onSubmit={(values, { resetForm }) => {
          dispatch(
            freezeWalletFn(id, values?.reason, () => {
              closeModalFn();
              setShowReason(false);
              resetForm();
            })
          );
        }}
      >
        {() => (
          <Form className={styles.form__container}>
            <Field
              type="text"
              as="textarea"
              name="reason"
              className={styles.input}
            />
            <ErrorMessage
              name="reason"
              className={styles.error}
              component="p"
            />
            <div className={styles.btn__container}>
              {freezeWalletState?.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <PrimaryButton
                  title="Submit"
                  type="submit"
                  className={styles.submit}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div>
      <div>
        <p className={styles.customerDetail__fullName}>
          {lastName} {firstName}
        </p>
        <p className={styles.customerDetail__email}>{email}</p>
      </div>
      <BankDetails
        accountName={wallet?.accountName}
        accountNumber={wallet?.accountNumber}
        bankName={wallet?.bankName}
      />

      <table
        style={{ tableLayout: "fixed" }}
        className="table table-striped table-borderless mt-4"
      >
        <tbody>
          <tr>
            <td>Email</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {email}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td align="right" style={{ textTransform: "capitalize" }}>
              {wallet?.status?.toLowerCase()}
            </td>
          </tr>
          <tr>
            <td>Book Balance</td>
            <td align="right">
              &#8358;{numberWithCommas(wallet?.bookedBalance)}
            </td>
          </tr>
          <tr>
            <td>Available Balance</td>
            <td align="right">
              &#8358;{numberWithCommas(wallet?.availableBalance)}
            </td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td align="right">{phoneNumber}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td align="right" style={{ wordWrap: "break-word" }}>
              {wallet?.accountReference}
            </td>
          </tr>
          <tr>
            <td>Tier</td>
            <td align="right">{TIER_OBJ[tier]}</td>
          </tr>
          <tr>
            <td>Created At</td>
            <td align="right">{format(new Date(createdAt), "MMM dd, yyyy")}</td>
          </tr>
        </tbody>
      </table>
      <OutlineButton
        onClick={linkWalletToCard}
        block
        title={loading ? "Linking..." : "Link Wallet To Card"}
      />
      {renderWalletActivation({ customerId: id, status: wallet?.status })}
    </div>
  );
};

export default CustomerDetail;
