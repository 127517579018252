import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { object, string } from "yup";

import TextInput from "components/TextInput/TextInput";
import BankLayout from "layouts/Bank/Bank";
import Loading from "components/Loading/Loading";
import { PrimaryButton } from "components/Button/Button";
import {
  selectSingleMerchantState,
  selectBankAccessModeState,
  selectParentMerchantsState,
  getSingleMerchantFn,
  getParentMerchantsFn,
  updateMerchantInfoFn,
  updateMerchantChargesFn,
  updateMerchantLimitFn,
} from "slices/bankSlice";

import styles from "./UpdateMerchant.module.css";

import { numberWithCommas } from "utils/helpers";

const UpdateMerchant = () => {
  const { merchant } = useParams() as Record<string, string | undefined>;
  const { loading, data } = useSelector(selectSingleMerchantState);
  const modeState = useSelector(selectBankAccessModeState);
  const parentMerchantState = useSelector(selectParentMerchantsState);

  const dispatch = useDispatch();

  const fetchMerchant = () => {
    dispatch(getParentMerchantsFn());
    dispatch(getSingleMerchantFn({ merchantId: merchant, mode: modeState }));
  };

  useEffect(fetchMerchant, [modeState]);

  const statusFn = (status) => {
    if (status === "IN-REVIEW") {
      return "In Review";
    } else if (status === "PENDING") {
      return "Pending";
    } else if (status === "DISABLED") {
      return "Disabled";
    } else {
      return "Active";
    }
  };

  const handleBasicInfoSubmit = async (values) => {
    dispatch(
      updateMerchantInfoFn(
        merchant,
        {
          card_setup: {
            appId: values?.card_setup?.appId,
            appKey: values?.card_setup?.appKey,
            prepaidCardPrefix: values?.card_setup?.prepaidCardPrefix,
            loadingAccountName: values?.card_setup?.loadingAccountName,
            loadingAccountNumber: values?.card_setup?.loadingAccountNumber,
            loadingAccountSortcode: values?.card_setup?.loadingAccountSortcode,
          },
          callbackURL: values?.callbackURL,
          fundingRate: values?.fundingRate,
          fundingRateMax: values?.fundingRateMax,
          parentMerchant: values?.parentMerchant,
          accountNumber: values?.accountNumber,
          sandboxCallbackURL: values?.sandboxCallbackURL,
        },
        {},
        () => {
          dispatch(getParentMerchantsFn());
          dispatch(
            getSingleMerchantFn({ merchantId: merchant, mode: modeState })
          );
        }
      )
    );
  };

  const handleCapabilities = (values) => {
    const { canLogin, sendEmail, canDebitCustomer, cardTransactionBlocked } =
      values;

    dispatch(
      updateMerchantInfoFn(
        merchant,
        {},
        {
          canLogin: canLogin === "" ? "" : canLogin === "false" ? false : true,
          sendEmail:
            sendEmail === "" ? "" : sendEmail === "false" ? false : true,
          canDebitCustomer:
            canDebitCustomer === ""
              ? ""
              : canDebitCustomer === "false"
              ? false
              : true,
          cardTransactionBlocked:
            cardTransactionBlocked === "true" ? true : false,
        },
        () => {
          dispatch(getParentMerchantsFn());
          dispatch(
            getSingleMerchantFn({ merchantId: merchant, mode: modeState })
          );
        }
      )
    );
  };

  const handleLimitSubmit = (values) => {
    dispatch(
      updateMerchantLimitFn({
        merchantId: merchant,
        limitInformations: {
          lien: values.lien,
          dailyTransactionLimit: values.dailyTransactionLimit,
          tier_1_daily_limit: values.tier_1_daily_limit,
          tier_2_daily_limit: values.tier_2_daily_limit,
          tier_3_daily_limit: values.tier_3_daily_limit,
          tier_1_min_balance: values.tier_1_min_balance,
          tier_2_min_balance: values.tier_2_min_balance,
          tier_3_min_balance: values.tier_3_min_balance,
        },
        cb: () => {
          dispatch(getParentMerchantsFn());
          dispatch(
            getSingleMerchantFn({ merchantId: merchant, mode: modeState })
          );
        },
      })
    );
  };

  const handleChargesSubmit = (values) => {
    dispatch(
      updateMerchantChargesFn({
        merchantId: merchant,
        chargesInformations: {
          charges: {
            walletToWalletTransfer: values.walletToWalletTransfer,
            walletReservationCharge: values.walletReservationCharge,
            transferCharges: {
              max5000: values.max5000,
              max50000: values.max50000,
              min50000: values.min50000,
            },
          },
        },
        cb: () => {
          dispatch(getParentMerchantsFn());
          dispatch(
            getSingleMerchantFn({ merchantId: merchant, mode: modeState })
          );
        },
      })
    );
  };

  return (
    <BankLayout>
      <>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className="mb-3">
              <h1>Update Merchant Details</h1>
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <div className={styles.table__container}>
                  <Formik
                    initialValues={{
                      card_setup: {
                        appId: data?.card_setup?.appId || "",
                        appKey: data?.card_setup?.appKey || "",
                        prepaidCardPrefix:
                          data?.card_setup?.prepaidCardPrefix || "",
                        loadingAccountName:
                          data?.card_setup?.loadingAccountName || "",
                        loadingAccountNumber:
                          data?.card_setup?.loadingAccountNumber || "",
                        loadingAccountSortcode:
                          data?.card_setup?.loadingAccountSortcode || "",
                      },
                      callbackURL: data?.callbackURL || "",
                      fundingRate: data?.fundingRate || "",
                      fundingRateMax: data?.fundingRateMax || "",
                      parentMerchant: data?.parentMerchant?.id || "",
                      accountNumber: data?.providusAccountNumber || "",
                      sandboxCallbackURL: data?.sandboxCallbackURL || "",
                    }}
                    validationSchema={object().shape({
                      card_setup: object().shape({
                        appId: string(),
                        appKey: string(),
                        prepaidCardPrefix: string(),
                        loadingAccountName: string(),
                        loadingAccountNumber: string(),
                        loadingAccountSortcode: string(),
                      }),
                      callbackURL: string(),
                      fundingRate: string(),
                      fundingRateMax: string(),
                      parentMerchant: string(),
                      accountNumber: string(),
                      sandboxCallbackURL: string(),
                    })}
                    onSubmit={handleBasicInfoSubmit}
                  >
                    {({ errors, touched }) => {
                      const parentMerchantClassname = `form-control form-control-lg ${
                        errors.parentMerchant && touched.parentMerchant
                          ? "is-invalid"
                          : ""
                      }`;

                      return (
                        <Form>
                          <h3>Basic Information</h3>
                          <table className="table table-striped table-borderless mt-4">
                            <tbody>
                              <tr>
                                <td>Business Name</td>
                                <td align="right">{data?.businessName}</td>
                              </tr>
                              <tr>
                                <td>Business Type</td>
                                <td align="right">{data?.businessType}</td>
                              </tr>
                              <tr>
                                <td>Tagpay Account Number</td>
                                <td align="right">
                                  <Field
                                    type="text"
                                    name="accountNumber"
                                    component={TextInput}
                                  />
                                </td>
                              </tr>
                              {!!data?.bvn && (
                                <tr>
                                  <td>BVN</td>
                                  <td align="right">{data?.bvn || ""}</td>
                                </tr>
                              )}
                              <tr>
                                <td>Email</td>
                                <td align="right">{data?.email}</td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td align="right">{statusFn(data?.review)}</td>
                              </tr>
                              <tr>
                                <td>Phone Number</td>
                                <td align="right">{data?.phoneNumber}</td>
                              </tr>
                              <tr>
                                <td>Book Balance</td>
                                <td align="right">
                                  &#8358;{numberWithCommas(data?.bookedBalance)}
                                </td>
                              </tr>
                              <tr>
                                <td>Available Balance</td>
                                <td align="right">
                                  &#8358;
                                  {numberWithCommas(data?.availableBalance)}
                                </td>
                              </tr>
                              <tr>
                                <td>Merchant Type</td>
                                <td align="right">{data?.merchantType}</td>
                              </tr>
                              <tr>
                                <td>Funding Rate</td>
                                <td align="right">
                                  <Field
                                    type="text"
                                    name="fundingRate"
                                    component={TextInput}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Funding Rate (Max)</td>
                                <td align="right">
                                  <Field
                                    type="text"
                                    name="fundingRateMax"
                                    component={TextInput}
                                  />
                                </td>
                              </tr>
                              {!!data?.parentMerchant && (
                                <tr>
                                  <td>Parent Merchant</td>
                                  <td align="right">
                                    <Field
                                      type="text"
                                      name="parentMerchant"
                                      component="select"
                                      className={parentMerchantClassname}
                                    >
                                      <option value="">
                                        -- Select Parent Merchant --
                                      </option>
                                      {parentMerchantState.data.map((each) => (
                                        <option value={each?.id}>
                                          {each?.businessName}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name="parentMerchant"
                                      className="invalid-feedback"
                                      component="div"
                                    />
                                  </td>
                                </tr>
                              )}
                              {!!data?.cacPack && (
                                <tr>
                                  <td>CAC Pack</td>
                                  <td align="right">
                                    {data?.cacPack.map((data) => (
                                      <a
                                        href={data}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.link__container}
                                      >
                                        <img
                                          src={data}
                                          alt=""
                                          className={styles.image__link}
                                        />
                                      </a>
                                    ))}
                                  </td>
                                </tr>
                              )}
                              {!!data?.callbackURL && (
                                <tr>
                                  <td>Callback URL</td>
                                  <td align="right">
                                    <Field
                                      type="text"
                                      name="callbackURL"
                                      component={TextInput}
                                    />
                                  </td>
                                </tr>
                              )}
                              {!!data?.sandboxCallbackURL && (
                                <tr>
                                  <td>Sandbox Callback URL</td>
                                  <td align="right">
                                    <Field
                                      type="text"
                                      name="sandboxCallbackURL"
                                      component={TextInput}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {!!data?.card_setup && (
                            <>
                              <h5>Card Setup</h5>
                              <table className="table table-striped table-borderless mt-4">
                                <tbody>
                                  <tr>
                                    <td>App ID</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.appId"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>App Key</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.appKey"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Prepaid Card Prefix</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.prepaidCardPrefix"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Loading Account Name</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.loadingAccountName"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Loading Account Number</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.loadingAccountNumber"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Loading Account Sort Code</td>
                                    <td align="right">
                                      <Field
                                        type="text"
                                        name="card_setup.loadingAccountSortcode"
                                        component={TextInput}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          )}
                          <div className="text-right mt-2">
                            <PrimaryButton
                              title="Update"
                              type="submit"
                              style={{ marginLeft: "auto" }}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className={styles.table__container}>
                  <Formik
                    initialValues={{
                      canLogin:
                        data?.canLogin === true
                          ? "true"
                          : data?.canLogin === false
                          ? "false"
                          : "",
                      sendEmail:
                        data?.sendEmail === true
                          ? "true"
                          : data?.sendEmail === false
                          ? "false"
                          : "",
                      canDebitCustomer:
                        data?.canDebitCustomer === true
                          ? "true"
                          : data?.canDebitCustomer === false
                          ? "false"
                          : "",
                      cardTransactionBlocked:
                        data?.cardTransactionBlocked === true ? "true" : false,
                    }}
                    validationSchema={object().shape({
                      canLogin: string(),
                      sendEmail: string(),
                      canDebitCustomer: string(),
                    })}
                    onSubmit={handleCapabilities}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <h3>Capabilities</h3>
                        <table className="table table-striped table-borderless mt-4">
                          <tbody>
                            <tr>
                              <td>Can Login</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="canLogin"
                                  className={`form-control form-control-lg ${
                                    errors.canLogin && touched.canLogin
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  component="select"
                                >
                                  <option value="">-- Select --</option>
                                  <option value="true">True</option>
                                  <option value="false">False</option>
                                </Field>
                                <ErrorMessage
                                  name="canLogin"
                                  className="invalid-feedback"
                                  component="div"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Can Send Email</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="sendEmail"
                                  component="select"
                                  className={`form-control form-control-lg ${
                                    errors.sendEmail && touched.sendEmail
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option value="">-- Select --</option>
                                  <option value="true">True</option>
                                  <option value="false">False</option>
                                </Field>
                                <ErrorMessage
                                  name="sendEmail"
                                  className="invalid-feedback"
                                  component="div"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Can Debit Customer</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="canDebitCustomer"
                                  component="select"
                                  className={`form-control form-control-lg ${
                                    errors.canDebitCustomer &&
                                    touched.canDebitCustomer
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option value="">-- Select --</option>
                                  <option value="true">True</option>
                                  <option value="false">False</option>
                                </Field>
                                <ErrorMessage
                                  name="canDebitCustomer"
                                  className="invalid-feedback"
                                  component="div"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Card Transaction Blocked</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="cardTransactionBlocked"
                                  component="select"
                                  className={`form-control form-control-lg ${
                                    errors.cardTransactionBlocked &&
                                    touched.cardTransactionBlocked
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option value="">-- Select --</option>
                                  <option value="true">True</option>
                                  <option value="false">False</option>
                                </Field>
                                <ErrorMessage
                                  name="cardTransactionBlocked"
                                  className="invalid-feedback"
                                  component="div"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-right mt-2">
                          <PrimaryButton
                            title="Update"
                            type="submit"
                            style={{ marginLeft: "auto" }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-6">
                <div className={styles.table__container}>
                  <Formik
                    initialValues={{
                      lien: data?.lien || 0,
                      dailyTransactionLimit: data?.dailyTransactionLimit || 0,
                      tier_1_daily_limit: data?.tier_1_daily_limit || 0,
                      tier_2_daily_limit: data?.tier_2_daily_limit || 0,
                      tier_3_daily_limit: data?.tier_3_daily_limit || 0,
                      tier_1_min_balance: data?.tier_1_min_balance || 0,
                      tier_2_min_balance: data?.tier_2_min_balance || 0,
                      tier_3_min_balance: data?.tier_3_min_balance || 0,
                    }}
                    validationSchema={object().shape({
                      lien: string(),
                      dailyTransactionLimit: string(),
                      tier_1_daily_limit: string(),
                      tier_2_daily_limit: string(),
                      tier_3_daily_limit: string(),
                      tier_1_min_balance: string(),
                      tier_2_min_balance: string(),
                      tier_3_min_balance: string(),
                    })}
                    onSubmit={handleLimitSubmit}
                  >
                    {() => (
                      <Form>
                        <h3>Limits</h3>
                        <table className="table table-striped table-borderless mt-4">
                          <tbody>
                            <tr>
                              <td>Lien</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="lien"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Daily Transaction Limit</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="dailyTransactionLimit"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 1 Daily Limit</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_1_daily_limit"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 2 Daily Limit</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_2_daily_limit"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 3 Daily Limit</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_3_daily_limit"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 1 Min Balance</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_1_min_balance"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 2 Min Balance</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_2_min_balance"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tier 3 Min Balance</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="tier_3_min_balance"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-right mt-2">
                          <PrimaryButton
                            title="Update"
                            type="submit"
                            style={{ marginLeft: "auto" }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className={styles.table__container}>
                  <Formik
                    initialValues={{
                      walletToWalletTransfer: data?.walletToWalletTransfer || 0,
                      walletReservationCharge:
                        data?.walletReservationCharge || 0,
                      max5000: data?.transferCharges?.max5000 || 0,
                      max50000: data?.transferCharges?.max50000 || 0,
                      min50000: data?.transferCharges?.min50000 || 0,
                    }}
                    validationSchema={object().shape({
                      walletToWalletTransfer: string(),
                      walletReservationCharge: string(),
                      max5000: string(),
                      max50000: string(),
                      min50000: string(),
                    })}
                    onSubmit={handleChargesSubmit}
                  >
                    {() => (
                      <Form>
                        <h3>Charges</h3>
                        <table className="table table-striped table-borderless mt-4">
                          <tbody>
                            <tr>
                              <td>Wallet Reservation Charge</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="walletReservationCharge"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Wallet To Wallet Transfer</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="walletToWalletTransfer"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Transfer Charge (max5000)</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="max5000"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Transfer Charge (max50000)</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="max50000"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Transfer Charge (min50000)</td>
                              <td align="right">
                                <Field
                                  type="text"
                                  name="min50000"
                                  component={TextInput}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-right mt-2">
                          <PrimaryButton
                            title="Update"
                            type="submit"
                            style={{ marginLeft: "auto" }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </BankLayout>
  );
};

export default UpdateMerchant;
