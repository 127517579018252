import * as React from "react";
import Navbar from "./components/Navbar/Navbar";
import SideNav from "./components/SideNav/SideNav";
import styles from "./Dashboard.module.css";

const DashboardLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<>
			<div>
				<Navbar />
				<div className="d-flex">
					<SideNav />
					<div className="container">
						<div className={`${styles.mainContent}`}>{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardLayout;
