import React, { useEffect, useState } from "react";
import styles from "./BatchCreditCustomersCSV.module.css";
import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";

import {
  selectSendMoneyState,
  uploadBulkCreditCustomerFn,
  processBulkCreditCustomerFn,
  getBulkCreditCustomerRecordsFn,
  deleteBulkCreditCustomerFn,
} from "slices/sendMoneySlice";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";

const CREDIT_STATUS = {
  PENDING: "Pending",
  READY: "Ready",
  ACTIVE: "Active",
  COMPLETED: "Completed",
};

const BatchCreditCustomersViaCSV: React.FC = () => {
  const [status, setStatus] = useState("status");
  const [processStatus, setProcessStatus] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState([]);
  const dispatch = useDispatch();
  const sendMoneyState = useSelector(selectSendMoneyState);
  const bulkCreditCustomerRecords = sendMoneyState.getBulkCreditCustomerRecords;
  const uploadCreditCustomerData = sendMoneyState.uploadBulkCreditCustomer;

  useEffect(() => {
    dispatch(getBulkCreditCustomerRecordsFn());
  }, [dispatch]);

  const renderContent = () => {
    switch (status) {
      case "status":
        return (
          <div className={styles.content__wrapper}>
            <div className={styles.top__btn}>
              <h4>Batch Credit Customers Via CSV</h4>
              <PrimaryButton
                title="Upload CSV"
                onClick={() => setStatus("upload")}
              />
            </div>
            {bulkCreditCustomerRecords?.data?.length ? (
              <div className={styles.collection__container}>
                <div className={styles.collection__header}>
                  <p className={styles.header__name}>Description</p>
                  <p className={styles.header__amount}>Status</p>
                  <p className={styles.header__action}>Action</p>
                </div>
                {bulkCreditCustomerRecords?.data?.map((data, index) => (
                  <div className={styles.collection__content} key={index}>
                    <p className={styles.content__name}>{data?.description}</p>
                    <p className={styles.content__amount}>
                      {CREDIT_STATUS?.[data?.status]}
                    </p>
                    <p className={styles.content__action}>
                      {data?.status === "READY" && (
                        <button
                          type="button"
                          className={styles.edit}
                          onClick={() => {
                            setProcessStatus([...processStatus, data?.id]);
                            dispatch(
                              processBulkCreditCustomerFn(
                                data?.batchReference,
                                () => {
                                  setProcessStatus(
                                    processStatus?.filter(
                                      (each) => each !== data?.id
                                    )
                                  );
                                  dispatch(getBulkCreditCustomerRecordsFn());
                                }
                              )
                            );
                          }}
                        >
                          {processStatus?.includes(data?.id)
                            ? "Starting..."
                            : "Start"}
                        </button>
                      )}
                      <button
                        type="button"
                        className={styles.delete}
                        onClick={() => {
                          setDeleteStatus([...deleteStatus, data?.id]);
                          dispatch(
                            deleteBulkCreditCustomerFn(data?.id, () => {
                              setDeleteStatus(
                                deleteStatus?.filter(
                                  (each) => each !== data?.id
                                )
                              );
                              dispatch(getBulkCreditCustomerRecordsFn());
                            })
                          );
                        }}
                      >
                        {deleteStatus?.includes(data?.id)
                          ? "Deleting..."
                          : "Delete"}
                      </button>
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.empty}>No Record Found</div>
            )}
          </div>
        );
      case "upload":
        return (
          <Formik
            initialValues={{
              file: null,
              description: "",
            }}
            validationSchema={WalletSchema}
            onSubmit={(values, actions) => {
              const formData = new FormData();

              formData.set("list", values?.file);
              formData.set("batchReference", nanoid());
              formData.set("description", values?.description);

              dispatch(
                uploadBulkCreditCustomerFn({
                  formData,
                  cb: () => {
                    dispatch(getBulkCreditCustomerRecordsFn());
                    setStatus("status");
                    actions.resetForm();
                  },
                })
              );
            }}
          >
            {({ values, setFieldValue }) => (
              <div className={styles.viaWallet}>
                <h4 className={styles.viaWallet__title}>
                  Batch Credit Customers Via CSV
                </h4>
                <Form>
                  <div className="form-group">
                    <label
                      htmlFor="file"
                      className={styles.csv__file__container}
                    >
                      <p className={styles.csv__file__text}>
                        {values.file ? "CSV File Selected" : "Select CSV File"}
                      </p>
                      <input
                        name="file"
                        id="file"
                        placeholder="Amount"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        className={styles.csv__file__input}
                        onChange={(e) => {
                          setFieldValue("file", e.target.files[0]);
                        }}
                      />
                    </label>
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="invalid-feedback"
                    />
                    <Field
                      name="description"
                      placeholder="Description"
                      type="text"
                      component={TextInput}
                      style={{ marginTop: "2rem", border: "1px solid #384c55" }}
                    />
                  </div>

                  {uploadCreditCustomerData.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <PrimaryButton
                      type="submit"
                      title="Upload CSV"
                      size="lg"
                      block
                      style={{ marginTop: "2rem" }}
                    />
                  )}
                </Form>
              </div>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  return (
    <SendMoney>
      {bulkCreditCustomerRecords.loading ? (
        <div style={{ margin: "2rem auto" }}>
          <Loading />
        </div>
      ) : (
        renderContent()
      )}
    </SendMoney>
  );
};

export default BatchCreditCustomersViaCSV;

const WalletSchema = Yup.object().shape({
  file: Yup.mixed().required("File is required"),
  description: Yup.string().required("Description is required"),
});
