import React, {useEffect} from "react";
import styles from "./CreditCustomerWallet.module.css";
import { PrimaryButton } from "components/Button/Button";
import SendMoney from "../../SendMoney";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {selectAllCustomersState, getAllCustomerFn} from 'slices/customersSlice';
import { creditWalletFn, selectWalletState } from "slices/walletSlice";
import TextInput from "components/TextInput/TextInput";
import Loading from "components/Loading/Loading";

const CreditCustomerWallet: React.FC = () => {
  const dispatch = useDispatch();
  const {creditWallet} = useSelector(selectWalletState);
  const customerState = useSelector(selectAllCustomersState);

  useEffect(() => {
    dispatch(getAllCustomerFn());
  }, [dispatch]);

  return (
    <SendMoney>
      {customerState.loading ? <div style={{margin: '0 auto'}}><Loading /></div> : <Formik
        initialValues={{
          customer: "",
          amount: "",
        }}
        validationSchema={WalletSchema}
        onSubmit={(values, actions) => {
          dispatch(creditWalletFn(values.customer, values.amount, () => actions.resetForm()));
        }}
      >
        {({errors, touched}) => (
          <div className={styles.viaWallet}>
            <h4 className={styles.viaWallet__title}>
              Credit Customer
            </h4>
            <Form>
              {/* <div>Baba oo</div> */}
              <div className="form-group">
              <Field
                // onBlur={() => {
                //   fetchUser(props.values.phoneNumber);
                // }}
                name="customer"
                type="string"
                component='select'
                className={`form-control form-control-lg ${
                  errors.customer && touched.customer ? "is-invalid" : ""
              }`}
              >
                <option value=''>-- Select Customer --</option>
                {customerState?.data?.map(customer => (
                  <option value={customer?.id} key={customer?.id}>{customer?.firstName + " " + customer?.lastName}</option>
                ))}
                </Field>
                <ErrorMessage name="customer" component='div' className="invalid-feedback" />
                </div>

              <Field
                name="amount"
                placeholder="Amount"
                type="text"
                component={TextInput}
              />
              {creditWallet.loading ? <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> : <PrimaryButton type="submit" title="Send Money" size="lg" block />}
            </Form>
          </div>
        )}
      </Formik>}
    </SendMoney>
  );
};

export default CreditCustomerWallet;

const WalletSchema = Yup.object().shape({
  customer: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
});
