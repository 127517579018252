import React, { useState } from "react";
import Table from "components/Table/Table";
import { numberWithCommas } from "utils/helpers";
import { format, parseISO } from "date-fns";
import { useDispatch } from "react-redux";

import { getReportFn } from "slices/bankSlice";

import styles from "./MerchantList.module.css";
import DownArrow from "assets/images/down-arrow.svg";
const categoryList = [
    "CARD_TOPUP",
    "BANK_TRANSFER",
    "AIRTIME_PURCHASE",
    "BVN_VERIFICATION",
    "WALLET_RESERVATION",
    "DEBIT_CUSTOMER_WALLET",
    "CREDIT_CUSTOMER_WALLET",
    "WALLET_FUNDED_THROUGH_BANK_TRANSFER",
    "MERCHANT_WALLET_SETTLEMENT",
    "WALLET_CREDITED_BY_MERCHANT",
];

const enumCategory = {
    CARD_TOPUP: "Card Topup",
    BANK_TRANSFER: "Bank Transfer",
    AIRTIME_PURCHASE: "Airtime Purchase",
    BVN_VERIFICATION: "BVN Verification",
    WALLET_RESERVATION: "Wallet Reservation",
    DEBIT_CUSTOMER_WALLET: "Debit Customer Wallet",
    CREDIT_CUSTOMER_WALLET: "Credit Customer Wallet",
    WALLET_FUNDED_THROUGH_BANK_TRANSFER: "Wallet Funded Through Bank Transfer",
    MERCHANT_WALLET_SETTLEMENT: "Merchant Wallet Settlement",
    WALLET_CREDITED_BY_MERCHANT: "Wallet Credited By Merchant",
};

const MerchantList = ({ data, openModal, setTransaction }) => {
    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [filterInputs, setFilterInputs] = useState({
        type: "ALL",
        status: null,
        category: null,
    });

    const renderTable = () =>
        data.map((record) => (
            <tr style={{ cursor: "pointer" }} key={record?.id} onClick={() => viewSingleTransaction(record)}>
                <td style={{ display: "flex", alignItems: "center" }}>
                    <span
                        style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            display: "inline-block",
                            backgroundColor:
                                record?.status?.toLowerCase() === "success"
                                    ? record?.status?.toLowerCase() === "pending"
                                        ? "#FBB900"
                                        : "green"
                                    : "red",
                        }}
                    />
                    &nbsp;&nbsp;&#8358;{numberWithCommas(record?.amount)}
                </td>
                <td>{enumCategory[record?.category]}</td>
                <td>{record?.currency}</td>
                <td>{record?.reference}</td>
                <td>{format(parseISO(record?.createdAt), "MMM dd, yyyy")}</td>
            </tr>
        ));

    const viewSingleTransaction = (data) => {
        setTransaction(data);
        openModal("transactionDetail");
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === "status" && value === "") {
            setFilterInputs({ ...filterInputs, status: null });
        } else {
            setFilterInputs({ ...filterInputs, [name]: value });
        }
    };

    const applyFilter = (e) => {
        e.preventDefault();
        const { type, category, status } = filterInputs;

        dispatch(getReportFn({ type, status, category, reference: searchValue }));

        setShowFilter(false);
    };

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
                <div className="col-auto my-3" style={{ width: "70%" }}>
                    <label className="sr-only" htmlFor="searchTransaction">
                        Search transaction
                    </label>
                    <div className="input-group mb-2" style={{ backgroundColor: "#87BD40" }}>
                        <div className="input-group-prepend">
                            <div
                                style={{
                                    borderRightColor: "transparent",
                                    backgroundColor: "transparent",
                                }}
                                className="input-group-text"
                            >
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                        <input
                            type="text"
                            value={searchValue}
                            id="searchTransaction"
                            className="form-control"
                            placeholder="Search transaction reference"
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ borderLeftColor: "transparent", backgroundColor: "#87BD40" }}
                        />
                    </div>
                </div>
                <p
                    style={{
                        width: "15%",
                        display: "flex",
                        fontSize: "90%",
                        cursor: "pointer",
                        padding: "5px 15px",
                        borderRadius: "30px",
                        alignItems: "center",
                        textTransform: "capitalize",
                        justifyContent: "space-between",
                        border: "1px solid rgba(0,0,0,.2)",
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                >
                    <span>{filterInputs.type || "ALL"}</span>{" "}
                    <img src={DownArrow} alt="Down Arrow" style={{ width: ".7rem", height: ".7rem" }} />
                </p>
            </form>
            <div className={styles.filter__container} style={{ display: showFilter ? "block" : "none" }}>
                <div className={styles.filter__top}>
                    <p>Filter</p>
                    <button type="button" onClick={applyFilter}>
                        Apply
                    </button>
                </div>
                <div className={styles.filter__bottom}>
                    <div className={styles.filter__type}>
                        <p>Transaction Type</p>
                        <select name="type" onChange={handleFilterChange}>
                            <option value="ALL">All</option>
                            <option value="DEBIT">Debit</option>
                            <option value="CREDIT">Credit</option>
                        </select>
                    </div>
                    <div className={styles.filter__type}>
                        <p>Transaction Status</p>
                        <select name="status" onChange={handleFilterChange}>
                            <option value="">All</option>
                            <option value="success">Success</option>
                            <option value="failed">Failed</option>
                        </select>
                    </div>
                    <div className={styles.filter__type}>
                        <p>Transaction Category</p>
                        <select name="category" onChange={handleFilterChange}>
                            <option value="">All</option>
                            {categoryList.map((each, index) => (
                                <option value={each} key={index}>
                                    {enumCategory[each]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className={styles.tableContainer}>
                <div className="col-auto my-3"></div>
                <Table noData={data.length === 0} headers={["AMOUNT", "TYPE", "CURRENCY", "REFERENCE", "DATE"]}>
                    {renderTable()}
                </Table>
            </div>
            <div
                className={styles.popup__modal}
                style={{ display: showFilter ? "block" : "none" }}
                onClick={() => setShowFilter(false)}
            />
        </>
    );
};

export default MerchantList;
