import React, { useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "components/Button/Button";
import { toast } from "react-toastify";
import { handleInputValidation } from "utils/helpers";
import request from "constants/requests";

export interface CreateWalletValuesTypes {
  firstName: string;
  lastName: string;
  email?: string;
  address?: string;
  tier?: string;
  customerId?: string;
}

export interface CreateWalletSectionProps {
  editWalletFn: (values: CreateWalletValuesTypes) => void;
  loading: boolean;
  error: any;
}

const PinSelectionModal: React.FC<{
  customerData: Record<string, any>;
  closeModal: () => void;
  refetch: () => void;
}> = ({ customerData, closeModal, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [isCleared, setIsCleared] = useState(false);

  const validationSchema = Yup.object().shape({
    // oldPin: Yup.string()
    //   .required("Old pin is required")
    //   .test(
    //     "onlyNumber",
    //     "Old pin must contain numbers only",
    //     (val) => handleInputValidation(val)?.onlyNumber
    //   )
    //   .length(4, "Old pin must be 4 characters long"),
    pin: Yup.string()
      .required("New pin is required")
      .test(
        "onlyNumber",
        "New pin must contain numbers only",
        (val) => handleInputValidation(val)?.onlyNumber
      )
      .length(4, "New pin must be 4 characters long"),
    cardNumber: Yup.string()
      .required("Card number is required")
      .test(
        "onlyNumber",
        "Card number must contain numbers only",
        (val) => handleInputValidation(val)?.onlyNumber
      )
      .min(16, "Card number must not be less than 16 characters long")
      .max(19, "Card number must not be greater than 19 characters long"),
    expiry: Yup.string()
      .required("Expiry date is required")
      .test("validDate", "Expiry date must be valid", (val) => {
        if (val?.length === 5 && val?.includes("/")) {
          return true;
        }

        return false;
      }),
    cvv: Yup.string()
      .required("CVV is required")
      .test(
        "onlyNumber",
        "CVV must contain numbers only",
        (val) => handleInputValidation(val)?.onlyNumber
      )
      .length(3, "CVV must be 3 characters long"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);

    try {
      const response = await request({
        method: "post",
        url: "/customer/card/pin-selection",
        data: {
          cvv: values?.cvv,
          expiry: values?.expiry,
          // oldPin: values?.oldPin,
          newPin: values?.pin,
          cardPan: values?.cardNumber,
        },
      });
      toast.success(response?.data?.message);
      refetch();
      closeModal();
      resetForm();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  const TextInput = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }: {
    [x: string]: any;
    field: any;
    form: any;
  }) => {
    const inputClassName = `form-control form-control-lg ${
      touched[field.name] && errors[field.name] ? "is-invalid" : ""
    }`;
    return (
      <div className="form-group">
        <input className={inputClassName} {...field} {...props} />
        {touched[field.name] && errors[field.name] && (
          <div className="invalid-feedback">{errors[field.name]}</div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h2 className="mb-5">Pin Selection</h2>
      <Formik
        initialValues={{
          // oldPin: "",
          pin: "",
          cardNumber: "",
          expiry: "",
          cvv: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* <>
              <Field
                name="oldPin"
                placeholder="Old Pin"
                type="text"
                component={TextInput}
                maxLength={4}
                value={values?.oldPin}
              />
              <ErrorMessage
                name="oldPin"
                className="invalid-feedback"
                component="div"
              />
            </> */}
            <div>
              <Field
                name="pin"
                placeholder="New Pin"
                type="text"
                component={TextInput}
                maxLength={4}
                value={values?.pin}
              />
              <ErrorMessage
                name="pin"
                className="invalid-feedback"
                component="div"
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Field
                name="cardNumber"
                placeholder="Card Number"
                type="text"
                component={TextInput}
                value={values?.cardNumber}
                maxLength={19}
              />
              <ErrorMessage
                name="cardNumber"
                className="invalid-feedback"
                component="div"
              />
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <Field
                  name="expiry"
                  placeholder="Expiry"
                  type="text"
                  component={TextInput}
                  maxLength={5}
                  value={values?.expiry}
                  onChange={(e) => {
                    const date = e.target.value;

                    if (date?.length === 2 && !isCleared) {
                      setFieldValue("expiry", date + "/");
                    } else {
                      setFieldValue("expiry", date);
                    }
                  }}
                  onKeyDown={(e) => {
                    const key = e.keyCode || e.charCode;

                    if (key == 8 || key == 46) {
                      setIsCleared(true);
                    } else {
                      setIsCleared(false);
                    }
                  }}
                />
                <ErrorMessage
                  name="expiry"
                  className="invalid-feedback"
                  component="div"
                />
              </div>
              <div style={{ width: "48%" }}>
                <Field
                  name="cvv"
                  placeholder="CVV"
                  type="text"
                  component={TextInput}
                  maxLength={3}
                  value={values?.cvv}
                />
                <ErrorMessage
                  name="cvv"
                  className="invalid-feedback"
                  component="div"
                />
              </div>
            </div>

            {loading ? (
              <div className="text-center" style={{ marginTop: "1.5rem" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <PrimaryButton
                type="submit"
                block
                size="lg"
                title="Submit"
                style={{ marginTop: "1.5rem" }}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PinSelectionModal;
