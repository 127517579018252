import * as React from "react";
import styles from "./CardNav.module.css";
import { NavLink, Link } from "react-router-dom";

interface CardNavProps {
	links: Array<{ label: string; route: string }>;
}

interface IProps {
	link: {route: string, label: string};
	status: boolean;
}

export const NavSection: React.FC<IProps> = ({ link, status }) => {

	const renderLinks = () => {
		return <Link
			to={link.route}
			className={status ? styles.active__link__nav : styles.nav__item}
		>
			{link.label}
		</Link>
	};

	return <nav className={styles.nav}>{renderLinks()}</nav>;
};

const CardNav: React.FC<CardNavProps> = ({ links }) => {
	const renderLinks = () => {
		const navLinks = links.map((link, index) => (
			<NavLink
				exact
				key={index}
				to={link.route}
				activeClassName={styles.nav__itemActive}
				className={styles.nav__item}
			>
				{link.label}
			</NavLink>
		));
		return navLinks;
	};

	return <nav className={styles.nav}>{renderLinks()}</nav>;
};

export default CardNav;
