import React from "react";
import Header from "components/Header/Header";
import { numberWithCommas } from "utils/helpers";
// import DottedImg from "assets/images/addWallet.svg";

import styles from "./Transaction.module.css";

const Transaction = ({ data }) => {
    return (
        <div className={styles.wallet}>
            <div className="mb-2">
                <Header>
                    Transactions {/*<img alt="Option" className={styles.wallet__headerIcon} src={DottedImg} />*/}
                </Header>
            </div>

            <div className={styles.wallet__content}>
                <div className={styles.transaction__balanceSheet}>
                    <p className={styles.transaction__balanceSheetTitle}>Credit Transactions</p>
                    <h3 className={styles.transaction__balanceSheetAmount}>
                        &#x20A6;{numberWithCommas(`${data?.creditTransactions || 0}`)}
                    </h3>
                </div>
                <div className={styles.transaction__balanceSheet}>
                    <p className={styles.transaction__balanceSheetTitle}>Debit Transactions</p>
                    <h3 className={styles.transaction__balanceSheetAmount}>
                        &#x20A6;{numberWithCommas(`${data?.debitTransactions || 0}`)}
                    </h3>
                </div>
                <div className={styles.wallet__vl} />
                <div className={styles.transaction__section}>
                    <p className={styles.wallet__amountTitle}>Total Transactions</p>
                    <h3 className={styles.wallet__amount}>
                        &#x20A6;{numberWithCommas(`${data?.totalTransactions || 0}`)}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default Transaction;
