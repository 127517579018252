import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import Table from "components/Table/Table";
import Header from "components/Header/Header";
import Loading from "components/Loading/Loading";
import DashboardLayout from "layouts/Dashboard/Dashboard";
import { PrimaryButton, OutlineButton } from "components/Button/Button";
import {
    selectMerchantRoles,
    selectMerchantCreateRole,
    selectMerchantUpdateRole,
    selectProfile,
    getRolesFn,
    createRoleFn,
    updateRoleFn,
} from "slices/merchantSlice";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import CreateRole from "./components/CreateRole/CreateRole";
import RoleDetails from "./components/RoleDetails/RoleDetails";
import UpdateRole from "./components/UpdateRole/UpdateRole";

import AddWalletImg from "assets/images/addWallet.svg";

const RolesScreen: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sideContent, setSideContent] = useState("");
    const [selectedRole, setSelectedRole] = useState({});

    const dispatch = useDispatch();

    const merchantRolesState = useSelector(selectMerchantRoles);
    const merchantCreateRoleState = useSelector(selectMerchantCreateRole);
    const merchantUpdateRoleState = useSelector(selectMerchantUpdateRole);
    const { permissions: profilePermissions } = useSelector(selectProfile);

    const fetchData = () => {
        !!profilePermissions?.includes("BROWSE_ROLES") && dispatch(getRolesFn());
    };

    // eslint-disable-next-line
    useEffect(fetchData, [profilePermissions]);

    useEffect(() => {
        if (merchantCreateRoleState.data) {
            fetchData();
            closeModal();
        }
        // eslint-disable-next-line
    }, [merchantCreateRoleState.data]);

    useEffect(() => {
        if (merchantUpdateRoleState.data) {
            fetchData();
            closeModal();
        }
        // eslint-disable-next-line
    }, [merchantUpdateRoleState.data]);

    const createRole = (payload) => {
        dispatch(createRoleFn(payload));
    };

    const updateRole = (payload) => {
        dispatch(updateRoleFn(payload));
    };

    const renderSideContent = () => {
        if (sideContent === "roleDetails") {
            return <RoleDetails content={selectedRole} />;
        }
        if (sideContent === "createRole") {
            return <CreateRole loading={merchantCreateRoleState?.loading} onSubmit={createRole} />;
        }
        if (sideContent === "updateRole") {
            return (
                <UpdateRole loading={merchantUpdateRoleState?.loading} content={selectedRole} onSubmit={updateRole} />
            );
        }
        return null;
    };

    const openModal = (content: string) => {
        setSideContent(content);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSideContent("");
    };

    const renderRows = () => {
        const rows = merchantRolesState?.data?.map((each: any, index) => {
            return (
                <tr key={each?.id}>
                    <td>{each?.name}</td>
                    <td>
                        <PrimaryButton
                            title="View"
                            className="mr-2"
                            size="sm"
                            onClick={() => {
                                openModal("roleDetails");
                                setSelectedRole(each);
                            }}
                        />
                        {!!profilePermissions?.includes("MANAGE_ROLES") && (
                            <OutlineButton
                                title="Update"
                                className="mr-2"
                                size="sm"
                                onClick={() => {
                                    openModal("updateRole");
                                    setSelectedRole(each);
                                }}
                            />
                        )}
                        {/* <button className='btn btn-sm btn-danger'>Delete</button> */}
                    </td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <>
            <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
            <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
                {renderSideContent()}
            </SideContent>
            <DashboardLayout>
                {merchantRolesState.loading ? (
                    <Loading />
                ) : (
                    <div className="container-fluid">
                        <div className="container-fluid d-flex justify-content-between align-items-start">
                            <Header>
                                Roles
                                {!!profilePermissions?.includes("MANAGE_ROLES") && (
                                    <>
                                        <img
                                            alt="Customer"
                                            className="ml-2"
                                            data-tip="Add New Role"
                                            data-for="addRole"
                                            src={AddWalletImg}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => openModal("createRole")}
                                        />
                                        <ReactTooltip id="addRole" place="right" />
                                    </>
                                )}
                            </Header>
                            <div>
                                <p className="fs-3">Total Numbers of Roles</p>
                                <h4 className="fs-2 fw-bold text-right">{merchantRolesState?.data?.length}</h4>
                            </div>
                        </div>
                        <div className="container-fluid bg-white rounded px-5 py-3 mt-4">
                            <Table
                                noData={merchantRolesState?.data?.length === 0 ? true : false}
                                headers={["ROLE", "ACTION"]}
                            >
                                {renderRows()}
                            </Table>
                        </div>
                    </div>
                )}
            </DashboardLayout>
        </>
    );
};

export default RolesScreen;
