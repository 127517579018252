import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Settings from "../Settings";
import routes from "routing/routes";
import Loading from "components/Loading/Loading";
import TextInput from "components/TextInput/TextInput";
import { PrimaryButton } from "components/Button/Button";
import { updateKycFn, selectAuthState } from "slices/authSlice";
import { getProfileFn, selectSettingsState } from "slices/settingsSlice";
import {selectProfile} from 'slices/merchantSlice';

const AccountVerificationScreen: React.FC = () => {
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState("");
    const history = useHistory();

    const fetchProfile = () => {
        dispatch(getProfileFn());
    };

    useEffect(fetchProfile, []);

    const dispatch = useDispatch();
    const authState = useSelector(selectAuthState);
    const profileState = useSelector(selectSettingsState);
    const { data: user, loading: userLoading } = profileState.getProfile;
    const {permissions: profilePermissions} = useSelector(selectProfile);

    const { loading: kycLoading } = authState.kyc;

    const submitKYC = (values: { bvn: string }) => {
        !!profilePermissions?.includes('UPDATE_MERCHANT_DETAIL') && dispatch(
            updateKycFn({
                image: image,
                bvn: values.bvn,
                imageTitle: imageName,
                cb: () => history.push(routes.ACCOUNT_REVIEW),
            })
        );
    };

    const fileSelectedHandler = (event: any) => {
        if (event.target.files[0]) {
            setImage(event.target.files[0]);
            setImageName(event.target.files[0].name);
        }
    };

    const renderContent = () => {
        if (userLoading && !user.id) {
            return <Loading />;
        } else if (user.review === "ENABLED") {
            return (
                <div>
                    <p className="mb-0">Identity Verification</p>
                    <p className="settings__lightText">Your ID Card document has been verified.</p>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-6">
                        <h2 className="mb-2">Director's Details</h2>
                        <p className="settings__lightText mb-3">
                            Submit the KYC(Know Your Customer) information to verifiy you account and complete
                            registration
                        </p>
                        <Formik initialValues={{ bvn: "" }} onSubmit={submitKYC}>
                            {() => (
                                <Form>
                                    <Field name="bvn" placeholder="BVN" type="text" component={TextInput} />

                                    <div className="form-group">
                                        <label htmlFor="cac">Upload CAC Pack</label>

                                        <div className="custom-file">
                                            <input
                                                id="cac"
                                                type="file"
                                                className="custom-file-input"
                                                onChange={fileSelectedHandler}
                                                accept="image/png, image/jpeg,image/jpg,.pdf"
                                            />
                                            <label className="custom-file-label" htmlFor="cac">
                                                {imageName || "Choose file"}
                                            </label>
                                        </div>
                                    </div>

                                    {kycLoading ? (
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        !!profilePermissions?.includes('UPDATE_MERCHANT_DETAIL') && <PrimaryButton block type="submit" title="Submit" />
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            );
        }
    };
    return <Settings>{renderContent()}</Settings>;
};

export default AccountVerificationScreen;
