import * as React from "react";
import { numberWithCommas } from "utils/helpers";
import Loading from "components/Loading/Loading";
import format from "date-fns/format";

import { CATEGORY_DESCRIPTION } from "constants/transaction";

export interface TransactionDetailProps {
    transaction: any;
}

const TransactionDetail: React.FC<TransactionDetailProps> = ({ transaction }) => {
    const {
        status,
        currency,
        category,
        amount,
        fee,
        vat,
        balance_before,
        balance_after,
        reference,
        type,
        description,
        createdAt,
        metadata,
    } = transaction.data;

    if (transaction.loading) {
        return <Loading />;
    }
    return (
        <div>
            <table style={{ tableLayout: "fixed" }} className="table table-striped table-borderless mt-4">
                <tbody>
                    <tr>
                        <td>Status</td>
                        <td align="right" style={{ textTransform: "capitalize" }}>
                            {status}
                        </td>
                    </tr>
                    {metadata?.narration ? (
                        <tr>
                            <td>Narration</td>
                            <td align="right">{metadata?.narration}</td>
                        </tr>
                    ) : (
                        <tr>
                            <td>Description</td>
                            <td align="right">{description}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Type</td>
                        <td align="right">{type}</td>
                    </tr>

                    <tr>
                        <td>Amount</td>
                        <td align="right">&#8358;{numberWithCommas(amount)}</td>
                    </tr>
                    {!!fee && (
                        <tr>
                            <td>Transaction Fee</td>
                            <td align="right">&#8358;{numberWithCommas(fee)}</td>
                        </tr>
                    )}
                    {!!vat && (
                        <tr>
                            <td>VAT</td>
                            <td align="right">&#8358;{numberWithCommas(vat)}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Balance Before</td>
                        <td align="right">&#8358;{numberWithCommas(balance_before)}</td>
                    </tr>
                    <tr>
                        <td>Balance After</td>
                        <td align="right">&#8358;{numberWithCommas(balance_after)}</td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td align="right">{CATEGORY_DESCRIPTION[category]}</td>
                    </tr>
                    <tr>
                        <td>Currency</td>
                        <td align="right">{currency}</td>
                    </tr>
                    <tr>
                        <td>Reference</td>
                        <td style={{ wordWrap: "break-word" }} align="right">
                            {reference}
                        </td>
                    </tr>
                    <tr>
                        <td>Transaction Date</td>
                        <td align="right">{format(new Date(createdAt), "dd-MM-yyyy p")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TransactionDetail;
